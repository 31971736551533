import React, {useEffect} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import {ChatContainer} from '../styles/Chat.style';
import ChatSideBar from './ChatSideBar';
import {useEventHandler} from '@unthinkable/react-event-handler';

const Chat = () => {
  const {patientId, groupId} = useParams();
  const {notify} = useEventHandler();

  useEffect(() => {
    if (groupId) {
      notify &&
        notify('dataUpdated', {
          id: 'chatgroups',
        });
    }
  }, [groupId]);

  return (
    <ChatContainer style={patientId ? {margin: '-6px'} : {}}>
      <ChatSideBar />
      <Outlet />
    </ChatContainer>
  );
};

export default Chat;
