import React from 'react';
import {View} from '@unthinkable/react-core-components';
import {TextRenderer} from '@unthinkable/react-text-input';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import routeLink from '../../../constant/routeLink.js';
import {hospitalizationMedicationStyle} from './style/HospitalizationMedicationTable';
import {combineText} from '../../../utils/commonMethod';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';

export const HospitalizationMedication = props => {
  const {encounter} = props;
  const {isMobile} = useMediaQuery();
  return (
    <View
      style={
        isMobile
          ? hospitalizationMedicationStyle.mainContainerMobile
          : hospitalizationMedicationStyle.mainContainer
      }>
      <TableHeader title={'New Hospitalization Medication'} />
      <BasicTable
        variant={'outline'}
        eventSourceId={'addMedication'}
        api={routeLink.getOutPatientMedication}
        fields={{
          drugName: 1,
          dose: 1,
          frequency: 1,
          direction: 1,
          prescriberId: {
            firstName: 1,
            lastName: 1,
          },
          status: 1,
        }}
        filter={{
          encounterId: encounter?._id,
        }}
        columns={[
          {
            header: 'Name',
            field: 'drugName',
            render: props => (
              <TextRenderer
                {...props}
                styles={{
                  ...props.styles,
                  text: {
                    ...props.styles.text,
                    textDecoration:
                      props?.row?.status == 'Inactive'
                        ? 'line-through'
                        : 'none',
                  },
                }}
              />
            ),
          },
          {
            header: 'Dose',
            filed: 'dose',
            type: 'text',
          },
          {
            header: 'Frequency',
            field: 'frequency',
            type: 'text',
          },
          {
            header: 'Directions',
            field: 'direction',
            type: 'text',
          },
          {
            header: 'Prescriber',
            render: ({row}) => {
              return (
                <TextRenderer
                  value={combineText(
                    row?.prescriberId?.firstName,
                    row?.prescriberId?.lastName,
                  )}
                />
              );
            },
          },
        ]}
        {...props}
      />
    </View>
  );
};
