import React from 'react';
import {useParams} from 'react-router-dom';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {
  USERS_API_ENDPOINT,
  USER_TYPE,
  USERS_LIST_EVENT_SOURCE_ID,
  STATUS,
} from '../constants/UserConstants';
import {Button} from '../../../components/button';
import {View} from '@unthinkable/react-core-components';
import Theme from '../../../theme/Theme';
import HttpAuth from '../../../services/HttpAuthService';
import {toasterMessage, routeLink} from '../../../constant';
import {useToast} from '@unthinkable/react-toast';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {
  nameValidation,
  phoneAndFaxValidation,
} from '../../../utils/validations/commonValidations';
import {entity} from '../../../constant/authorization';

export const AddUser = props => {
  const toast = useToast();
  const {onSubmit} = useFormSubmit({
    uri: props.mode == 'edit' ? routeLink.editUser : routeLink.addUser,
    eventSourceId: USERS_LIST_EVENT_SOURCE_ID,
  });
  const {user} = useAuth();
  const {colors, fonts} = Theme;
  return (
    <Form
      saveButtonText={props.mode === 'edit' ? 'Save' : 'Send Request'}
      onSubmit={onSubmit}
      defaultValues={{
        employerType: user?.employerType,
        practiceId: user?.practiceId,
        facilityId: user?.facilityId,
      }}
      beforeSubmit={data => ({
        data: {
          ...data.data,
          ...(data?.data?.practiceId?.type || data?.data?.facilityId?.type
            ? {
                organizationType: data.data?.practiceId
                  ? data.data?.practiceId?.type
                  : [data.data?.facilityId?.type],
              }
            : {}),
        },
      })}
      computations={{
        employerType: {
          compute: async value => {
            try {
              const {data} = await HttpAuth.get(routeLink.getUserType, {
                params: {
                  fields: {
                    employerType: {
                      name: 1,
                    },
                  },
                  filter: {
                    _id: value?.userType?._id,
                  },
                  only: true,
                },
              });
              return data?.employerType || null;
            } catch (error) {
              return '';
            }
          },
          dependencies: ['userType'],
        },
      }}
      layoutFields={[
        {
          label: props.mode == 'edit' ? 'User Info' : 'Request New User',
          fields: [
            {
              type: 'text',
              label: 'Name',
              field: 'name',
              validate: nameValidation,
              required: true,
            },
            {
              type: 'autoComplete',
              field: 'userType',
              label: 'User Type',
              api: '/v1/userTypes',
              sort: {name: 1},
              required: true,
              suggestionField: 'name',
              size: 'medium',
              onChangeValue: (value, e, options) => {
                const {setFieldValue} = options;
                if (value?.name === USER_TYPE.ADMIN) {
                  setFieldValue('isSuperAdmin', true);
                }
                setFieldValue('practiceId', user?.practiceId || null);
                setFieldValue('facilityId', user?.facilityId || null);
                setFieldValue('facilityAssigned', []);
                setFieldValue('employerType', user?.employerType || null);
              },
            },

            {
              type: 'autoComplete',
              field: 'employerType',
              label: 'Employer Type',
              api: '/v1/employerTypes',
              sort: {name: 1},
              suggestionField: 'name',
              size: 'medium',
              visible: ({values}) => {
                return (
                  user?.userType?.name === USER_TYPE.ADMIN &&
                  !(
                    values.isPracticePrimaryAdmin ||
                    values.isFacilityPrimaryAdmin
                  )
                );
              },
              onChangeValue: (value, e, options) => {
                const {setFieldValue} = options;
                setFieldValue('practiceId', null);
                setFieldValue('facilityId', null);
                setFieldValue('userType', null);
                setFieldValue('facilityAssigned', []);
              },
              readOnly: true,
              valueField: 'name',
            },
            {
              type: 'text',
              field: 'employerType.name',
              label: 'Employer Type',
              size: 'medium',
              readOnly: true,
              visible: ({values}) => {
                return (
                  user?.userType?.name === USER_TYPE.ADMIN &&
                  (values.isPracticePrimaryAdmin ||
                    values.isFacilityPrimaryAdmin)
                );
              },
            },
            {
              type: 'autoComplete',
              field: 'practiceId',
              label: 'Employer',
              api: '/v1/practices',
              sort: {name: 1},
              size: 'medium',
              suggestionField: 'name',
              fields: {
                name: 1,
                type: 1,
              },
              visible: ({values}) => {
                return (
                  values?.employerType?.name === entity?.practices &&
                  user?.userType?.name === USER_TYPE.ADMIN &&
                  !(
                    values.isFacilityPrimaryAdmin ||
                    values.isPracticePrimaryAdmin
                  )
                );
              },
              onChangeValue: (value, e, options) => {
                const {setFieldValue} = options;
                setFieldValue('facilityId', null);
                setFieldValue('facilityAssigned', null);
              },
              required: true,
            },
            {
              type: 'text',
              field: 'practiceId.name',
              label: 'Employer',
              size: 'medium',
              readOnly: true,
              visible: ({values}) => {
                return (
                  values?.employerType?.name === entity?.practices &&
                  (values.isFacilityPrimaryAdmin ||
                    values.isPracticePrimaryAdmin)
                );
              },
            },
            {
              type: 'autoComplete',
              field: 'facilityId',
              label: 'Employer',
              api: '/v1/facilities',
              sort: {name: 1},
              size: 'medium',
              suggestionField: 'name',
              filter: () => {
                return JSON.stringify({
                  isRedoxFacility: false,
                });
              },
              fields: {
                name: 1,
                type: 1,
              },
              visible: ({values}) =>
                values?.employerType?.name === entity.facilities &&
                user?.userType?.name === USER_TYPE.ADMIN &&
                !(
                  values.isFacilityPrimaryAdmin || values.isPracticePrimaryAdmin
                ),
              onChangeValue: (value, e, options) => {
                const {setFieldValue} = options;
                setFieldValue('practiceId', null);
                setFieldValue('facilityAssigned', null);
              },
              required: true,
            },
            {
              type: 'text',
              field: 'facilityId.name',
              label: 'Employer',
              size: 'medium',
              readOnly: true,
              visible: ({values}) =>
                values?.employerType?.name === entity.facilities &&
                (values.isFacilityPrimaryAdmin ||
                  values.isPracticePrimaryAdmin),
            },
            {
              type: 'multiAutoComplete',
              field: 'facilityAssigned',
              label: 'Facilities',
              api: routeLink.getFacilities,
              sort: {name: 1},
              suggestionField: 'name',
              secondarySuggestionField: 'shortName',
              onInputBlur: false,
              filter: ({values}) => {
                return JSON.stringify({
                  _id: {$nin: [...(values?.facilityAssigned || [])]},
                  practices: values?.practiceId?._id,
                  status: STATUS.ACTIVE,
                });
              },
              visible: ({values}) => {
                return (
                  values?.employerType?.name === entity.practices &&
                  values?.practiceId
                );
              },
            },
            {
              type: 'email',
              label: 'User Email Address',
              field: 'email',
              required: true,
              readOnly: props.mode == 'edit',
            },
            {
              render: (_, {values}) => {
                return (
                  <View>
                    <Button
                      key="resetPassword"
                      text="Reset Password"
                      onPress={async () => {
                        try {
                          await HttpAuth.post(routeLink.forgotPasswordLink, {
                            email: values.email,
                          });
                          toast({
                            message: toasterMessage.forgetPassword.success,
                            type: 'Success',
                          });
                        } catch (err) {
                          toast({
                            message: err.message,
                            type: 'Error',
                          });
                        }
                      }}
                      styles={{
                        container: {
                          backgroundColor: colors.NEUTRAL_HIGH,
                          width: 200,
                        },
                        disabledContainer: {
                          backgroundColor: colors.OUTLINE,
                        },
                        text: {
                          ...fonts.ACTION,
                          color: colors.BACKGROUND,
                        },
                        disabledText: {color: colors.NEUTRAL_LOW},
                      }}
                    />
                  </View>
                );
              },
              size: 'medium',
              visible: props?.mode == 'edit',
            },
            {
              type: 'text',
              label: 'Phone No.',
              field: 'phoneNumber',
              validate: value => phoneAndFaxValidation(value, 'phone'),
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditUser = props => {
  const {userId} = useParams();
  return (
    <AddUser
      {...props}
      mode="edit"
      api={`${USERS_API_ENDPOINT}/${userId}`}
      fields={{
        name: 1,
        shortName: 1,
        phoneNumber: 1,
        faxNumber: 1,
        streetAddress: 1,
        city: 1,
        state: 1,
        zipCode: 1,
        type: 1,
        userType: {
          name: 1,
        },
        email: 1,
        organizationId: 1,
        employerType: {
          name: 1,
        },
        practiceId: {
          name: 1,
        },
        facilityId: {
          name: 1,
        },
        isPracticePrimaryAdmin: {
          _id: 1,
        },
        isFacilityPrimaryAdmin: {
          _id: 1,
        },
        facilityAssigned: {
          name: 1,
        },
      }}
    />
  );
};
