import React from 'react';
import moment from 'moment';
import {TextRender} from '@unthinkable/react-table';
import {TextRenderer} from '@unthinkable/react-text-input';
import {Row} from '@unthinkable/react-core-components';
import Config from '../../Config';
import {
  Patient_Location_Type,
  Patient_Type,
} from '../patient/constants/PatientConstants';
import {ENCOUNTER_STATUS} from 'src/constant/encounterStatus';
import {ColumnTextRender} from './screens/FacilityTable.style';
import {getDueDateColor} from 'src/utils/commonMethod';
import {status} from 'src/constant/status';
import {TaskWidget} from '../patient/components/TaskWidget';

export const renderDOB = patient => {
  return <TextRender value={moment(patient.DOB).format(Config.DOBFormat)} />;
};

export const renderCurrentLocation = patient => {
  const text =
    patient?.currentLocationType?.value == Patient_Location_Type.Home
      ? Patient_Location_Type.Home
      : patient?.currentLocation?.name;
  return <TextRenderer value={text} />;
};

export const renderArrivalDate = patient => {
  return patient?.latestEncounter?.latestArrivalEvent?.eventDate ? (
    <TextRender
      value={moment(patient?.latestEncounter?.latestArrivalEvent?.eventDate)
        .utc()
        .format(Config.dateFormat)}
    />
  ) : (
    ''
  );
};

export const renderDischargeDate = patient => {
  return patient?.latestEncounter?.dischargeDate ? (
    <TextRender
      value={moment(patient?.latestEncounter?.dischargeDate)
        .utc()
        .format(Config.dateFormat)}
    />
  ) : (
    ''
  );
};

export const renderTcmDueDate = patient => {
  if (
    !patient?.latestEncounter ||
    patient?.latestEncounter?.status === ENCOUNTER_STATUS.BILLABLE ||
    patient?.latestEncounter?.status === ENCOUNTER_STATUS.INCOMPLETE
  ) {
    return;
  } else if (patient?.latestEncounter?.onGoingTcmDueDate) {
    return (
      <Row
        gap={36}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ColumnTextRender
          type={getDueDateColor(patient?.latestEncounter?.onGoingTcmDueDate)}>
          {moment(patient?.latestEncounter?.onGoingTcmDueDate)
            .utc()
            .format(`${Config.dateFormat}`)}
        </ColumnTextRender>
      </Row>
    );
  }
};

export const renderPatientType = patient => {
  return <TextRenderer value={Patient_Type[patient?.type]} />;
};

export const renderTaskWidget = (patient, user) => {
  const activeTasks = patient?.latestEncounter?.taskInfo?.filter(
    task => task.status === status.active,
  );
  if (
    activeTasks?.length &&
    patient?.primaryPractice?._id === user?.practiceId?._id
  ) {
    return (
      <TaskWidget
        patient={patient}
        encounter={patient?.latestEncounter}
        skipMessage
      />
    );
  }
};
