import moment from 'moment';
import {employerType} from '../../../constant/employerType';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
const isToday = time => {
  if (moment(time).calendar().includes('Today')) {
    return true;
  }
  return false;
};
const isMe = (senderId, userId) => {
  return senderId === userId;
};

const getTimeFromTimeToken = timetoken => {
  // Convert timetoken back to seconds
  let timetokenInSeconds = timetoken / 10000000;

  // Convert seconds to milliseconds (JavaScript uses milliseconds for time)
  let timetokenInMilliseconds = timetokenInSeconds * 1000;
  return new Date(timetokenInMilliseconds);
};

const getGroupName = group => {
  // console.log("🚀 ~ group:", group)
  const {user} = useAuth();
  if (group.patientId) {
    const parts = group.name.split('-');
    return parts[1] + '-' + parts[2];
  }
  if (group?.practiceId && user?.employerType?.name === employerType.facility) {
    return group?.practiceId?.name;
  } else if (
    group?.facilityId &&
    user?.employerType?.name === employerType.practice
  ) {
    return group?.facilityId?.name;
  } else if (group?.facilityId || group?.practiceId) {
    return group?.name;
  } else {
    return group?.name?.split('.')[2]?.replaceAll('_', ' ');
  }
};

export {isToday, isMe, getTimeFromTimeToken, getGroupName};
