import React from 'react';
import moment from 'moment';
import {getZeroTimeDate} from '@unthinkable/react-filters';
import {TextRenderer} from '../components/formEditors/Editors';
import {TASK_TYPE} from '../constant/taskType';
import Config from '../Config';
import {STATUS} from '../modules/user/constants/UserConstants';

export const combineText = (value1, value2) => {
  return `${value1 || ''} ${value2 || ''}`.trim();
};
export const getPatientsFullName = props => {
  const {FirstName, MiddleName, LastName, Render} = props || {};
  const fullName = `${LastName ? LastName + ',' : ''} ${
    FirstName ? FirstName : ''
  } ${MiddleName ? MiddleName : ''}`;

  return Render ? <TextRenderer value={fullName} /> : fullName;
};

export const getDueDateColor = tcmDueDate => {
  let hours = (new Date(tcmDueDate) - new Date()) / 36e5; // 36e5 is 1 hour in milliseconds
  if (hours <= 0) {
    return 'Overdue';
  } else if (hours > 0 && hours < 24) {
    return 'Red';
  } else if (hours >= 24 && hours < 72) {
    return 'Yellow';
  } else if (hours >= 72) {
    return 'Gray';
  }
};

export const getDifference = tcmDueDate => {
  let hours = (new Date(tcmDueDate) - new Date()) / 36e5; // 36e5 is 1 hour in milliseconds
  if (hours <= 0) {
    return 'Overdue';
  } else if (hours > 0 && hours < 24) {
    return `${Math.floor(hours)} Hours`;
  } else if (hours >= 24) {
    return `${Math.floor(hours / 24)} Days`;
  }
};

export const extractInitials = fullName => {
  const nameParts = fullName?.split(' ') || []; // Split the full name by space
  const initials = nameParts.map(part => part.charAt(0).toUpperCase());
  return initials.join('');
};

export const getVariant = (task, encounter) => {
  if (task?.status === STATUS.COMPLETE) {
    if (
      task.type === TASK_TYPE.ContactTask &&
      task?.successStatus &&
      encounter?.ContactTaskDuration <= Config.contactTaskDuration
    )
      return 'success';
    else if (
      task.type === TASK_TYPE.VisitTask &&
      task?.successStatus &&
      encounter?.VisitTaskDuration <= Config.visitTaskDuration
    )
      return 'success';
    else if (
      task.type === TASK_TYPE.MedicationTask &&
      task?.successStatus &&
      encounter?.MedicationTaskDuration <= Config.medicationTaskDuration
    )
      return 'success';
    else if (task.type === TASK_TYPE.NotesTask && task?.successStatus)
      return 'success';
    else if (task.type === TASK_TYPE.Form1823 && task?.successStatus)
      return 'success';
    else return 'alarm';
  } else {
    return 'warning';
  }
};

export const getRemainingDaysMessage = task => {
  if (
    task?.status == STATUS.ACTIVE &&
    (task?.tcmDueDate || task?.nextTcmDueDate)
  ) {
    let concernedDate = task.tcmDueDate;
    if (
      new Date(task.tcmDueDate) < new Date() &&
      task.type !== TASK_TYPE.ContactTask &&
      task?.nextTcmDueDate
    ) {
      concernedDate = task.nextTcmDueDate;
    }
    const diff = moment(getZeroTimeDate(concernedDate)).diff(
      getZeroTimeDate(new Date()),
      'days',
    );
    if (diff < 0) {
      return 'Overdue by ' + Math.abs(diff) + ' days';
    } else if (diff == 0) {
      return 'Due Today';
    }
    return diff + ' days remaining';
  }
  return '';
};

export const DateTypeCaste = value => {
  if (typeof value === 'string') {
    value = value.trim();
  }
  value = value ? new Date(value) : null;
  if (!value || isNaN(value.getTime())) {
    return null; // Handle invalid date
  }
  const year = value.getFullYear();
  const month = value.getMonth();
  const day = value.getDate();
  const hours = value.getHours();
  const minutes = value.getMinutes();
  const seconds = value.getSeconds();

  // Convert to UTC Date with the same time
  const utcDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds));
  return utcDate;
};
