import {View} from '@unthinkable/react-core-components';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import images from '../../../assets/images';
import {Button} from '../../../components/button';
import {Form} from '../../../components/form';
import {TableHeader} from '../../../components/header/TableHeader';
import {routeLink} from '../../../constant';
import buttonType from '../../../constant/buttonType';
import {useFormSubmit} from '../../../hooks';
import {addMedicationStyle} from '../screens/style/AddMedication.style';
import {
  PatientClass,
  Transitional_Event_Type,
} from '../constants/PatientConstants';
import {DateTypeCaste} from 'src/utils/commonMethod';
export const CreateNewTransitionalEvent = props => {
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.CreateNewTransitionalEvent}`,
    method: 'post',
    eventSourceId: ['transitional-event'],
  });
  const {
    state: {patient, encounter},
  } = useLocation();

  const RenderPlusButton = () => {
    const navigate = useNavigate();
    const {state} = useLocation();
    return (
      <View style={addMedicationStyle.buttonContainer}>
        <Button
          key="Add New Facility Form Transitional Event"
          icon={images.addIcon}
          buttonType={buttonType.linkButtonWithBorder}
          onPress={() => {
            navigate('add-new-facility', {
              state: {
                encounter: state?.encounter,
                patient: state?.patient,
              },
            });
          }}
        />
      </View>
    );
  };
  const eventOptions = [
    {
      value: Transitional_Event_Type.Discharge,
      label: `Patient discharged from ${
        encounter?.latestEventLocation?.name || patient?.currentLocation?.name
      }`,
    },
  ];
  if (encounter?.patientClass === PatientClass.Emergency) {
    eventOptions.push({
      value: Transitional_Event_Type.Arrival,
      label: `Patient was admitted to ${
        encounter?.latestEventLocation?.name || patient?.currentLocation?.name
      }`,
    });
  }
  return (
    <>
      <TableHeader title="Create New Transitional Event" secondaryTitle={''} />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          patientId: patient?._id,
          encounterId: encounter?._id,
          eventDate: DateTypeCaste(new Date()),
          location: encounter?.latestEventLocation,
        }}
        computations={{
          patientClass: {
            compute: async values => {
              try {
                if (values?.event === Transitional_Event_Type.Arrival)
                  return 'Inpatient';
                else return encounter?.patientClass;
              } catch (error) {
                return '';
              }
            },
            dependencies: ['event'],
          },
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'autoComplete',
                field: 'event',
                label: 'Event',
                options: eventOptions,
                key: 'event',
                keyField: 'value',
                suggestionField: 'label',
                required: true,
              },
              {
                type: 'date',
                field: 'eventDate',
                label: 'Event Date',
                size: 'large',
                visible: ({values}) => {
                  return values.event;
                },
                required: true,
              },
              {
                type: 'autoComplete',
                field: 'dischargeType',
                label: 'Discharge Type',
                required: true,
                options: ['Home', 'Inpatient Facility'],
                size: 'medium',
                visible: ({values}) => {
                  return values?.event === Transitional_Event_Type.Discharge;
                },
              },
              {
                type: 'autoComplete',
                field: 'newFacilityPatientClass',
                label: 'New Facility Classification',
                required: true,
                options: ['Emergency', 'Inpatient'],
                size: 'medium',
                visible: ({values}) => {
                  return (
                    values.event === Transitional_Event_Type.Discharge &&
                    values.dischargeType === 'Inpatient Facility'
                  );
                },
              },
              {
                type: 'autoComplete',
                field: 'dischargedTo',
                label: 'Discharged To',
                api: routeLink.getFacilities,
                filter: {
                  isRedoxFacility: true,
                },
                sort: {name: 1},
                suggestionField: 'name',
                size: 'medium',
                required: true,
                visible: ({values}) => {
                  return (
                    values.event === Transitional_Event_Type.Discharge &&
                    values.dischargeType === 'Inpatient Facility'
                  );
                },
              },
              {
                render: RenderPlusButton,
                size: 0.5,
                visible: ({values}) => {
                  return (
                    values.event === Transitional_Event_Type.Discharge &&
                    values.dischargeType === 'Inpatient Facility'
                  );
                },
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
