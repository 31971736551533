import ChatMainScreen from './screens/ChatMainScreen';
import GroupMembersList from './screens/GroupMembersList';
import {AddGroupMember} from './components/AddGroupMember';
import Chat from './screens/Chat';
import NoGroupIsSelected from './screens/NoGroupSelected';
import CreateChatGroupModal from './screens/CreateChatGroup.modal';

const stackRoutes = [
  {
    name: 'Groups',
    path: '/chat',
    component: Chat,
    type: 'stack',
    outlet: false,
    visible: user => {
      if (user?.chatAuthKey && user) {
        return true;
      }
      return false;
    },
    children: [
      {
        name: 'User Groups',
        path: 'userGroups',
        component: NoGroupIsSelected,
        children: [
          {
            name: 'create Chat',
            path: 'createChat',
            component: CreateChatGroupModal,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      {
        name: 'Patient Groups',
        path: 'patientGroups',
        component: NoGroupIsSelected,
        children: [
          {
            name: 'create Chat',
            path: 'createChat',
            component: CreateChatGroupModal,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      {
        name: 'User Groups Selected',
        path: 'userGroups/:channelId/:groupId',
        component: ChatMainScreen,
        children: [
          {
            name: 'create Chat',
            path: 'createChat',
            component: CreateChatGroupModal,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      {
        name: 'Patient Chat Selected',
        path: 'patientGroups/:channelId/:groupId',
        component: ChatMainScreen,
        children: [
          {
            name: 'create Chat',
            path: 'createChat',
            component: CreateChatGroupModal,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      {
        name: 'Group Members',
        path: 'patientGroups/:channelId/:groupId/GroupMembersList',
        component: GroupMembersList,
        children: [
          {
            name: 'Add Member',
            path: 'AddGroupMember',
            component: AddGroupMember,
            visible: user => user?.chatAuthKey,
            screenOptions: {
              modalSize: 'small',
            },
          },
        ],
      },
    ],
  },
  {
    name: 'Chat',
    path: '/groups/chat/:channelId/:groupId',
    component: ChatMainScreen,
    visible: user => user?.chatAuthKey,
  },
  {
    name: 'Chat',
    path: '/patient/chat/:channelId/:groupId',
    component: ChatMainScreen,
    visible: user => user?.chatAuthKey,
  },
  {
    name: 'Chat',
    path: '/patientgroups/chat/:channelId/:groupId',
    component: ChatMainScreen,
    visible: user => user?.chatAuthKey,
  },
];

const modalRoutes = [];

export default {
  stack: stackRoutes,
  modal: modalRoutes,
};
