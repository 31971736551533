export const Patient_LIST_EVENT_SOURCE_ID = 'PATIENT_LIST';
export const DEFAULT_LIMIT = 3;
export const Patient_Location_Type = {
  Home: 'Home',
  Facility: 'Facility',
};

export const Patient_Type = {
  longTermCare: 'Long Term Care',
  skilledNursing: 'Skilled Nursing',
};
export const Patient_Location_Status = {
  HOME: 'Home',
  OUT: 'Out',
  NONE: 'None',
};
export const Transitional_Event_Type = {
  Discharge: 'Discharge',
  Admission: 'Admission',
  Arrival: 'Arrival',
};
export const PatientClass = {
  Emergency: 'Emergency',
  Inpatient: 'Inpatient',
  Outpatient: 'Outpatient',
};

export const defaultContactMode = {
  name: 'In Person',
  _id: '6672b15ab78c242eb96f754f',
};

export const defaultContactPerson = {
  name: 'Care Team',
  _id: '6672b15ab78c242eb96f7555',
};
