import React, {useEffect, useState} from 'react';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {useLocation, useParams} from 'react-router-dom';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import {USER_TYPE} from '../../../constant/userType';
import {entity} from '../../../constant/authorization';
import {TableHeader} from '../../../components/header/TableHeader';
import HttpAuth from '../../../services/HttpAuthService';
import {isAdmin} from '../../../utils/checkUserType';

export const AddCareTeamMember = props => {
  const {patientId} = useParams();
  const {state} = useLocation();
  const {user} = useAuth();
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.addCareTeamMember}`,
    eventSourceId: 'careTeamMembers',
    method: 'post',
  });
  const [patientDetails, setPatientDetails] = useState({});
  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const response = await HttpAuth.get(routeLink.getPatient, {
          params: {
            filter: {
              _id: patientId,
            },
            fields: {
              primaryPractice: 1,
              practices: 1,
              currentLocation: 1,
            },
            only: true,
          },
        });
        setPatientDetails(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPatient();
  }, []);
  return (
    <>
      <TableHeader
        title="Add Care Team Members"
        secondaryTitle={state?.patient?.FullName}
      />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          patient: patientId,
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'autoComplete',
                field: 'employerType',
                label: 'Employer Type',
                api: routeLink.getEmployerTypes,
                suggestionField: 'name',
                size: 'medium',
                sort: {name: 1},
                visible: user?.userType?.name === USER_TYPE.ADMIN,
              },
              {
                type: 'autoComplete',
                field: 'facilityId',
                label: 'Facility',
                api: routeLink.getFacilities,
                sort: {name: 1},
                suggestionField: 'name',
                size: 'medium',
                filter: () => {
                  return JSON.stringify({
                    status: STATUS.ACTIVE,
                    _id: patientDetails?.currentLocation,
                  });
                },
                visible: ({values}) => {
                  return (
                    user?.userType?.name === USER_TYPE.ADMIN &&
                    values?.employerType?.name === entity.facilities
                  );
                },
              },
              {
                type: 'autoComplete',
                field: 'practiceId',
                label: 'Practice',
                api: routeLink.addPractice,
                sort: {name: 1},
                suggestionField: 'name',
                size: 'medium',
                filter: () => {
                  return JSON.stringify({
                    status: STATUS.ACTIVE,
                    _id: {
                      $in: [
                        patientDetails?.primaryPractice,
                        ...(patientDetails.practices || []),
                      ],
                    },
                  });
                },
                visible: ({values}) => {
                  return (
                    user?.userType?.name === USER_TYPE.ADMIN &&
                    values?.employerType?.name === entity.practices
                  );
                },
              },
              [
                {
                  type: 'autoComplete',
                  field: 'user',
                  label: 'Team Member',
                  api: routeLink.getUser,
                  sort: {name: 1},
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  size: 'medium',
                  required: true,
                  filter: ({values}) => {
                    if (
                      values?.employerType?.name === entity.facilities &&
                      values.facilityId
                    ) {
                      return JSON.stringify({
                        facilityId: values?.facilityId?._id,
                        status: STATUS.ACTIVE,
                      });
                    }
                    if (
                      values?.employerType?.name === entity.practices &&
                      values.practiceId
                    ) {
                      return JSON.stringify({
                        practiceId: values?.practiceId?._id,
                        status: STATUS.ACTIVE,
                      });
                    }
                    if (isAdmin()) {
                      return JSON.stringify({
                        status: STATUS.ACTIVE,
                        $or: [
                          {
                            practiceId: {
                              $in: [
                                patientDetails.primaryPractice,
                                ...(patientDetails.practices || []),
                              ],
                            },
                          },
                          {
                            facilityId: patientDetails?.currentLocation,
                          },
                        ],
                        _id: {$ne: user?._id},
                      });
                    }
                    return JSON.stringify({
                      practiceId: user?.practiceId?._id,
                      facilityId: user?.facilityId?._id,
                      status: STATUS.ACTIVE,
                    });
                  },
                },
              ],
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
