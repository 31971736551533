import {
  FETCH_DATA_FAILURE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  INCREASE_UNREAD_COUNT,
  SET_UNREAD_COUNT,
  SET_LAST_MESSAGE,
} from './chatReduxConstant';

export const fetchDataRequest = groupId => ({
  type: FETCH_DATA_REQUEST,
  payload: {
    groupId,
  },
});
export const fetchDataSuccess = (data, groupId) => ({
  type: FETCH_DATA_SUCCESS,
  payload: {
    data,
    groupId,
  },
});
export const fetchDataFailure = error => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const setUnreadCount = (data, groupId) => ({
  type: SET_UNREAD_COUNT,
  payload: {
    data,
    groupId,
  },
});

export const increaseUnreadCount = groupId => ({
  type: INCREASE_UNREAD_COUNT,
  payload: {
    groupId,
  },
});

export const updateLastMessage = (message, groupId) => ({
  type: SET_LAST_MESSAGE,
  payload: {
    message,
    groupId,
  },
});
