import React from 'react';
import {GroupListContainer} from '../styles/Chat.style';
import {
  ActionContainer,
  TogglerButton,
  TogglerStyle,
} from '../styles/GroupList.style';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import PatientChatList from 'src/modules/patient/screens/PatientChatList';
import GroupsLists from './GroupsLists';

const ChatSideBar = () => {
  const navigate = useNavigate();
  const {patientId} = useParams();

  const {pathname} = useLocation();
  const splitParts = pathname?.split('/');
  const patientChat = splitParts.includes('patientGroups');

  return (
    <GroupListContainer>
      {!patientId && (
        <ActionContainer>
          <TogglerStyle key="toggler">
            <TogglerButton
              onClick={() => {
                navigate('/chat/userGroups');
              }}
              selected={!patientChat}>
              Users Chats
            </TogglerButton>
            <TogglerButton
              onClick={() => {
                navigate('/chat/patientGroups');
              }}
              selected={patientChat}>
              Patients Chats
            </TogglerButton>
          </TogglerStyle>
        </ActionContainer>
      )}
      {patientChat || patientId ? <PatientChatList /> : <GroupsLists />}
    </GroupListContainer>
  );
};

export default ChatSideBar;
