import styled from 'styled-components';
import theme from '../theme/Theme';

const NavLink = styled.div`
  cursor: pointer;
`;

const ChildNavLink = styled.div`
  padding-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
`;

const ParentNavLink = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
`;

const NavLinkText = styled.text`
  padding-left: 8px;
`;
const NavIconText = styled.div``;
const NavLinkNotification = styled.div`
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1px 2px 1px 2px;
  border-radius: 4px;
  background-color: ${props =>
    props.isSelected
      ? theme.colors.SIDEBAR_BACKGROUND_HIGH
      : theme.colors.BACKGROUND};
  color: ${props =>
    props.isSelected
      ? theme.colors.BACKGROUND
      : theme.colors.SIDEBAR_BACKGROUND_HIGH};
  font-size: 14px;
`;

const NavItem = styled.div`
  background-color: ${props =>
    props.isSelected
      ? theme.colors.BACKGROUND
      : theme.colors.SIDEBAR_BACKGROUND};
  color: ${props =>
    props.isSelected
      ? theme.colors.SIDEBAR_BACKGROUND_MEDIUM
      : theme.colors.BACKGROUND};
  padding-left: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 12px;
`;

export {
  ChildNavLink,
  NavItem,
  NavLink,
  NavLinkText,
  NavIconText,
  ParentNavLink,
  NavLinkNotification,
};
