import React from 'react';
import ChatContent from './ChatContent';
import {ChatMainScreenContainer} from '../styles/Chat.style';

const ChatMainScreen = props => {
  return (
    <ChatMainScreenContainer>
      <ChatContent props={props} />
    </ChatMainScreenContainer>
  );
};

export default ChatMainScreen;
