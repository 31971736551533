const activation = '/images/activation.svg';
const AddFacility = '/images/facilityAdd.svg';
const AddPractice = '/images/practiceAdd.svg';
const addIcon = '/images/addIcon.svg';
const addUser = '/images/user-plus-02.svg';
const Admin = '/images/admin.svg';
const AdminSelected = '/images/adminSelected.svg';
const ambulance = '/images/ambulance.svg';
const ambulanceSelected = '/images/ambulanceSelected.svg';
const ArrowDown = '/images/arrowDown.svg';
const ArrowDownBlack = '/images/arrowDownBlack.svg';
const arrowUp = '/images/arrowup.svg';
const ArrowLeft = '/images/arrowLeft.svg';
const ArrowRight = '/images/arrowRight.svg';
const avatar = '/images/Avatar.svg';
const Buildings = '/images/buildings.svg';
const buildingDark = '/images/buildingsDark.svg';
const BuildingsSelected = '/images/buildingsSelected.svg';
const calender = '/images/calender.svg';
const chatSmile = '/images/chatSmile.svg';
const chatSmileSelected = '/images/chatSmileSelected.svg';
const checkCircle = '/images/check-circle.svg';
const checkDouble = '/images/check-double.svg';
const closeCircle = '/images/close-circle.svg';
const contact = '/images/contact.svg';
const contactalarm = '/images/contactalarm.svg';
const contactwarning = '/images/contactwarning.svg';
const contactsuccess = '/images/contactsuccess.svg';
const contactSuccess = '/images/contactSuccess.svg';
const Cross = '/images/Cross.svg';
const Crossdark = '/images/Crossdark.svg';
const DateNext = '/images/DateNext.svg';
const DatePrevious = '/images/DatePrevious.svg';
const edit = '/images/edit.svg';
const EliteCareTechnology = '/images/EliteCareTechnology.svg';
const expand = '/images/expand.svg';
const Eye = '/images/Eye.svg';
const fetchClinicalSummaryFailed = '/images/fetchClinicalSummaryfailed.svg';
const fetchClinicalSummarySuccess = '/images/fetchClinicalSummarySuccess.svg';
const flagFr = '/images/flag-french.png';
const flagUk = '/images/flag-uk.png';
const form1823 = '/images/form1823.svg';
const form1823warning = '/images/form1823warning.svg';
const form1823alarm = '/images/form1823alarm.svg';
const form1823success = '/images/form1823success.svg';
const FullBrandLogo = '/images/FullBrandLogo.png';
const GrayhourGlass = '/images/GrayhourGlass.svg';
const groupMembers = '/images/groupMembers.svg';
const homeOutline = '/images/home-outline.png';
const Home = '/images/home.svg';
const HomeSelected = '/images/homeSelected.svg';
const homeSolid = '/images/home-solid.png';
const lightEdit = '/images/lightEdit.svg';
const location = '/images/location.svg';
const logo = '/images/logo.png';
const logout = '/images/logout.svg';
const logs = '/images/logs.svg';
const logsSelected = '/images/logsSelected.svg';
const medRec = '/images/medRec.svg';
const medRecsuccess = '/images/medRecsuccess.svg';
const medRecwarning = '/images/medRecwarning.svg';
const medRecalarm = '/images/medRecalarm.svg';

const medRecComplete = '/images/medRecCompleted.svg';
const MiniBrandLogo = '/images/MiniBrandLogo.png';
const moon = '/images/moon.png';
const NavRight = '/images/navRight.svg';
const newRequest = '/images/newRequest.svg';
const newRequestSelected = '/images/newRequestSelected.svg';
const notes = '/images/homeHealthSelected.svg';
const notessuccess = '/images/homeHealthSelectedsuccess.svg';
const notesalarm = '/images/homeHealthSelectedalarm.svg';
const noteswarning = '/images/homeHealthSelectedwarning.svg';
const PatientChat = '/images/patientChat.svg';
const PatientChatSelected = '/images/patientChatSelected.svg';
const Patients = '/images/patients.svg';
const PatientsSelected = '/images/patientsSelected.svg';
const practices = '/images/practices.svg';
const practicesSelected = '/images/practicesSelected.svg';
const profileOutline = '/images/profile-outline.png';
const profileSolid = '/images/profile-solid.png';
const RedhourGlass = '/images/RedhourGlass.svg';
const residence = '/images/residence.svg';
const request = '/images/request.svg';
const requestSelected = '/images/requestSelected.svg';
const Send = '/images/send.png';
const sendAlt = '/images/sendalt.svg';
const setting = '/images/settings.svg';
const settingsDark = '/images/settingDarkVector.svg';
const showLess = '/images/showLess.svg';
const showMore = '/images/showMore.svg';
const stopwatch = '/images/stopwatch.svg';
const Submit = '/images/Submit.svg';
const SubmitActive = '/images/SubmitActive.svg';
const sun = '/images/sun.png';
const Tasks = '/images/tasks.svg';
const Taskswarning = '/images/taskswarning.svg';
const TasksSelected = '/images/tasksSelected.svg';
const Tick = '/images/tick.svg';
const trash = '/images/trash.svg';
const Upload = '/images/upload.svg';
const user = '/images/avatar.svg';
const visit = '/images/visit.svg';
const visitsuccess = '/images/visitsuccess.svg';
const visitalarm = '/images/visitalarm.svg';
const visitwarning = '/images/visitwarning.svg';
const YellowhourGlass = '/images/YellowhourGlass.svg';
const spaceDashboard = '/images/space_dashboard.svg';
const spaceDashboardSelected = '/images/space_dashboardSelected.svg';
const change = '/images/change.svg';
const chatSmileGreen = '/images/chatSmileGreen.svg';
const doubleArrowLeft = '/images/double-arrow-left.svg';
const doubleArrowRight = '/images/double-arrow-right.svg';
const noDataAvailable = '/images/no-data.svg';
const addChats = '/images/addChats.svg';
export default {
  checkCircle,
  checkDouble,
  closeCircle,
  chatSmileSelected,
  chatSmile,
  avatar,
  logo,
  sun,
  moon,
  flagFr,
  flagUk,
  homeSolid,
  homeOutline,
  profileSolid,
  profileOutline,
  Cross,
  Crossdark,
  Buildings,
  BuildingsSelected,
  Admin,
  AdminSelected,
  Patients,
  PatientsSelected,
  Tasks,
  TasksSelected,
  Home,
  HomeSelected,
  edit,
  addUser,
  logout,
  trash,
  activation,
  FullBrandLogo,
  MiniBrandLogo,
  DateNext,
  DatePrevious,
  ArrowRight,
  ArrowLeft,
  ArrowDown,
  ArrowDownBlack,
  NavRight,
  AddPractice,
  AddFacility,
  Tick,
  Eye,
  Upload,
  Send,
  sendAlt,
  setting,
  Submit,
  SubmitActive,
  spaceDashboard,
  spaceDashboardSelected,
  groupMembers,
  expand,
  addIcon,
  contactSuccess,
  medRecComplete,
  PatientChat,
  PatientChatSelected,
  showMore,
  showLess,
  stopwatch,
  fetchClinicalSummaryFailed,
  fetchClinicalSummarySuccess,
  location,
  calender,
  GrayhourGlass,
  YellowhourGlass,
  RedhourGlass,
  residence,
  settingsDark,
  user,
  EliteCareTechnology,
  request,
  requestSelected,
  logs,
  logsSelected,
  practices,
  practicesSelected,
  lightEdit,
  ambulance,
  buildingDark,
  ambulanceSelected,
  contact,
  contactalarm,
  contactwarning,
  contactsuccess,

  medRec,
  medRecsuccess,
  medRecwarning,
  medRecalarm,
  visit,
  visitsuccess,
  visitalarm,
  visitwarning,
  notes,
  notessuccess,
  noteswarning,
  notesalarm,
  arrowUp,
  newRequest,
  newRequestSelected,
  form1823,
  form1823warning,
  form1823success,
  form1823alarm,
  Taskswarning,
  chatSmileGreen,
  change,
  doubleArrowLeft,
  doubleArrowRight,
  noDataAvailable,
  addChats,
};
