import React from 'react';
import Config from '../../../Config';
import {useFilter} from '@unthinkable/react-filters';
import {SearchFilter} from '../../../components/filter/Filters';
import {routeLink} from '../../../constant';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import {
  getGroupName,
  getTimeFromTimeToken,
  isToday,
} from '../utils/ChatHelperFunctions';
import {
  ActionContainer,
  Avatar,
  AvatarContainer,
  TableContainerStyles,
  ActionRowStyles,
  CreateChatIcon,
  ChatListItem,
  ItemContent,
} from '../styles/GroupList.style';
import images from 'src/assets/images';
import {BasicTable} from 'src/components/table/Table';
import {Row} from '@unthinkable/react-core-components';
import {
  SecondaryTitleText,
  TitleText,
} from '@unthinkable/react-screen-components/src/styles/HeaderStyles';
import {useTheme} from '@unthinkable/react-theme';
import GetCountMessages from '../utils/GetCountMessages';
import {useNavigate, useParams} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {Icon} from 'src/components/icon';
import moment from 'moment';
import GetLastMessage from '../utils/GetLastMessage';

const {defaultListLimit} = Config;

const GroupsLists = props => {
  const {searchValue, onChangeFilter} = useFilter();
  const navigate = useNavigate();
  const theme = useTheme();
  const {groupId: paramsGroupId} = useParams();
  const {pathname} = useLocation();

  // const {params: filterParams = {}} = filterValues;
  const filter = {
    status: STATUS.ACTIVE,
    'groupId.patientId': {$exists: false},
    // $or: [
    //   {'groupId.practiceId': {$exists: true}},
    //   {'groupId.facilityId': {$exists: true}},
    // ],
  };

  // if (filterParams?.['groupId.facilityId']) {
  //   filter.$and = filter.$and || [];
  //   filter['$and'].push({
  //     'groupId.facilityId': filterParams['groupId.facilityId'],
  //   });
  // }
  // if (filterParams?.['groupId.practiceId']) {
  //   filter.$and = filter.$and || [];
  //   filter['$and'].push({
  //     'groupId.practiceId': filterParams['groupId.practiceId'],
  //   });
  // }

  const renderGroup = ({row}) => {
    let date;
    if (row?.lastMessageTimeToken) {
      date = getTimeFromTimeToken(row?.lastMessageTimeToken);
    }
    return (
      <ChatListItem row={row} selectedGroupId={paramsGroupId}>
        {/* <Indicator /> */}
        <AvatarContainer>
          <Avatar src={images.avatar} />
        </AvatarContainer>
        <ItemContent>
          <Row style={{justifyContent: 'space-between'}}>
            <TitleText styles={{title: {...theme.fonts.BODY1}}}>
              {row?.groupId?.facilityId && row?.groupId?.practiceId
                ? getGroupName(row.groupId)
                : row?.groupId?.name}
            </TitleText>
            {date && (
              <SecondaryTitleText
                styles={{secondaryTitle: {...theme.fonts.CAPTION_SMALL}}}>
                {}
                {isToday(date)
                  ? moment(date).local().format(Config.momentTimeFormat)
                  : moment(date).local().format('DD-MM-YYYY')}
              </SecondaryTitleText>
            )}
          </Row>

          <SecondaryTitleText
            styles={{secondaryTitle: {...theme.fonts.CAPTION_SMALL}}}>
            {row?.groupId?._id === paramsGroupId
              ? row.groupId?.lastMessage
              : GetLastMessage({row: row})}
          </SecondaryTitleText>
        </ItemContent>
        {row?.groupId?._id !== paramsGroupId && GetCountMessages({row})}
      </ChatListItem>
    );
  };
  return (
    <>
      <ActionContainer>
        <Row style={ActionRowStyles}>
          <SearchFilter
            value={searchValue}
            onChangeFilter={onChangeFilter}
            key={'SearchFilter'}
            placeholder={'Search groups'}
            width={'80%'}
          />
          <Icon
            style={CreateChatIcon}
            src={images.addChats}
            title={'Create Group'}
            onClick={() => {
              navigate(`${pathname}/createChat`);
            }}
          />
        </Row>
      </ActionContainer>
      <TableContainerStyles>
        <BasicTable
          style={{
            row: {
              padding: '0px',
            },
          }}
          noDataText={'No groups found'}
          onRowPress={({row}) => {
            navigate(
              `userGroups/${row?.groupId?.PNGroupId}/${row?.groupId?._id}`,
            );
          }}
          filter={JSON.stringify(filter)}
          search={searchValue}
          searchFields={['groupId.name']}
          api={routeLink.getChatGroups}
          eventSourceId="chatgroups"
          perPage={5 || defaultListLimit}
          columns={[
            {
              render: renderGroup,
            },
          ]}
          {...props}
        />
      </TableContainerStyles>
    </>
  );
};

export default GroupsLists;
