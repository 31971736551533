import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Text, View, Row} from '@unthinkable/react-core-components';
import Config from '../../../Config';
import {routeLink} from '../../../constant';
import {getPatientsFullName} from '../../../utils/commonMethod';
import {TodayTaskCardStyle} from '../styles/TodayTaskCard.style';
import {TASK_TYPE_TO_SHOW} from '../../../constant/taskType';
import {Table} from '../../../components/table';
import {TableHeader} from '../../../components/header/TableHeader';
import {taskFields} from '../../../constant/task.fields';
import {TaskAction} from '../../../services/taskService';

export const TodayTaskTable = props => {
  const {filter, reduxKey} = props;
  const navigate = useNavigate();
  const {defaultListLimit} = Config;

  const renderTaskCard = ({row, index}) => {
    const borderColor = index % 2 !== 0 ? '#4EA875' : 'ff9d2b';

    return (
      <View style={{...TodayTaskCardStyle.container, borderColor}}>
        <Row style={{justifyContent: 'space-between', alignItems: 'center'}}>
          <View>
            <Text style={TodayTaskCardStyle.Heading}>
              {TASK_TYPE_TO_SHOW[row?.type]}
            </Text>
            <Text style={TodayTaskCardStyle.SubHeading}>
              {getPatientsFullName(row?.patientId)}
            </Text>
          </View>
          <View>{TaskAction(row)}</View>
        </Row>
      </View>
    );
  };
  const columns = [
    {
      render: renderTaskCard,
    },
  ];
  const fields = taskFields;
  return (
    <View style={{backgroundColor: '#fff'}}>
      <Table
        reduxKey={reduxKey}
        renderHeader={() => <TableHeader title={'Upcoming Tasks'} />}
        onRowPress={({row}) => {
          navigate(`/patient/details/${row?.patientId?._id}/tcmEncounter`, {
            state: {
              patient: row.patientId,
              encounter: row.encounterId,
            },
          });
        }}
        noDataText={'No task found'}
        style={{
          border: true,
          rowBorder: true,
          columnPadding: true,
          container: {
            backgroundColor: '#FFF',
            borderRadius: '6px',
          },
        }}
        sort={{isDateBounded: -1, onGoingTcmDueDate: 1, _id: 1}}
        api={`${routeLink.getTCMTask}`}
        eventSourceId="tcmtasks"
        addOnFilter={JSON.stringify(filter)}
        perPage={7 || defaultListLimit}
        fields={fields}
        columns={columns}
        marginPagesDisplayed={1}
        paginationComponentSize="medium"
        {...props}
      />
    </View>
  );
};
