import Theme from 'src/theme/Theme';
import styled from 'styled-components';

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 12px;
  padding: 8px;
  width: 100%;
`;
export const ActionRowStyles = {
  alignItems: 'center',
  gap: '6px',
  justifyContent: 'space-around',
  width: '100%',
};

export const CreateChatIcon = {
  width: '30px',
  height: '30px',
};

const TableContainerStyles = styled.div`
  height: calc(100vh - 178px);
  overflow-y: scroll;
`;
const AvatarContainer = styled.div`
  max-width: 30%;
`;
const Indicator = styled.div`
  width: 10px;
  height: 100%;
  // border-radius: 50%;
  background-color: grey;
`;
const Avatar = styled.img`
  overflow: hidden;
  display: flex;
  flex: 1;
  height: 40px;
  width: 40px;
`;

const TogglerStyle = styled.div`
  display: flex;
  border: 1px solid black;
  border-radius: 20px;
  width: 100%;
`;

const TogglerButton = styled.div`
  padding: 8px 12px;
  border-radius: 20px;
  background-color: ${props =>
    props.selected ? Theme.colors.NEUTRAL_HIGH : Theme.colors.BACKGROUND};
  color: ${props =>
    props.selected ? Theme.colors.BACKGROUND : Theme.colors.NEUTRAL_HIGH};
  ${props => props.style || ''}
  font-size:12px;
  user-select: none;
  cursor: pointer;
  width: 50%;
  text-align: center;
`;

export const ChatListItem = styled.div`
  display: flex;
  gap: 12px;
  padding: 16px;
  margin: -8px -12px;
  background-color: ${({row, selectedGroupId}) =>
    row?.groupId?._id === selectedGroupId ? '#ebedef' : '#fff'};
  justify-content: space-between;
  border-bottom: 1px solid #ebedef;
`;

export const ItemContent = styled.div`
  flex: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  gap: 2px;
  display: flex;
  flex-direction: column;
`;

export {
  ActionContainer,
  TableContainerStyles,
  Avatar,
  AvatarContainer,
  TogglerStyle,
  TogglerButton,
  Indicator,
};
