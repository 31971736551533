/* eslint-disable no-undef */
const url = process.env.REACT_APP_API_URL;
const en_k1 = process.env.REACT_APP_EN_K1;
const en_k2 = process.env.REACT_APP_EN_K2;
const socketUrl = process.env.REACT_APP_SOCKET_URL;

const BASE_URL = url;

const fetchUrl = BASE_URL;
const postUrl = BASE_URL;
const uploadUrl = BASE_URL;
const downloadUrl = BASE_URL;

const defaultListLimit = 10;
const idleTimeoutMinutes = 15;

const isEncryptionEnabled = process.env?.NODE_ENV !== 'development';
const dateFormat = 'MM/DD/YY';
const DOBFormat = 'MM/DD/YYYY';
const dateTimeFormat = 'MM/DD/YY, HH:mm';

const timeFormat = 'hh:mm A';

const momentTimeFormat = 'LT'; // 'hh:mm Am/Pm'

const PUB_NUB_PUBLISH_KEY = process.env.REACT_APP_PUB_NUB_PUBLISH_KEY;
const PUB_NUB_SUBSCRIBE_KEY = process.env.REACT_APP_PUB_NUB_SUBSCRIBE_KEY;

const contactTaskDuration = 2;
const visitTaskDuration = 14;
const medicationTaskDuration = 14;
const itemsPerPageGraph = 5;

const ToSLink =
  process.env?.NODE_ENV == 'development'
    ? 'http://localhost:3000/pages/ToS.html'
    : process.env?.REACT_APP_TERMS_CONDITION_LINK;

const TabletBreakPoint = 1000;
const brandName = process.env.REACT_APP_BRAND_NAME;
const Config = {
  fetchUrl,
  postUrl,
  uploadUrl,
  downloadUrl,
  en_k1,
  en_k2,
  defaultListLimit,
  idleTimeoutMinutes,
  socketUrl,
  isEncryptionEnabled,
  dateFormat,
  DOBFormat,
  dateTimeFormat,
  timeFormat,
  momentTimeFormat,
  PUB_NUB_PUBLISH_KEY,
  PUB_NUB_SUBSCRIBE_KEY,
  contactTaskDuration,
  visitTaskDuration,
  medicationTaskDuration,
  ToSLink,
  TabletBreakPoint,
  brandName,
  itemsPerPageGraph,
};

export default Config;
