import React from 'react';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import {Form} from '../../../components/form';
import {TableHeader} from '../../../components/header/TableHeader';
import {routeLink} from '../../../constant';
import {useFormSubmit} from '../../../hooks';
import Config from 'src/Config';
import {Patient_LIST_EVENT_SOURCE_ID} from '../constants/PatientConstants';
import {useAuth} from 'src/modules/auth/hooks/useAuth';

const CreatePatientChatGroup = props => {
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.createPatientChatGroup}`,
    method: 'post',
    eventSourceId: [Patient_LIST_EVENT_SOURCE_ID],
  });
  const {user} = useAuth();
  const {state} = useLocation();
  const {patient, users} = state || {};
  return (
    <>
      <TableHeader
        title="Create New Patient Chat"
        secondaryTitle={`${patient.FullName} ${moment(patient.DOB).format(
          Config.DOBFormat,
        )}`}
      />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          patientId: patient._id,
          users: [
            // if changes any field over here ensure also updates in users that we extracting from state
            {
              _id: user._id,
              PNChatMemberId: user.PNChatMemberId,
              name: user.name,
              email: user.email,
            },
            ...(users?.map(user => ({
              _id: user._id,
              PNChatMemberId: user.PNChatMemberId,
              name: user.name,
              email: user.email,
            })) || []),
          ],
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'text',
                field: 'groupName',
                label: 'Group Name',
                required: true,
              },
              {
                type: 'multiAutoComplete',
                field: 'users',
                label: 'Users',
                api: routeLink.getCareTeamUsers,
                required: true,
                suggestionField: 'name',
                size: 'large',
                fields: {user: {PNChatMemberId: 1, name: 1, email: 1}},
                filter: ({values}) => {
                  return JSON.stringify({
                    residence: patient?.residence?._id,
                    currentLocation: patient?.currentLocation?._id,
                    primaryPractice: patient?.primaryPractice?._id,
                    practices: patient?.practices,
                    'user._id': {
                      $nin: [
                        ...(values?.users?.length > 0
                          ? values.users.map(user => user._id)
                          : []),
                      ],
                    },
                    patientId: values?.patientId,
                  });
                },
              },
              {
                type: 'text',
                field: 'message',
                label: 'Message',
                required: true,
                size: 'medium',
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};

export default CreatePatientChatGroup;
