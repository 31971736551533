import React, {forwardRef, useRef, useState} from 'react';
import {getFormatValue, typecast} from './DateUtility';
import SelectDate from './Calendar';
import {ResponsiveModal} from '@unthinkable/react-popper';
import './date';
import {View} from '@unthinkable/react-core-components';
import {InputContainer, InputWrapper, useInput} from '@unthinkable/react-input';
import {DateInputRender} from './DateInputRender';

const DateInput = forwardRef((props, ref) => {
  props = useInput({...props, ref});

  let {
    styles = {},
    mode,
    typecast: typecastProp,
    modalPosition,
    inputProps,
    inputRef,
  } = props;
  let {
    value,
    onChangeText,
    onChange,
    onChangeValue: onChangeValueProp,
    onBlur: onBlurProp,
  } = inputProps;
  const [stateValue, setStateValue] = useState();
  const [showModal, setShowModal] = useState(false);
  const elementRef = useRef(null);
  if (value === undefined || value === null) {
    value = '';
  }
  let formattedValue = getFormatValue(value, props);
  const onChangeValue = (value, e) => {
    if (typeof value === 'string') {
      value = value.trim();
    }
    let typecastDate = typecastProp || typecast;
    if (typecastDate) {
      value = typecastDate(value, props);
    } else {
      value = value ? new Date(value) : null;
      if (value === 'Invalid Date') {
        value = null;
      }
    }
    if (onChangeText) {
      onChangeText(value);
    }
    onChangeValueProp && onChangeValueProp(value, e);
    onChange && onChange(value);
  };

  const onDateChange = (value, e) => {
    onChangeValue(value, e);
    onBlurProp && onBlurProp(e);
  };

  const onBlur = e => {
    if (stateValue !== undefined) {
      onChangeValue(stateValue, e);
      setStateValue(void 0);
    }
    onBlurProp && onBlurProp(e);
  };

  const removeValue = e => {
    onChangeText && onChangeText(null);
    onChange && onChange(null);
    onChangeValueProp && onChangeValueProp(null);
    onBlurProp && onBlurProp(e);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  let ValueSelector = mode === 'time' ? null : SelectDate;
  return (
    <>
      <ResponsiveModal
        isVisible={showModal}
        styles={styles?.modal}
        position={modalPosition}
        referenceElement={elementRef?.current}
        hideModal={hideModal}>
        <ValueSelector
          {...props}
          value={formattedValue}
          styles={styles?.calendarStyle}
          mode={mode}
          hideModal={hideModal}
          onChange={onDateChange}
        />
      </ResponsiveModal>
      <View ref={elementRef}>
        <InputWrapper {...props}>
          <InputContainer {...props}>
            <DateInputRender
              {...props}
              {...inputProps}
              ref={inputRef}
              openModal={openModal}
              onBlur={onBlur}
              value={stateValue !== undefined ? stateValue : formattedValue}
              onChangeValue={setStateValue}
              removeValue={removeValue}
            />
          </InputContainer>
        </InputWrapper>
      </View>
    </>
  );
});

export default DateInput;
