import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {View, Text, Row} from '@unthinkable/react-core-components';
import {useToast} from '@unthinkable/react-toast';
import {
  ActiveTaskRow,
  EncounterTaskStyle,
} from '../screens/style/patientInfo.style';
import {getRemainingDaysMessage, getVariant} from '../../../utils/commonMethod';
import {TASK_STATUS, TASK_TYPE} from '../../../constant/taskType';
import images from '../../../assets/images';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {Icon} from '../../../components/icon';
import modalMessages from '../../../constant/ModalMessage';
import {isFacilityAdmin} from '../../../utils/checkUserType';

const variantWiseHourGlass = {
  alarm: 'RedhourGlass',
  warning: 'YellowhourGlass',
};

const taskRoutes = (task, encounter, pathname) => {
  const variant = getVariant(task, encounter);

  const routes = {
    [TASK_TYPE.ContactTask]: {
      icon: images[`contact${variant}`],
      path: `${pathname}/${encounter?._id}/${task?.taskId}/add-contact-attempts`,
    },
    [TASK_TYPE.VisitTask]: {
      icon: images[`visit${variant}`],
      path: `${pathname}/complete-visit/${task?.taskId}`,
    },
    [TASK_TYPE.MedicationTask]: {
      icon: images[`medRec${variant}`],
      path: `${pathname}/complete-medication/${task?.taskId}`,
    },
    [TASK_TYPE.NotesTask]: {
      icon: images[`notes${variant}`],
      path: `${pathname}/tcm-notes`,
      state: {taskId: task?._id},
    },
    [TASK_TYPE.Form1823]: {
      icon: images[`form1823${variant}`],
      path: `${pathname}/form1823/${task?.taskId}`,
    },
    [TASK_TYPE.INITIAL_TCM_ELIGIBILITY]: {
      icon: images[`Tasks${variant}`],
      path: `${pathname}/initial-eligibility`,
      state: {tcmTaskId: task?._id},
    },
    [TASK_TYPE.FACILITY_ASSIGNMENT]: {
      icon: images[`Tasks${variant}`],
      path: `${pathname}/facility-assignment`,
      state: {tcmTaskId: task?._id},
    },
    [TASK_TYPE.PATIENT_CLASSIFICATION]: {
      icon: images[`Tasks${variant}`],
      path: `${pathname}/patient-classification/${task?._id}`,
      state: {tcmTaskId: task?._id},
    },
    [TASK_TYPE.DISCHARGE_STATUS]: {
      icon: images[`Tasks${variant}`],
      path: `${pathname}/complete-discharge-status/${task?._id}`,
      state: {tcmTaskId: task?._id},
    },
  };

  return routes[task.type] || {};
};

const ActiveTasks = ({activeTasks, encounter, patient}) => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const {pathname} = useLocation();
  const IsFacilityAdmin = isFacilityAdmin();

  const handleOnPress = task => {
    const {path, state} = taskRoutes(task, encounter, pathname);

    if (!path) return;

    if (!checkAuthorization(user, entity[task.type], action.complete)) {
      toast({
        message: 'You do not have permission to complete this task',
        type: 'Error',
      });
      return;
    }

    if (
      IsFacilityAdmin &&
      task.type === TASK_TYPE.Form1823 &&
      task.status !== TASK_STATUS.Approved
    ) {
      toast({
        message:
          task.status === TASK_STATUS.Active
            ? modalMessages.form1823.active
            : modalMessages.form1823.rejected,
        type: 'Error',
      });
      return;
    }

    navigate(path, {state: {encounter, patient, ...state}});
  };

  return (
    <View style={EncounterTaskStyle.container}>
      <Text style={EncounterTaskStyle.heading}>Active Tasks</Text>
      {activeTasks?.map((task, index) => {
        const {icon} = taskRoutes(task, encounter, pathname);
        const variant = getVariant(task, encounter);

        return (
          <ActiveTaskRow
            key={index}
            variant={variant}
            onClick={() => handleOnPress(task)}>
            <Row style={EncounterTaskStyle.taskRow}>
              <Icon src={icon} />
              {task.type}
            </Row>
            {task.tcmDueDate && (
              <Row>
                <Icon
                  style={EncounterTaskStyle.Icon}
                  src={images[variantWiseHourGlass[variant]]}
                />
                <Text style={EncounterTaskStyle.dueDateText}>
                  {getRemainingDaysMessage(task)}
                </Text>
              </Row>
            )}
          </ActiveTaskRow>
        );
      })}
    </View>
  );
};

export default ActiveTasks;
