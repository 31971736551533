import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import {
  ActivityIndicator,
  Row,
  Text,
  View,
} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {HttpAuthService} from '../../../services';
import {routeLink} from '../../../constant';
import {PatientEncounterDetails} from '../screens/PatientEncounterDetail';
import Config from '../../../Config';
import {
  EncounterTimeLineStyle,
  tcmEncounterTableStyle,
  TaskTitle,
  EncounterTimelineOpenCloseIcon,
  LoadingContainer,
} from './style/tcmEncounterTable.style';
import {Icon} from '../../../components/icon';
import images from '../../../assets/images';
import {Button} from '../../../components/button';
import buttonType from '../../../constant/buttonType';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {getVariant} from '../../../utils/commonMethod';
import {encounterFields} from '../../../constant/encounter.fields';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';
import {getPatientById} from '../service/patient.service';
import {TaskUserHaveAccess} from 'src/modules/tasks/TaskFilter';

const PatientClassWiseSelectedIcon = {
  Inpatient: 'BuildingsSelected',
  Outpatient: 'HomeSelected',
  Emergency: 'ambulanceSelected',
};
const PatientClassWiseIcon = {
  Inpatient: 'buildingDark',
  Outpatient: 'homeOutline',
  Emergency: 'ambulance',
};
export const TCMEncounters = () => {
  const {patientId} = useParams();
  const {state} = useLocation();
  const {encounter: stateEncounter} = state || {};

  const [patient, setPatient] = useState(state?.patient);
  const [selectedEncounter, setSelectedEncounter] = useState(stateEncounter);
  const [encounters, setEncounters] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {user} = useAuth();
  const {isDesktop} = useMediaQuery();
  const [showEncounterTimeline, setShowEncounterTimeline] = useState(false);
  const {DatePrevious, DateNext} = useTheme('icons');
  const toggleEncounterTimeline = () => {
    setShowEncounterTimeline(!showEncounterTimeline);
  };
  const getEncounters = async () => {
    try {
      setLoading(true);
      const response = await HttpAuthService.get(routeLink.getTCMEncounter, {
        params: {
          sort: {admissionDate: -1, createdAt: -1},
          fields: encounterFields,
          filter: JSON.stringify({
            patientId: patientId,
          }),
        },
      });
      if (response?.data && response.data.length > 0) {
        // if (JSON.stringify(encounters) !== JSON.stringify(response.data)) {
        setEncounters(response.data);
        setSelectedEncounter(response.data[0]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const fetchPatient = async () => {
    try {
      if (!patient) {
        const response = await getPatientById(patientId);
        if (response?.data?.length === 0) {
          navigate('/patient');
        }
        setPatient(response?.data);
      }
    } catch (err) {
      navigate('/patient');
    }
  };
  useEffect(() => {
    fetchPatient();
    getEncounters();
  }, []);

  useDataFetchEvents(
    {
      eventSourceId: [
        'transitional-event',
        'addMedication',
        'tcm-notes-status',
        'visitAttempt',
        'contactAttempts',
        'tcm-encounters',
        'form1823Task',
        'facilityAssignment',
        'initialTcmEligibility',
        'patientClassification',
      ],
    },
    () => {
      getEncounters();
    },
  );

  if (loading || !patient) {
    return (
      <LoadingContainer>
        <ActivityIndicator></ActivityIndicator>
      </LoadingContainer>
    );
  }

  const taskUserHaveAccess = TaskUserHaveAccess({user});
  const filteredTasks = selectedEncounter?.taskInfo?.filter(task =>
    taskUserHaveAccess.includes(task?.type),
  );
  return (
    <>
      {selectedEncounter && patient ? (
        <View style={tcmEncounterTableStyle}>
          {(!showEncounterTimeline || isDesktop) && (
            <PatientEncounterDetails
              patient={patient}
              encounter={selectedEncounter}
            />
          )}
          {!isDesktop && (
            <EncounterTimelineOpenCloseIcon
              src={showEncounterTimeline ? DateNext : DatePrevious}
              onClick={toggleEncounterTimeline}
              isOpen={showEncounterTimeline}
            />
          )}
          {(showEncounterTimeline || isDesktop) && (
            <View style={EncounterTimeLineStyle.container}>
              <Row style={{alignItems: 'center', gap: '10px'}}>
                <Text style={EncounterTimeLineStyle.heading}>
                  Encounter Timeline
                </Text>
                {encounters?.length &&
                  encounters[0]?.dischargeDate &&
                  checkAuthorization(user, entity.encounter, action.create) && (
                    <Button
                      key="create New Encounter"
                      onPress={() => {
                        navigate('create-new-encounter');
                      }}
                      icon={images.addIcon}
                      iconPosition="right"
                      buttonType={buttonType.linkButtonWithBorder}
                    />
                  )}
              </Row>

              <View style={EncounterTimeLineStyle.timeLineContainer}>
                {encounters &&
                  encounters.length > 0 &&
                  encounters.map((encounter, index) => {
                    if (
                      encounter?._id?.toString() ===
                      selectedEncounter?._id?.toString()
                    ) {
                      return (
                        <View
                          key={index}
                          style={
                            EncounterTimeLineStyle.selectedEncounterContainer
                          }>
                          <View>
                            <Row style={{alignItems: 'center', gap: '8px'}}>
                              <Icon
                                src={
                                  images[
                                    PatientClassWiseSelectedIcon[
                                      encounter.patientClass
                                    ]
                                  ]
                                }
                              />
                              <Text style={EncounterTimeLineStyle.textSelected}>
                                {encounter?.admissionDate
                                  ? moment
                                      .utc(encounter.admissionDate)
                                      .format(Config.dateFormat)
                                  : '---'}
                              </Text>
                              <Text
                                style={{
                                  ...EncounterTimeLineStyle.textSelected,
                                  fontSize: '12px',
                                }}>
                                {encounter.status}
                              </Text>
                            </Row>
                          </View>
                          {filteredTasks?.map((task, index) => {
                            const variant = getVariant(task, encounter);
                            return (
                              <TaskTitle key={index} variant={variant}>
                                {task.type}
                              </TaskTitle>
                            );
                          })}
                        </View>
                      );
                    }
                    return (
                      <View
                        key={index}
                        onClick={() => {
                          setSelectedEncounter(encounter);
                          setShowEncounterTimeline(false);
                        }}>
                        <Row style={{alignItems: 'center', gap: '8px'}}>
                          <Icon
                            src={
                              images[
                                PatientClassWiseIcon[encounter.patientClass]
                              ]
                            }
                          />
                          <Text style={EncounterTimeLineStyle.text}>
                            {moment
                              .utc(encounter.admissionDate)
                              .format(Config.dateFormat)}
                          </Text>
                          <Text style={{fontSize: '12px', cursor: 'pointer'}}>
                            {encounter.status}
                          </Text>
                        </Row>
                      </View>
                    );
                  })}
              </View>
            </View>
          )}
        </View>
      ) : (
        <>
          {checkAuthorization(user, entity.encounter, action.create) && (
            <Row style={{justifyContent: 'flex-end'}}>
              <Button
                key="create New Encounter"
                text="Create New Encounter"
                onPress={() => {
                  navigate('create-new-encounter');
                }}
                iconPosition="right"
                buttonType={buttonType.linkButtonWithBorder}
              />
            </Row>
          )}
          <LoadingContainer>
            <Text>No Data Found</Text>
          </LoadingContainer>
        </>
      )}
    </>
  );
};
