import React, {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useFilter} from '@unthinkable/react-filters';
import {Row} from '@unthinkable/react-core-components';
import {
  SecondaryTitleText,
  TitleText,
} from '@unthinkable/react-screen-components/src/styles/HeaderStyles';
import {useTheme} from '@unthinkable/react-theme';
import Config from '../../../Config';
import {SearchFilter} from '../../../components/filter/Filters';
import {Table} from '../../../components/table';
import {STATUS} from '../../user/constants/UserConstants';
import {routeLink} from '../../../constant';
import GetCountMessages from '../../chat/utils/GetCountMessages';
import images from 'src/assets/images';
import {
  getGroupName,
  getTimeFromTimeToken,
  isToday,
} from 'src/modules/chat/utils/ChatHelperFunctions';
import {
  ActionContainer,
  ActionRowStyles,
  Avatar,
  AvatarContainer,
  ChatListItem,
  CreateChatIcon,
  ItemContent,
} from 'src/modules/chat/styles/GroupList.style';
import GetLastMessage from 'src/modules/chat/utils/GetLastMessage';
import {Icon} from 'src/components/icon';
import moment from 'moment';

const {defaultListLimit} = Config;

const PatientChatList = props => {
  const navigate = useNavigate();
  const {pathname, state} = useLocation();
  const {groupId: paramsGroupId, patientId} = useParams();
  const fromFacilityStatsOverview = pathname
    .split('/')
    .find(path => path === 'facility');
  const {facility} = state || {};
  const {searchValue, onChangeFilter, filterValues} = useFilter({
    filterKey: patientId ? 'PatientDetails' : 'PatientChatList',
  });
  const theme = useTheme();

  filterValues.filter = {
    ...filterValues.filter,
    'patientDetails.status': STATUS.ACTIVE,
  };
  if (patientId) {
    filterValues.filter['patientId'] = patientId;
  }
  if (fromFacilityStatsOverview) {
    filterValues.filter['patientDetails.currentLocation'] = facility;
  }
  useEffect(() => {
    if (fromFacilityStatsOverview && !facility) {
      navigate('/dashboard');
    }
  });

  const renderGroup = ({row}) => {
    let date;
    if (row?.lastMessageTimeToken) {
      date = getTimeFromTimeToken(row?.lastMessageTimeToken);
    }
    return (
      <ChatListItem row={row} selectedGroupId={paramsGroupId}>
        <AvatarContainer>
          <Avatar src={images.avatar} />
        </AvatarContainer>
        <ItemContent>
          <Row style={{justifyContent: 'space-between'}}>
            <TitleText styles={{title: {...theme.fonts.BODY1}}}>
              {row?.groupId?.facilityId && row?.groupId?.practiceId
                ? getGroupName(row.groupId)
                : row?.groupId?.name}
            </TitleText>
            {date && (
              <SecondaryTitleText
                styles={{secondaryTitle: {...theme.fonts.CAPTION_SMALL}}}>
                {}
                {isToday(date)
                  ? moment(date).local().format(Config.momentTimeFormat)
                  : moment(date).local().format('DD-MM-YYYY')}
              </SecondaryTitleText>
            )}
          </Row>
          <SecondaryTitleText
            styles={{secondaryTitle: {...theme.fonts.CAPTION_SMALL}}}>
            {row?.groupId?._id === paramsGroupId
              ? row.groupId?.lastMessage
              : GetLastMessage({row})}
          </SecondaryTitleText>
        </ItemContent>
        {row?.groupId?._id !== paramsGroupId && GetCountMessages({row})}
      </ChatListItem>
    );
  };

  return (
    <>
      <ActionContainer>
        <Row style={ActionRowStyles}>
          <SearchFilter
            value={searchValue}
            onChangeFilter={onChangeFilter}
            key={'SearchFilter'}
            placeholder={'Search groups'}
            width={'80%'}
          />
          {!patientId && (
            <Icon
              style={CreateChatIcon}
              src={images.addChats}
              title={'Create Group'}
              onClick={() => {
                navigate(`${pathname}/createChat`);
              }}
            />
          )}
        </Row>
      </ActionContainer>
      <Table
        key={patientId ? 'PatientDetails' : 'PatientChatListTable'}
        noDataText={'No groups found'}
        onRowPress={({row}) => {
          patientId
            ? navigate(
                `/patient/details/${patientId}/chat/patientGroups/${row?.groupId?.PNGroupId}/${row?.groupId?._id}`,
              )
            : navigate(
                `/chat/patientGroups/${row?.groupId?.PNGroupId}/${row?.groupId?._id}`,
              );
        }}
        addOnFilter={JSON.stringify(filterValues.filter)}
        search={searchValue}
        searchFields={['patientDetails.FullName']}
        api={routeLink.getPatientChatGroups}
        eventSourceId="chatgroups"
        perPage={defaultListLimit}
        columns={[
          {
            render: renderGroup,
          },
        ]}
        {...props}
      />
    </>
  );
};

export default PatientChatList;
