import {TASK_TYPE} from './taskType';
import {USER_TYPE} from './userType';

export const action = {
  view: 'view',
  create: 'create',
  edit: 'edit',
  delete: 'delete',
  complete: 'complete',
  request: 'request',
  approve: 'approve',
  viewAll: 'viewAll',
  viewOwn: 'viewOwn',
  editView: 'editView',
};

export const entity = {
  user: 'User',
  practices: 'Practices',
  facilities: 'Facilities',
  adminFacilities: 'AdminFacilities',
  chat: 'Chat',
  Tasks: 'Tasks',
  ContactTask: 'ContactTask',
  VisitTask: 'VisitTask',
  MedicationTask: 'MedicationTask',
  NotesTask: 'NotesTask',
  TcmTasks: 'TcmTasks',
  patient: 'Patients',
  careTeamMembers: 'CareTeamMembers',
  medication: 'Medication',
  diagnose: 'Diagnose',
  facilityStats: 'Facility Stats',
  yourPatient: 'YourPatient',
  cronSettings: 'Cron Settings',
  dashboard: 'Dashboard',
  patientChat: 'Patient Chat',
  encounter: 'Encounter',
  transitionalEvent: 'TransitionalEvent',
  fetchClinicalSummary: 'FetchClinicalSummary',
  patientType: 'PatientType',
  exportPatient: 'ExportPatient',
  importPatient: 'ImportPatient',
  downloadReport: 'DownloadReport',
};

entity[TASK_TYPE.FACILITY_ASSIGNMENT] = 'FacilityAssignment';
entity[TASK_TYPE.INITIAL_TCM_ELIGIBILITY] = `InitialTcmEligibility`;
entity[TASK_TYPE.Form1823] = 'form1823';
entity[TASK_TYPE.FACILITY_ONBOARDING] = 'FacilityOnBoarding';
entity[TASK_TYPE.PATIENT_CLASSIFICATION] = 'PatientClassification';
entity[TASK_TYPE.DISCHARGE_STATUS] = 'DischargeStatus';

export const authorization = {
  [USER_TYPE.ADMIN]: {
    Dashboard: [action.view],
    Facilities: [action.view],
    Patients: [action.delete, action.viewAll, action.edit, action.editView],
    Chat: [action.view, action.create, action.delete],
    'Patient Chat': [action.view],
    Tasks: [action.view],
    ContactTask: [action.view],
    VisitTask: [action.view],
    NotesTask: [action.view],
    MedicationTask: [action.view],
    InitialTcmEligibility: [],
    FacilityAssignment: [action.view, action.complete],
    form1823: [action.complete, action.edit, action.view],
    FacilityOnBoarding: [action.view, action.complete],
    PatientClassification: [action.complete],
    Admin: [action.view],
    AdminFacilities: [
      action.viewAll,
      action.create,
      action.edit,
      action.delete,
    ],
    Practices: [action.view, action.create, action.edit, action.delete],
    User: [action.view, action.create, action.edit, action.delete],
    'Audit Logs': [action.view],
    'New Requests': [action.view],
    'Cron Settings': [action.view, action.edit],
    FetchClinicalSummary: [action.view],
    CareTeamMembers: [action.delete, action.create],
    Medication: [],
    Diagnose: [],
    'Facility Stats': [action.viewAll],
    YourPatient: [action.viewAll],
    PatientType: [action.edit],
    ExportPatient: [action.view],
    ImportPatient: [action.view],
    DownloadReport: [action.view],
  },

  [USER_TYPE.PRACTICE_ADMIN]: {
    Dashboard: [action.view],
    Facilities: [action.view],
    Patients: [action.delete, action.viewAll, action.edit, action.editView],
    Chat: [action.view, action.create, action.delete],
    'Patient Chat': [action.view],
    Tasks: [action.view],
    ContactTask: [action.view, action.complete],
    VisitTask: [action.view, action.complete],
    NotesTask: [action.view, action.complete],
    MedicationTask: [action.view, action.complete],
    InitialTcmEligibility: [action.view, action.complete],
    FacilityAssignment: [action.view, action.complete],
    form1823: [action.complete, action.edit, action.view],
    Admin: [action.view],
    AdminFacilities: [],
    Practices: [action.view, action.edit, action.request],
    User: [action.view, action.create, action.edit, action.delete],
    'Audit Logs': [action.view],
    'New Requests': [action.view],
    FetchClinicalSummary: [action.view],
    CareTeamMembers: [action.delete, action.create],
    Medication: [action.create, action.delete, action.approve],
    Diagnose: [action.approve, action.delete],
    'Facility Stats': [action.view],
    YourPatient: [action.viewAll],
    Encounter: [action.create],
    TransitionalEvent: [action.create],
    FacilityOnBoarding: [],
    PatientClassification: [action.complete],
    DischargeStatus: [action.view, action.complete],
    PatientType: [action.edit],
    ExportPatient: [action.view],
  },

  [USER_TYPE.PRACTICE_USER]: {
    Dashboard: [action.view],
    Facilities: [action.view],
    Patients: [action.viewOwn],
    Chat: [action.view],
    'Patient Chat': [action.view],
    Tasks: [],
    ContactTask: [],
    VisitTask: [],
    NotesTask: [],
    MedicationTask: [],
    InitialTcmEligibility: [],
    FacilityAssignment: [],
    form1823: [],
    FacilityOnBoarding: [],
    PatientClassification: [action.complete],
    Admin: [action.view],
    AdminFacilities: [],
    Practices: [action.view],
    User: [action.view],
    'Audit Logs': [],
    CareTeamMembers: [],
    Medication: [action.create, action.delete, action.approve],
    Diagnose: [action.approve, action.delete],
    'Facility Stats': [action.viewOwn],
    YourPatient: [action.viewOwn],
    DischargeStatus: [action.view, action.complete],
  },
  [USER_TYPE.MEDICAL_PROVIDER]: {
    Dashboard: [action.view],
    Facilities: [action.view],
    Patients: [action.viewOwn],
    Chat: [action.view],
    'Patient Chat': [action.view],
    Tasks: [action.view],
    ContactTask: [action.view, action.complete],
    VisitTask: [action.view, action.complete],
    NotesTask: [action.view, action.complete],
    MedicationTask: [action.view, action.complete],
    InitialTcmEligibility: [],
    FacilityAssignment: [],
    FacilityOnBoarding: [],
    PatientClassification: [action.complete],
    form1823: [action.approve, action.complete, action.edit, action.view],
    Admin: [action.view],
    AdminFacilities: [],
    Practices: [action.view],
    User: [action.view],
    'Audit Logs': [],
    CareTeamMembers: [],
    Medication: [action.create, action.delete, action.approve],
    Diagnose: [action.approve, action.delete],
    'Facility Stats': [action.viewOwn],
    YourPatient: [action.viewOwn],
    TransitionalEvent: [action.create],
    DischargeStatus: [action.view, action.complete],
    PatientType: [action.edit],
  },

  [USER_TYPE.OFFICE_USER]: {
    Dashboard: [action.view],
    Facilities: [action.view],
    Patients: [action.delete, action.viewAll, action.editView],
    Chat: [action.view],
    'Patient Chat': [action.view],
    Tasks: [action.view, action.complete],
    ContactTask: [action.view, action.complete],
    VisitTask: [action.view, action.complete],
    NotesTask: [action.view, action.complete],
    MedicationTask: [action.view, action.complete],
    InitialTcmEligibility: [],
    FacilityAssignment: [action.view, action.complete],
    form1823: [action.view],
    Admin: [action.view],
    AdminFacilities: [],
    Practices: [action.view],
    User: [action.view],
    'Audit Logs': [],
    Medication: [action.create, action.delete, action.approve],
    Diagnose: [action.approve, action.delete],
    'Facility Stats': [action.view],
    YourPatient: [action.viewAll],
    CareTeamMembers: [],
    Encounter: [],
    FetchClinicalSummary: [],
    TransitionalEvent: [],
    FacilityOnBoarding: [],
    PatientClassification: [action.complete],
    DischargeStatus: [action.view, action.complete],
    PatientType: [action.edit],
  },

  [USER_TYPE.FACILITY_ADMIN]: {
    Dashboard: [action.view],
    Facilities: [action.view],
    Patients: [
      action.create,
      action.delete,
      action.viewAll,
      action.edit,
      action.editView,
    ],
    Chat: [action.view, action.create, action.delete],
    'Patient Chat': [action.view],
    Tasks: [action.view],
    ContactTask: [],
    VisitTask: [action.view, action.complete],
    NotesTask: [],
    MedicationTask: [action.view, action.complete],
    InitialTcmEligibility: [],
    FacilityAssignment: [],
    form1823: [
      action.create,
      action.complete,
      action.edit,
      action.view,
      action.request,
    ],
    PatientClassification: [action.complete],
    DischargeStatus: [action.view, action.complete],
    FacilityOnBoarding: [],

    Admin: [action.view],
    AdminFacilities: [action.view, action.edit, action.request],
    User: [action.view, action.create, action.edit, action.delete],
    'Audit Logs': [action.view],
    'New Requests': [action.view],

    CareTeamMembers: [action.delete],
    Medication: [],
    Diagnose: [],
    'Facility Stats': [action.viewOwn],
    YourPatient: [action.viewAll],
    PatientType: [action.edit],
    ExportPatient: [action.view],
  },

  [USER_TYPE.FACILITY_USER]: {
    Dashboard: [action.view],
    Facilities: [action.view],
    Patients: [action.viewOwn, action.create],
    Chat: [action.view],
    'Patient Chat': [action.view],
    Tasks: [action.view],

    ContactTask: [],
    VisitTask: [action.view, action.complete],
    NotesTask: [],
    MedicationTask: [action.view, action.complete],
    InitialTcmEligibility: [],
    FacilityAssignment: [],
    form1823: [],
    FacilityOnBoarding: [],
    DischargeStatus: [],
    PatientClassification: [],

    Admin: [action.view],
    AdminFacilities: [],
    User: [action.view],
    'Audit Logs': [],

    Medication: [],
    Diagnose: [],
    'Facility Stats': [action.viewOwn],
    YourPatient: [action.viewOwn],
  },
};
export const guestAuthorization = {
  [USER_TYPE.FACILITY_ADMIN]: {
    Chat: [action.view, action.create, action.delete],
    'Patient Chat': [action.view],
    Admin: [action.view],
    AdminFacilities: [action.view, action.edit, action.request],
    User: [action.view, action.create, action.edit, action.delete],
    'Audit Logs': [action.view],
    'New Requests': [action.view],
  },
  [USER_TYPE.FACILITY_USER]: {
    Chat: [action.view],
    Admin: [action.view],
    AdminFacilities: [],
    User: [action.view],
    'Audit Logs': [],
  },
};
