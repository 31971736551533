import {useLocation} from 'react-router-dom';
import {routeLink} from 'src/constant';
import {action, entity} from 'src/constant/authorization';
import {FACILITY_TYPE} from 'src/constant/facilityType';
import {status} from 'src/constant/status';
import {useAuth} from 'src/modules/auth/hooks/useAuth';
import {Patient_Location_Status} from 'src/modules/patient/constants/PatientConstants';
import checkAuthorization from 'src/services/accessService';

const useFacilityFilter = () => {
  const {user} = useAuth();
  const {pathname} = useLocation();
  const tab = pathname.split('/').pop();

  const baseState = (tabType, facility) => ({
    [`${tabType}Tab`]: true,
    showPatientType: facility?.type === FACILITY_TYPE.SkilledNursing,
  });

  const getFiltersAndApi = (api, filter, sort) => ({api, filter, sort});

  const generateFacilityPatientFilter = facility => {
    if (!facility) {
      return {};
    }
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    thirtyDaysAgo.setHours(0, 0, 0, 0); // Set to start of day

    const commonFilter = {
      status: status.active,
      user: user._id,
      patientResidence: facility?._id,
      patientStatus: status.active,
    };
    switch (tab) {
      case 'residents':
        return {
          ...baseState('residence', facility),
          ...getFiltersAndApi(
            checkAuthorization(user, entity.patient, action.viewAll)
              ? routeLink.getPatient
              : routeLink.careTeamPatients,
            checkAuthorization(user, entity.patient, action.viewAll)
              ? {
                  $or: [
                    {residence: facility?._id},
                    {currentLocation: facility?._id},
                  ],
                  status: status.active,
                }
              : commonFilter,
            checkAuthorization(user, entity.patient, action.viewAll)
              ? {LastName: 1, FirstName: 1}
              : {},
          ),
        };
      case 'admissionPlanning':
        return {
          ...baseState('admissionPlanning', facility),
          ...getFiltersAndApi(
            checkAuthorization(user, entity.patient, action.viewAll)
              ? routeLink.getPatient
              : routeLink.careTeamPatientsAdmissionPlanning,
            checkAuthorization(user, entity.patient, action.viewAll)
              ? {
                  residence: facility?._id,
                  status: status.active,
                  locationStatus: Patient_Location_Status.OUT,
                }
              : commonFilter,
            checkAuthorization(user, entity.patient, action.viewAll)
              ? {LastName: 1, FirstName: 1}
              : {},
          ),
        };
      case 'transitionalCare':
        return {
          ...baseState('transitionalCare', facility),
          ...getFiltersAndApi(
            checkAuthorization(user, entity.patient, action.viewAll)
              ? routeLink.getPatient
              : routeLink.careTeamPatientsTransitionalCare,
            checkAuthorization(user, entity.patient, action.viewAll)
              ? {
                  locationStatus: Patient_Location_Status.HOME,
                  lastEncounterDischargeDate: {$gte: thirtyDaysAgo},
                  residence: facility?._id,
                  status: status.active,
                }
              : commonFilter,
            checkAuthorization(user, entity.patient, action.viewAll)
              ? {lastEncounterDischargeDate: -1, LastName: 1, FirstName: 1}
              : {},
          ),
        };
      default:
        return {};
    }
  };

  return {generateFacilityPatientFilter};
};

export {useFacilityFilter};
