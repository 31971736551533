import React from 'react';
import {Form} from '../../../components/form';
import {TableHeader} from '../../../components/header/TableHeader';
import {routeLink} from '../../../constant';
import {useFormSubmit} from '../../../hooks';
import {useAuth} from 'src/modules/auth/hooks/useAuth';
import {USER_TYPE} from 'src/constant/userType';
import checkAuthorization from 'src/services/accessService';
import {action, entity} from 'src/constant/authorization';
import {getPatientsFullName} from 'src/utils/commonMethod';

const CreateChatGroupModal = props => {
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.createChatGroup}`,
    method: 'post',
    eventSourceId: ['chatgroups'],
  });
  const {user} = useAuth();
  const patientFilter = {status: 'Active'};

  if (user?.employerType?.name === 'Practice') {
    patientFilter['$or'] = [
      {primaryPractice: user?.practiceId?._id},
      {practices: user?.practiceId?._id},
    ];
    if (!checkAuthorization(user, entity.patient, action.viewAll)) {
      patientFilter['$or'] = [
        {residence: {$in: user?.facilityAssigned}},
        {currentLocation: {$in: user?.facilityAssigned}},
      ];
    }
  } else {
    patientFilter['$or'] = [
      {residence: user?.facilityId?._id},
      {currentLocation: user?.facilityId?._id},
    ];
  }

  return (
    <>
      <TableHeader title="Create New Chat Group" />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          users: [
            // if changes any field over here ensure also updates in users that we extracting from state
            {
              _id: user._id,
              PNChatMemberId: user.PNChatMemberId,
              name: user.name,
              email: user.email,
            },
          ],
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'autoComplete',
                field: 'groupType',
                label: 'Group Type',
                options: [
                  {label: 'Patient', value: 'patient'},
                  {label: 'Users', value: 'users'},
                ],
                keyField: 'value',
                suggestionField: 'label',
                size: 'medium',
                required: true,
              },
              // only those patients for which user have access
              {
                type: 'autoComplete',
                field: 'patientId',
                label: 'Patient',
                api: routeLink.getPatient,
                required: true,
                suggestionField: values => getPatientsFullName(values),
                getDisplayValue: ({value}) => {
                  return getPatientsFullName(value);
                },
                fields: {
                  LastName: 1,
                  FirstName: 1,
                  MiddleName: 1,
                  FullName: 1,
                  _id: 1,
                  primaryPractice: 1,
                  practices: 1,
                  residence: 1,
                  currentLocation: 1,
                },
                filter: () => JSON.stringify(patientFilter),
                size: 'large',
                visible: ({values}) => {
                  return (
                    user?.userType?.name !== USER_TYPE.FACILITY_USER &&
                    values?.groupType === 'patient'
                  );
                },
              },
              // only those patients for which user have access
              {
                type: 'autoComplete',
                field: 'patientId',
                label: 'Patient',
                api: routeLink.careTeamMembers,
                filter: () =>
                  JSON.stringify({status: 'Active', user: user?._id}),
                fields: JSON.stringify({
                  patientId: {
                    LastName: 1,
                    FirstName: 1,
                    MiddleName: 1,
                    FullName: 1,
                    _id: 1,
                    primaryPractice: 1,
                    practices: 1,
                    residence: 1,
                    currentLocation: 1,
                  },
                }),
                required: true,
                getDisplayValue: ({value}) => {
                  return getPatientsFullName(value.patientId);
                },
                suggestionField: values =>
                  getPatientsFullName(values.patientId),
                keyField: 'patientId._id',
                size: 'large',
                visible: ({values}) => {
                  return (
                    user?.userType?.name === USER_TYPE.FACILITY_USER &&
                    values?.groupType === 'patient'
                  );
                },
              },
              {
                type: 'text',
                field: 'groupName',
                label: 'Group Name',
                required: true,
              },
              // other users who
              {
                type: 'multiAutoComplete',
                field: 'users',
                label: 'Users',
                api: routeLink.getCareTeamUsers,
                required: true,
                suggestionField: 'name',
                size: 'large',
                fields: {user: {PNChatMemberId: 1, name: 1, email: 1}},
                filter: ({values}) => {
                  return JSON.stringify({
                    residence: values?.patientId?.residence,
                    currentLocation: values?.patientId?.currentLocation,
                    primaryPractice: values?.patientId?.primaryPractice,
                    practices: values?.patientId?.practices,
                    'user._id': {
                      $nin: [
                        ...(values?.users?.length > 0
                          ? values.users.map(user => user._id)
                          : []),
                      ],
                    },
                    patientId: values?.patientId?._id,
                  });
                },
                visible: ({values}) => values?.groupType === 'patient',
              },
              {
                type: 'multiAutoComplete',
                field: 'users',
                label: 'Users',
                api: routeLink.getUser,
                required: true,
                suggestionField: 'name',
                size: 'large',
                fields: {PNChatMemberId: 1, name: 1, email: 1},
                visible: ({values}) => values?.groupType === 'users',
              },
              {
                type: 'text',
                field: 'message',
                label: 'Message',
                required: true,
                size: 'medium',
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};

export default CreateChatGroupModal;
