import React, {useEffect} from 'react';
import {Col} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/header/TableHeader';
import TabNavigation from '../../../components/tabNavigation/TabNavigation';
import {useLocation, useNavigate} from 'react-router-dom';
import {LoaderAbsoluteContainer} from './FacilityStatsOverview.style';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {isFacilityEmployee} from 'src/utils/checkEmployerType';
import {useAuth} from 'src/modules/auth/hooks/useAuth';

export const FacilityStatsOverView = () => {
  const {user} = useAuth();
  const {state} = useLocation();
  const {facility: stateFacility} = state || {};
  const facility = isFacilityEmployee() ? user?.facilityId : stateFacility;

  const navigate = useNavigate();

  useEffect(() => {
    if (!facility) {
      navigate('/dashboard');
    }
  }, [facility]);

  const tabs = [
    {
      name: 'Residents',
      route: 'residents',
      state: {
        facility: facility,
      },
    },
    {
      name: 'Admission Planning',
      route: `admissionPlanning`,
      state: {
        facility: facility,
      },
    },
    {
      name: 'Transitional Care',
      route: 'transitionalCare',
      state: {
        facility: facility,
      },
    },
    {
      name: 'Care Teams',
      route: 'careProviders',
      state: {
        facility: facility,
      },
    },
    // {
    //   name: 'Chat',
    //   route: 'facility/chat',
    //   state: {
    //     facility: facility,
    //     fromFacilityStatsOverview: true,
    //   },
    // },
  ];
  if (!facility) {
    return (
      <LoaderAbsoluteContainer>
        <ActivityIndicator />
      </LoaderAbsoluteContainer>
    );
  }
  return (
    <Col
      gap={8}
      style={{
        flex: 1,
      }}>
      {!isFacilityEmployee() && <TableHeader title={facility?.name} />}
      <TabNavigation
        tabs={tabs}
        styles={{
          TabContainer: {
            borderRadius: '10px',
          },
          TabContent: {
            border: 'none',
            padding: '0px',
            paddingTop: '10px',
          },
        }}
      />
    </Col>
  );
};
