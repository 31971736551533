import React from 'react';
import moment from 'moment';
import {
  MessageMainContainer,
  MessageName,
  MessageText,
  MessageTime,
} from '../styles/Message.style';
import {isMe, isToday} from '../utils/ChatHelperFunctions';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import Config from '../../../Config';

const Message = ({text, sender, senderId, date}) => {
  const {user} = useAuth();
  const isItMe = isMe(senderId, user.PNChatMemberId);
  return (
    <MessageMainContainer isItMe={isItMe}>
      {!isItMe && <MessageName>{sender}</MessageName>}
      <MessageText>{text}</MessageText>
      <MessageTime>
        {isToday(date) ? moment(date).local().format(Config.momentTimeFormat) : moment(date).utc().calendar()}
      </MessageTime>
    </MessageMainContainer>
  );
};

export default Message;
