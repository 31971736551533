import React from 'react';
import images from 'src/assets/images';
import {ChatMainScreenContainer, Image} from '../styles/Chat.style';

const NoGroupIsSelected = () => {
  return (
    <ChatMainScreenContainer>
      <Image src={images.noDataAvailable} />
    </ChatMainScreenContainer>
  );
};

export default NoGroupIsSelected;
