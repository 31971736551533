import Config from '../../Config';
import Pubnub from 'pubnub';

export const onMFASuccess = async ({
  user,
  navigate,
  setAuth,
  toast,
  message,
  toastType,
}) => {
  const pubnub = new Pubnub({
    publishKey: Config.PUB_NUB_PUBLISH_KEY,
    subscribeKey: Config.PUB_NUB_SUBSCRIBE_KEY,
    userId: user.PNChatMemberId,
  });
  pubnub.setToken(user.chatAuthKey);
  user.chatInstance = pubnub;
  setAuth({user: user});
  toast({
    message,
    type: toastType,
  });
  navigate('/');
};
