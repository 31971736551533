import React, {useEffect, useState} from 'react';
import {CNavItem, CSidebar, CSidebarNav} from '@coreui/react';
import {useTheme} from '@unthinkable/react-theme';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';

import {
  BrandContainer,
  BrandIcon,
  GroupItemContainer,
  GroupItemIcon,
  GroupItemText,
  SidebarNavContainer,
} from './AppSidebar.style';
import {AppSidebarNav} from './AppSidebarNav';
import {useAuth} from '../modules/auth/hooks/useAuth';
import {action, entity} from '../constant/authorization';
import checkAuthorization from '../services/accessService';
import {routeLink} from '../constant';
import {status} from '../constant/status';
import {HttpAuthService} from '../services';
import {isFacilityAdmin, isFacilityUser} from '../utils/checkUserType';
import {
  isFacilityEmployee,
  isPracticeEmployee,
} from '../utils/checkEmployerType';
import {useMediaQuery} from '../modules/auth/hooks/useMediaquery';
import {TaskUserHaveAccess} from '../modules/tasks/TaskFilter';

const GroupItem = item => {
  const {children, name, showName, icon, isMenuSelected} = item;
  const [isParentHovered, setParentParentHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setParentParentHovered(true);
      }}
      onMouseLeave={() => {
        setParentParentHovered(false);
      }}>
      <GroupItemContainer title={name}>
        {icon && <GroupItemIcon alt={name} src={icon} />}
        <GroupItemText>{showName && name && name}</GroupItemText>
      </GroupItemContainer>
      <div hidden={!isParentHovered && !isMenuSelected}>{children}</div>
    </div>
  );
};

const AppSidebar = ({sidebarShow, setSidebarShow}) => {
  const {user} = useAuth();
  const IS_PRACTICE_EMPLOYEE = isPracticeEmployee();
  const IS_FACILITY_ADMIN = isFacilityAdmin();
  const IS_FACILITY_USER = isFacilityUser();
  const [taskCount, setTaskCount] = useState(0);
  // const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const {
    Buildings,
    BuildingsSelected,
    Patients,
    PatientsSelected,
    Admin,
    AdminSelected,
    chatSmile,
    chatSmileSelected,
    Tasks,
    TasksSelected,
    FullBrandLogo,
    EliteCareTechnology,
    setting,
    settingsDark,
    logs,
    logsSelected,
    practices,
    practicesSelected,
    spaceDashboard,
    spaceDashboardSelected,
    newRequest,
    newRequestSelected,
  } = useTheme('icons');
  const items = [
    {
      component: CNavItem,
      name: 'Facilities',
      to: '/facilities-company',
      showName: sidebarShow,
      icon: Buildings,
      selectedIcon: BuildingsSelected,
    },
    {
      component: CNavItem,
      name: 'Practices',
      to: '/practices',
      showName: sidebarShow,
      icon: practices,
      selectedIcon: practicesSelected,
    },
    {
      component: CNavItem,
      name: 'User',
      to: '/users',
      showName: sidebarShow,
      icon: Patients,
      selectedIcon: PatientsSelected,
    },
    {
      component: CNavItem,
      name: 'Audit Logs',
      to: '/audit-logs',
      showName: sidebarShow,
      icon: logs,
      selectedIcon: logsSelected,
    },
    {
      component: CNavItem,
      name: 'New Requests',
      to: '/new-requests',
      showName: sidebarShow,
      icon: newRequest,
      selectedIcon: newRequestSelected,
    },
    {
      component: CNavItem,
      name: 'Cron Settings',
      to: '/cron-setting',
      showName: sidebarShow,
      icon: setting,
      selectedIcon: settingsDark,
    },
  ];

  // for render menu on basis of permission
  const itemsToShow = items.filter(item => {
    let NAME = item.name;
    if (NAME === 'Facilities') {
      NAME = 'AdminFacilities';
    }
    return (
      checkAuthorization(user, NAME, action.view) ||
      checkAuthorization(user, NAME, action.viewAll) ||
      checkAuthorization(user, NAME, action.viewOwn)
    );
  });

  const _nav = [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: spaceDashboard,
      selectedIcon: spaceDashboardSelected,
      showName: sidebarShow,
    },
    {
      component: CNavItem,
      name: 'Facilities',
      to: '/facility-stats',
      icon: Buildings,
      selectedIcon: BuildingsSelected,
      showName: sidebarShow,
    },
    {
      component: CNavItem,
      name: 'Patients',
      to: isFacilityEmployee()
        ? `/facility-stats/overview/${user?.facilityId?._id}/patient/residents`
        : '/patient',
      icon: Patients,
      selectedIcon: PatientsSelected,
      showName: sidebarShow,
    },
    {
      component: CNavItem,
      name: 'Chat',
      to: '/chat/userGroups',
      icon: chatSmile,
      selectedIcon: chatSmileSelected,
      // notificationCount: unreadMessageCount,
      showName: sidebarShow,
    },
    {
      component: CNavItem,
      name: 'Tasks',
      to: '/tasks',
      icon: Tasks,
      selectedIcon: TasksSelected,
      notificationCount: sidebarShow
        ? taskCount > 999
          ? '999+'
          : taskCount
        : 0,
      showName: sidebarShow,
    },
    {
      component: GroupItem,
      name: 'Admin',
      to: '/admin/',
      icon: Admin,
      selectedIcon: AdminSelected,
      items: itemsToShow,
      showName: sidebarShow,
    },
  ];

  const _navToShow = _nav.filter(item => {
    if (
      (!user?.chatAuthKey &&
        (item.name === 'Chat' || item.name === 'Patient Chat')) ||
      (item.name === 'Facilities' && isFacilityEmployee())
    ) {
      return false;
    }
    return (
      checkAuthorization(user, item.name, action.viewAll) ||
      checkAuthorization(user, item.name, action.viewOwn) ||
      checkAuthorization(user, item.name, action.view)
    );
  });
  const fetchIncompleteTaskCount = async () => {
    try {
      let filter = {
        status: {$nin: [status.complete, status.inactive]},
        'patientId.status': status.active,
      };
      if (IS_PRACTICE_EMPLOYEE) {
        filter = {
          ...filter,
          'patientId.primaryPractice': user?.practiceId?._id,
        };
      }
      if (
        IS_PRACTICE_EMPLOYEE &&
        checkAuthorization(user, entity.patient, action.viewOwn)
      ) {
        filter = {
          ...filter,
          residence: {$in: user?.facilityAssigned},
        };
      }

      if (IS_FACILITY_ADMIN) {
        filter = {
          ...filter,
          $or: [
            {'patientId.currentLocation': user?.facilityId},
            {'patientId.residence': user?.facilityId},
          ],
        };
      } else if (IS_FACILITY_USER) {
        filter = {
          ...filter,
          owners: user?._id,
        };
      }

      const taskTypes = TaskUserHaveAccess({user});

      filter = {
        ...filter,
        type: {$in: taskTypes},
      };

      if (!IS_FACILITY_ADMIN) {
        filter['status'] = {
          $nin: [status.complete, status.approved, status.inactive],
        };
      }

      const response = await HttpAuthService.get(routeLink.getTCMTask, {
        params: {
          filter: JSON.stringify(filter),
        },
      });
      setTaskCount(response.data.length);
    } catch (err) {
      console.log(err);
    }
  };
  // useDataFetchEvents(
  //   {
  //     eventSourceId: ['unreadMessageCountUpdate', 'unreadMessageCount'],
  //   },
  //   () => {
  //     fetchUnreadChatMessages();
  //   },
  // );
  // const fetchUnreadChatMessages = async () => {
  //   try {
  //     const response = await HttpAuthService.get(
  //       routeLink.getUnreadMessageCount,
  //     );
  //     setUnreadMessageCount(response.data);
  //   } catch (err) {
  //     console.log('err', err);
  //   }
  // };

  useEffect(() => {
    fetchIncompleteTaskCount();
    // fetchUnreadChatMessages();
  }, []);

  useDataFetchEvents(
    {
      eventSourceId: [
        // Object.keys(TASK_TYPE),
        'visitAttempt',
        'tcm-notes-status',
        'contactAttempts',
        'form1823Task',
        'addMedication',
        'initialTcmEligibility',
        'facilityAssignment',
        'patientClassification',
      ],
    },
    () => {
      fetchIncompleteTaskCount();
    },
  );
  const {isMobile} = useMediaQuery();
  return isMobile ? (
    <CSidebar
      style={{
        paddingBottom: '0',
      }}
      visible={sidebarShow}
      onVisibleChange={visible => setSidebarShow(visible)}
      position="sticky">
      <BrandContainer>
        <BrandIcon isOpen={sidebarShow} src={FullBrandLogo} />
      </BrandContainer>
      <CSidebarNav>
        <SidebarNavContainer>
          <AppSidebarNav items={_navToShow} setSidebarShow={setSidebarShow} />
        </SidebarNavContainer>
      </CSidebarNav>
    </CSidebar>
  ) : (
    <CSidebar
      style={{
        paddingBottom: '0',
        zIndex: 1,
      }}
      narrow={sidebarShow ? false : true}>
      <BrandContainer>
        <BrandIcon
          alt="Elite Care Technology"
          isOpen={sidebarShow}
          src={sidebarShow ? FullBrandLogo : EliteCareTechnology}
        />
      </BrandContainer>
      <CSidebarNav>
        <SidebarNavContainer>
          <AppSidebarNav items={_navToShow} setSidebarShow={setSidebarShow} />
        </SidebarNavContainer>
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
