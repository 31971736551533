import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {TextRender} from '@unthinkable/react-table';
import {useAuth} from '../../auth/hooks/useAuth';
import {TaskWidget} from '../../patient/components/TaskWidget';
import {useMediaQuery} from '../../auth/hooks/useMediaquery';
import {Table} from '../../../components/table';
import {Button} from '../../../components/button';
import {TableHeader} from '../../../components/header/TableHeader';
import {TaskCardFooter} from '../../../components/card/taskCard.style';
import {renderPatientCard} from '../../../components/card/patientCard';
import buttonType from '../../../constant/buttonType';
import {patientFields} from '../../../constant/patient.fields';
import {action, entity} from '../../../constant/authorization';
import checkAuthorization from '../../../services/accessService';
import {getPatientsFullName} from '../../../utils/commonMethod';
import {
  isFacilityEmployee,
  isPracticeEmployee,
} from '../../../utils/checkEmployerType';
import images from '../../../assets/images';
import {locationStatus} from 'src/modules/patient/service/patient.service';
import {
  renderArrivalDate,
  renderCurrentLocation,
  renderDischargeDate,
  renderDOB,
  renderPatientType,
  renderTcmDueDate,
} from '../facility.utils';
import createForm1823 from '../hooks/Form1823.hook';
import useChangePatientType from '../hooks/useChangePatientType';
import {FACILITY_TYPE} from 'src/constant/facilityType';
import {Icon} from 'src/components/icon';
import {routeLink} from 'src/constant';
import {useFacilityFilter} from '../hooks/useFacilityFilter.hook';
import {LoaderAbsoluteContainer} from './FacilityStatsOverview.style';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';

//  also update FacilityCareTeamPatientTable.js
export const FacilityPatientTable = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state, pathname} = useLocation();
  const reduxKey = pathname.split('/').pop();
  const {facility: stateFacility} = state || {};

  const facility = isFacilityEmployee() ? user.facilityId : stateFacility;
  const {renderForm1823Button} = createForm1823();
  const {RenderPatientTypeChangeAction} = useChangePatientType();
  const {generateFacilityPatientFilter} = useFacilityFilter();

  const {
    filter: stateFilter = {},
    field,
    sort,
    api,
    showPatientType,
    residenceTab = false,
    admissionPlanningTab = false,
    transitionalCareTab = false,
  } = generateFacilityPatientFilter(facility);

  useEffect(() => {
    if (!api) {
      navigate('/dashboard');
    }
  }, []);
  const {isMobile} = useMediaQuery();
  const filter = {...stateFilter};

  if (isPracticeEmployee()) {
    filter['$and'] = [
      ...(filter['$or'] ? [{$or: [...filter['$or']]}] : []),
      {
        $or: [
          {primaryPractice: user?.practiceId?._id},
          {practices: user?.practiceId?._id},
        ],
      },
    ];
    if (filter['$or']) delete filter['$or'];
  }
  const FooterComponent = ({row}) => {
    return (
      <TaskCardFooter>
        <TaskWidget
          patient={row}
          encounter={row?.latestEncounter}
          skipMessage
        />
      </TaskCardFooter>
    );
  };
  let columns = [];
  if (isMobile) {
    columns = [
      {
        render: ({row}) => {
          return renderPatientCard({row, FooterComponent});
        },
      },
    ];
  } else {
    columns = [
      {
        header: 'Name',
        type: 'text',
        render: ({row}) => {
          const {FirstName, LastName, MiddleName} = row;
          return getPatientsFullName({FirstName, LastName, MiddleName});
        },
        width: 200,
      },
      {
        header: 'DOB',
        type: 'text',
        render: ({row}) => renderDOB(row),
        visible: residenceTab,
      },
      {
        header: 'Location Status',
        render: ({row}) => locationStatus(row),
        visible: residenceTab,
      },
      {
        header: 'Current Location',
        render: ({row}) => renderCurrentLocation(row),
        visible: admissionPlanningTab,
        width: 300,
      },
      {
        header: 'Admission Date',
        render: ({row}) => renderArrivalDate(row),
        visible: admissionPlanningTab,
        width: 150,
      },
      {
        header: 'Hospitalization Reason',
        render: ({row}) => {
          return <TextRender value={row?.latestEncounter?.diagnoseReason} />;
        },
        visible: admissionPlanningTab || transitionalCareTab,
        width: 200,
      },
      {
        header: 'Discharge Date',
        render: ({row}) => renderDischargeDate(row),
        width: 200,
        visible: transitionalCareTab,
      },
      {
        header: 'New Medications',
        render: () => {
          return '';
        },
        width: 200,
        visible: transitionalCareTab,
      },
      {
        header: 'Readmission Risk',
        render: () => {
          return '';
        },
        visible: transitionalCareTab,
        width: 200,
      },
      {
        header: 'TCM Due Date',
        render: ({row}) => renderTcmDueDate(row),
        width: 150,
        visible: () => transitionalCareTab && isPracticeEmployee(),
      },
      {
        header: 'Type',
        render: ({row}) => renderPatientType(row),
        visible: () => showPatientType,
        width: 150,
      },
      // {
      //   render: ({row}) => renderTaskWidget(row, user),
      //   // width: 600,
      //   visible: () => user?.practiceId && !admissionPlanningTab,
      // },
      {
        render: ({row, index}) => {
          return renderForm1823Button(row, index);
        },
        visible: () =>
          transitionalCareTab &&
          checkAuthorization(user, entity.Form1823, action.request),
        width: 200,
      },
      {
        render: ({row}) => {
          return (
            row?.residence?.type === FACILITY_TYPE.SkilledNursing &&
            RenderPatientTypeChangeAction(row)
          );
        },
        visible: () =>
          showPatientType &&
          checkAuthorization(user, entity.patientType, action.edit),
        width: 50,
      },
      {
        render: () => {
          return <Icon src={images.chatSmileGreen} />;
        },
        width: 50,
        visible: residenceTab,
      },
    ];
  }
  if (!facility) {
    return (
      <LoaderAbsoluteContainer>
        <ActivityIndicator />
      </LoaderAbsoluteContainer>
    );
  }

  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            actions={
              (admissionPlanningTab || residenceTab) &&
              checkAuthorization(user, entity.patient, action.create)
                ? [
                    <Button
                      key="Add New Patient"
                      text="Add New Patient"
                      onPress={() => {
                        navigate('/facility-stats/overview/add-patient', {
                          state,
                        });
                      }}
                      icon={images.addIcon}
                      iconPosition="right"
                      buttonType={buttonType.linkButtonWithBorder}
                    />,
                  ]
                : []
            }
          />
        );
      }}
      flex={false}
      reduxKey={reduxKey}
      api={api || routeLink.getPatient}
      filter={JSON.stringify(filter)}
      onRowPress={({row}) => {
        navigate(`/patient/details/${row?._id}/tcmEncounter`, {
          state: {
            patient: row,
          },
        });
      }}
      eventSourceId={['form1823Task', 'patientClassification']}
      style={{
        border: isMobile,
        rowBorder: isMobile,
        columnPadding: isMobile,
      }}
      fields={field || patientFields}
      sort={sort || {}}
      columns={columns}
    />
  );
};
