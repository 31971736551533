import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {View, Text, Row} from '@unthinkable/react-core-components';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {useToast} from '@unthinkable/react-toast';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {Confirm} from '@unthinkable/react-confirm';
import images from '../../../assets/images';
import {Avatar} from '../../../components/AppHeader.style';
import Config from '../../../Config';
import {routeLink} from '../../../constant';
import {TASK_TYPE} from '../../../constant/taskType';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import {HttpAuthService} from '../../../services';
import {Patient_Location_Status} from '../constants/PatientConstants';
import {patientTableStyles} from './style';
import {getPatientsFullName} from '../../../utils/commonMethod';
import {encounterFields} from '../../../constant/encounter.fields';
import {taskFields} from '../../../constant/task.fields';
import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {action, entity} from '../../../constant/authorization';
import {TaskUserHaveAccess} from '../../../modules/tasks/TaskFilter';
import {Button} from '../../../components/button';
import buttonType from '../../../constant/buttonType';
import modalMessages from '../../../constant/ModalMessage';
import ActiveTasks from '../components/ActiveTasks';
import {
  ButtonContainer,
  PatientInfoContainer,
  patientInfoStyle,
} from './style/patientInfo.style';
import {Icon} from '../../../components/icon';
import LocationType from 'src/constant/locationType';

const locationStatus = patient => {
  if (patient?.locationStatus !== Patient_Location_Status.NONE) {
    const statusText = patient?.locationStatus;
    return (
      <div style={patientTableStyles.StatusContainer}>
        <div
          style={
            statusText == Patient_Location_Status.HOME
              ? patientTableStyles.GreenDot
              : patientTableStyles.RedDot
          }></div>
        <div style={patientTableStyles.textContainer}>{statusText}</div>
      </div>
    );
  }
  return '-';
};
export const PatientInfo = ({patient}) => {
  const navigate = useNavigate();
  const [encounter, setEncounter] = useState(patient?.latestEncounter);
  const [activeTasks, setActiveTasks] = useState(null);
  const {user} = useAuth();
  const taskUserHaveAccess = TaskUserHaveAccess({user});
  const toast = useToast();
  const {notify} = useEventHandler();

  const getActiveTasks = async () => {
    try {
      if (!patient?._id) return;
      const activeTaskFilter = {
        status: {$nin: [STATUS.COMPLETE, STATUS.INACTIVE]},
        type: {$in: taskUserHaveAccess},
      };
      if (patient?.latestEncounter?._id) {
        activeTaskFilter['$or'] = [
          {encounterId: patient?.latestEncounter?._id},
          {patientId: patient?._id},
        ];
      } else {
        activeTaskFilter['patientId'] = patient?._id;
      }
      const response = await HttpAuthService.get(routeLink.getTCMTask, {
        params: {
          filter: JSON.stringify(activeTaskFilter),
          fields: taskFields,
        },
      });
      if (response?.data) {
        setActiveTasks(response?.data);
      }
      if (patient?.latestEncounter?._id) {
        const response = await HttpAuthService.get(routeLink.getTCMEncounter, {
          params: {
            filter: JSON.stringify({
              _id: patient?.latestEncounter?._id,
            }),
            fields: encounterFields,
            only: true,
          },
        });
        setEncounter(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getActiveTasks();
  }, [patient]);

  useDataFetchEvents(
    {
      eventSourceId: [
        'transitional-event',
        'addMedication',
        'tcm-notes-status',
        'visitAttempt',
        'contactAttempts',
        'form1823Task',
        'facilityAssignment',
        'initialTcmEligibility',
        'tcm-encounters',
        'transitional-event',
        'requestForm1823',
      ],
    },
    () => {
      getActiveTasks();
    },
  );

  const requestForm1823Handler = async patient => {
    try {
      const response = await HttpAuthService.post(
        `${routeLink.CreateForm1823Task}`,
        {
          patientId: patient?._id,
          encounterId: patient?.latestEncounter?._id,
        },
      );
      if (response?.success) {
        console.log('notifying');
        notify &&
          notify('dataUpdated', {
            id: 'requestForm1823',
          });
      }
      toast({
        message: response.message,
        type: response.success ? 'Success' : 'Error',
      });
    } catch (err) {
      toast({
        message: err.message,
        type: 'Error',
      });
    }
  };
  const form1823 = encounter?.taskInfo?.filter(
    task => task.type === TASK_TYPE.Form1823,
  );
  const showRequestForm1823 =
    !(form1823?.length > 0) &&
    patient?.latestEncounter?.dischargeDate &&
    moment().diff(moment(patient.latestEncounter.dischargeDate), 'days') <=
      15 &&
    checkAuthorization(user, entity[TASK_TYPE.Form1823], action.request);
  return (
    <PatientInfoContainer>
      {/* patient details */}
      <View style={patientInfoStyle.patientInfoContainer}>
        <Row style={patientInfoStyle.patientInfoHeader}>
          <Avatar styles={patientInfoStyle.Avatar} src={images.user}></Avatar>
          {checkAuthorization(user, entity.patient, action.editView) && (
            <View
              style={patientInfoStyle.IconWrapper}
              onClick={() => {
                navigate(`/patient/details/${patient?._id}/edit`, {});
              }}>
              <Icon src={images.lightEdit}></Icon>
            </View>
          )}
        </Row>
        <Row style={patientInfoStyle.patientNameContainer}>
          <Text style={patientInfoStyle.heading1}>
            {getPatientsFullName({...patient})}
          </Text>
          {patient?.locationStatus &&
            patient?.locationStatus !== Patient_Location_Status.NONE && (
              <View>{locationStatus(patient)}</View>
            )}
        </Row>
        <Row>
          <Text style={patientInfoStyle.heading2}>
            {patient.Sex ? `${patient.Sex}|` : ''}
            {moment(patient?.DOB).utc().format(Config.DOBFormat)}
          </Text>
        </Row>
        {patient?.residence?.name && (
          <Row style={patientInfoStyle.locationContainer}>
            <Icon src={images.homeOutline} />
            <Text>{patient.residence.name}</Text>
          </Row>
        )}
        {patient?.residenceType?.value === LocationType.locationType.Home && (
          <Row style={patientInfoStyle.locationContainer}>
            <Icon src={images.homeOutline} />
            <Text>
              {patient?.Address?.StreetAddress
                ? patient?.Address?.StreetAddress + ', '
                : ''}
              {patient?.Address?.City ? patient?.Address?.City + ', ' : ''}
              {patient?.Address?.State ? patient?.Address?.State : ''}
            </Text>
          </Row>
        )}
        {patient?.currentLocation?.name && (
          <Row style={patientInfoStyle.locationContainer}>
            <Icon src={images.location} />
            <Text>{patient.currentLocation.name}</Text>
          </Row>
        )}
      </View>
      {/* Encounter details  */}
      {checkPrimaryPractice(user, patient) &&
        activeTasks &&
        activeTasks?.length > 0 && (
          <ActiveTasks
            activeTasks={activeTasks}
            encounter={encounter}
            patient={patient}
          />
        )}
      <ButtonContainer>
        {showRequestForm1823 && (
          <Confirm
            title={modalMessages.requestForm1823.title}
            message={modalMessages.requestForm1823.message}
            confirmText={modalMessages.requestForm1823.confirmText}
            onConfirm={() => {
              requestForm1823Handler(patient);
            }}>
            <Button
              key="Request Form 1823"
              text="Request Form 1823"
              buttonType={buttonType.linkButtonWithBorder}
            />
          </Confirm>
        )}
      </ButtonContainer>
    </PatientInfoContainer>
  );
};
