export const patientFields = {
  FirstName: 1,
  MiddleName: 1,
  LastName: 1,
  DOB: 1,
  Address: {
    StreetAddress: 1,
    City: 1,
    State: 1,
    ZIP: 1,
  },
  PhoneNumber: {
    Mobile: 1,
  },
  groupId: {
    _id: 1,
    name: 1,
    PNGroupId: 1,
    practiceId: 1,
    facilityId: 1,
  },
  residenceType: {
    value: 1,
  },
  currentLocationType: {
    value: 1,
  },
  residence: {
    name: 1,
    type: 1,
  },
  currentLocation: {
    name: 1,
  },
  locationStatus: 1,
  primaryPractice: {
    _id: 1,
    name: 1,
  },
  latestEncounter: {
    status: 1,
    dischargeDate: 1,
    taskInfo: {
      _id: 1,
      taskId: 1,
      status: 1,
      successStatus: 1,
      type: 1,
      tcmDueDate: 1,
      nextTcmDueDate: 1,
    },
    latestArrivalEvent: {
      _id: 1,
      eventDate: 1,
    },
    onGoingTcmDueDate: 1,
    isDateBounded: 1,
    ContactTaskDuration: 1,
    MedicationTaskDuration: 1,
    VisitTaskDuration: 1,
    tcmNotes: 1,
    tcmTaskPerformedBy: 1,
    diagnoseReason: 1,
  },
  lastEncounterDate: 1,
  outPatientClinicalSummary: 1,
  practices: 1,
  status: 1,
  FullName: 1,
  type: 1,
};
