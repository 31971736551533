import theme from '../../../theme/Theme';
import styled from 'styled-components';

export const patientTableStyles = {
  StatusContainer: {
    display: 'flex',
    borderRadius: 6,
    width: 75,
    height: 24,
    padding: '2px 8px',
    border: `1px solid ${theme.colors.SECONDARY_UPPER_LOW}`,
    boxShadow: theme.shadow.shadow6,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  GreenDot: {
    height: 8,
    width: 8,
    backgroundColor: theme.colors.GREEN_STATUS_COLOR,
    borderRadius: '50%',
  },

  RedDot: {
    height: 8,
    width: 8,
    backgroundColor: theme.colors.RED_STATUS_COLOR,
    borderRadius: '50%',
  },

  textContainer: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 18,
    color: theme.colors.SIDEBAR_BACKGROUND_HIGH,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

export const ColumnTextRenderSuccess = styled.div`
  border-radius: 16px;
  border: 1px solid ${theme.colors.GREEN_TCM_BORDER_COLOR};
  padding: 2px 8px;
  width: fit-content;
  color: ${theme.colors.GREEN_TCM_FORE_COLOR};
  background-color: ${theme.colors.GREEN_TCM_BACKGROUND_COLOR};
  font-size: 12px;
  line-height: 18px;
`;

export const ColumnTextRender = styled.div`
  border-radius: 16px;
  border: 1px solid
    ${props => {
      if (props.type === 'Gray') {
        return theme.colors.GRAY_TCM_BORDER_COLOR;
      } else if (props.type === 'Yellow') {
        return theme.colors.YELLOW_TCM_BORDER_COLOR;
      } else {
        return theme.colors.RED_TCM_BORDER_COLOR;
      }
    }};

  padding: 2px 8px;
  width: fit-content;

  color: ${props => {
    if (props.type === 'Gray') {
      return theme.colors.GRAY_TCM_FORE_COLOR;
    } else if (props.type === 'Yellow') {
      return theme.colors.YELLOW_TCM_FORE_COLOR;
    } else {
      return theme.colors.RED_TCM_FORE_COLOR;
    }
  }};

  background-color: ${props => {
    if (props.type === 'Gray') {
      return theme.colors.GRAY_TCM_BACKGROUND_COLOR;
    } else if (props.type === 'Yellow') {
      return theme.colors.YELLOW_TCM_BACKGROUND_COLOR;
    } else {
      return theme.colors.RED_TCM_BACKGROUND_COLOR;
    }
  }};
  font-size: 12px;
  line-height: 18px;
`;

export const ColumnTextRenderDanger = styled.div`
  border-radius: 16px;
  border: 1px solid ${theme.colors.RED_TCM_BORDER_COLOR};
  padding: 2px 8px;
  width: fit-content;
  color: ${theme.colors.RED_TCM_FORE_COLOR};
  background-color: ${theme.colors.RED_TCM_BACKGROUND_COLOR};
  font-size: 12px;
  line-height: 18px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
`;
export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-end;
`;
export const TaskTablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  overflow-x: scroll;
  flex: 1;
`;

export const TakTableStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 400px;
`;
