import React, {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Form} from '../../../components/form';
import {TableHeader} from '../../../components/header/TableHeader';
import {routeLink} from '../../../constant';
import {status} from '../../../constant/status';
import {useFormSubmit} from '../../../hooks';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {validateDate} from '../../../utils/validations/commonValidations';
import {isMedicalProvider} from '../../../utils/checkUserType';
import {USER_TYPE_ID} from '../../../constant/userType';
import {DateTypeCaste, getPatientsFullName} from '../../../utils/commonMethod';

const CompleteMedicationModal = props => {
  const {taskId, patientId} = useParams();
  const navigate = useNavigate();
  const {user} = useAuth();
  const {state} = useLocation();
  const {encounter, patient} = state || {};
  const {onSubmit} = useFormSubmit({
    uri: routeLink.CompleteMedicationTask,
    eventSourceId: ['MedicationTask', 'addMedication', 'tcmtasks'],
  });
  const defaultValues = {
    dateTime: DateTypeCaste(new Date()),
  };
  if (isMedicalProvider()) {
    defaultValues['updatedBy'] = {
      _id: user?._id,
      name: user?.name,
      email: user?.email,
      facilityId: user?.facilityId,
      practiceId: user?.practiceId,
    };
  }
  useEffect(() => {
    if (!encounter?.dischargeDate) {
      navigate('/patient');
    }
  }, []);
  return (
    <>
      <TableHeader
        title={`Complete Medication Task - ${getPatientsFullName(patient)}`}
      />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        updateDefaultValues={defaultValues}
        mode="edit"
        beforeSubmit={data => ({
          data: {
            ...data.data,
            status: 'Complete',
          },
        })}
        api={routeLink.CompleteMedicationTask + `/${taskId}`}
        fields={{
          updatedBy: {
            user: {
              name: 1,
              email: 1,
            },
          },
          dateTime: 1,
          status: 1,
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'autoComplete',
                field: 'updatedBy',
                label: 'User',
                keyField: 'user',
                api: '/v1/CareTeamMembers',
                suggestionField: 'user.name',
                secondarySuggestionField: 'user.email',
                size: 'medium',
                getDisplayValue: props => {
                  return props?.value?.name;
                },
                fields: {
                  user: {
                    name: 1,
                    email: 1,
                    practiceId: 1,
                    facilityId: 1,
                    userType: {
                      name: 1,
                    },
                  },
                },
                filter: JSON.stringify({
                  patient: patientId || patient?._id,
                  status: status.active,
                  'user.userType': USER_TYPE_ID.MEDICAL_PROVIDER,
                }),
                required: true,
              },
              {
                type: 'date',
                label: 'Date',
                field: 'dateTime',
                required: true,
                size: 'medium',
                validate: date => validateDate(date, encounter?.dischargeDate),
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};

export default CompleteMedicationModal;
