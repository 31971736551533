import React, {Suspense} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {CSpinner} from '@coreui/react';
import {children} from '../routes/AppNavigator';
import {useAuth} from '../modules/auth/hooks/useAuth';
import checkAuthorization from '../services/accessService';
import {action, entity} from '../constant/authorization';
import {AppContentStyle} from '../Layout/DefaultLayout.style';

const resolveRoute = (route, idx) => {
  return (
    route.element && (
      <Route
        key={idx}
        path={route.path}
        exact={route.exact}
        name={route.name}
        element={<route.element />}>
        {route.children.length &&
          route.children.map((route, idx) => {
            return resolveRoute(route, idx);
          })}
      </Route>
    )
  );
};

const filterVisibleRoutes = (route, user) => {
  let {visible = true} = route;
  if (typeof visible === 'function') {
    visible = visible(user);
  }
  return visible;
};

const AppContent = () => {
  const {user} = useAuth();
  let visibleRoutes = children.filter(route =>
    filterVisibleRoutes(route, user),
  );

  // Then, recursively filter children if they exist
  visibleRoutes = visibleRoutes.map(route => ({
    ...route,
    children: route.children
      ? route.children.filter(child => filterVisibleRoutes(child, user))
      : undefined,
  }));
  const {pathname} = useLocation();
  const parts = pathname.split('/');
  const setPaddingToZero = parts[1] === 'chat';
  return (
    <Suspense fallback={<CSpinner color="primary" />}>
      <AppContentStyle setPaddingToZero={setPaddingToZero}>
        <Routes>
          {visibleRoutes.map((route, idx) => {
            return resolveRoute(route, idx);
          })}
          <Route
            path="/*"
            element={
              <Navigate
                to={
                  !user ||
                  checkAuthorization(user, entity.dashboard, action.view)
                    ? 'dashboard'
                    : '/chat/userGroups'
                }
                replace
              />
            }
          />
        </Routes>
      </AppContentStyle>
    </Suspense>
  );
};

export default React.memo(AppContent);
