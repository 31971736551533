import React from 'react';
import {TableHeader} from '../../../components/header/TableHeader';
import {routeLink} from '../../../constant';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import locationConstant from '../../../constant/locationType';
import {
  IdentifierValidation,
  phoneAndFaxValidation,
} from '../../../utils/validations/commonValidations';
import {useParams} from 'react-router-dom';

const PatientForm = props => {
  const {facilityId} = useParams();
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.patients}`,
    method: 'post',
    eventSourceId: [''],
  });

  return (
    <>
      <TableHeader title="Add New Patient" secondaryTitle={''} />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          Identifiers: [],
          locationStatus: 'Home',
          residence: facilityId,
          residenceType: locationConstant.locationTypeId.Facility,
          currentLocation: facilityId,
          currentLocationType: locationConstant.locationTypeId.Facility,
          Address: {
            StreetAddress: null,
            City: null,
            State: null,
            ZIP: null,
            County: null,
            Country: null,
          },
          PhoneNumber: {
            Mobile: null,
            Home: null,
            Office: null,
          },
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'text',
                field: 'FirstName',
                label: 'First Name',
                size: 'medium',
                required: true,
              },
              {
                type: 'text',
                field: 'MiddleName',
                label: 'Middle Name',
                size: 'medium',
              },
              {
                type: 'text',
                field: 'LastName',
                label: 'Last Name',
                size: 'medium',
                required: true,
              },
              {
                type: 'date',
                field: 'DOB',
                label: 'Date of Birth',
                size: 'medium',
                required: true,
              },
              {
                type: 'autoComplete',
                field: 'Sex',
                label: 'Sex',
                options: ['Male', 'Female'],
                size: 'medium',
                required: true,
              },
              {
                type: 'autoComplete',
                field: 'primaryPractice',
                label: 'Primary Practice',
                api: '/v1/practices',
                filter: () => JSON.stringify({status: 'Active'}),
                suggestionField: 'name',
                required: true,
                size: 'medium',
              },
              {
                type: 'text',
                label: 'SSN',
                field: 'SSN',
                size: 'medium',
              },
              {
                type: 'autoComplete',
                field: 'IsDeceased',
                label: 'Is Deceased',
                options: [
                  {label: 'Yes', value: true},
                  {label: 'No', value: false},
                ],
                keyField: 'value',
                suggestionField: 'label',
                size: 'medium',
              },
              {
                type: 'text',
                field: 'Address.StreetAddress',
                label: 'Street Address',
              },
              {
                type: 'text',
                field: 'Address.City',
                label: 'City',
              },
              {
                type: 'text',
                field: 'Address.State',
                label: 'State',
              },
              {
                type: 'text',
                field: 'Address.ZIP',
                label: 'ZIP',
              },
              {
                type: 'text',
                field: 'PhoneNumber.Mobile',
                validate: value => {
                  return phoneAndFaxValidation(value, 'phone');
                },
                label: 'Phone Number',
                size: 'medium',
              },
            ],
          },
          {
            label: 'Identifiers',
            fields: [
              {
                type: 'autoComplete',
                field: 'Identifiers[0].IDType',
                label: 'Type',
                options: [
                  {label: 'AdvancedMD', value: 'AdvancedMD'},
                  {label: 'Chart Number', value: 'ChartNumber'},
                ],
                keyField: 'value',
                suggestionField: 'label',
                size: 'medium',
                required: true,
              },
              {
                type: 'text',
                field: 'Identifiers[0].ID',
                label: 'ID',
                size: 'medium',
                required: true,
                validate: IdentifierValidation,
              },
              {
                type: 'autoComplete',
                field: 'Identifiers[1].IDType',
                label: 'Type',
                options: [
                  {label: 'AdvancedMD', value: 'AdvancedMD'},
                  {label: 'Chart Number', value: 'ChartNumber'},
                ],
                keyField: 'value',
                suggestionField: 'label',
                size: 'medium',
                required: true,
              },
              {
                type: 'text',
                field: 'Identifiers[1].ID',
                label: 'ID',
                size: 'medium',
                required: true,
                validate: IdentifierValidation,
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};

export default PatientForm;
