import {AddCareTeamMember} from '../components/addCareTeamMember.modal';
import CreatePatientChatGroup from '../components/createPatientChatGroup.modal';
import {CareTeamMembers} from '../screens/CareTeamMembers';
import {TaskRoutes} from './TaskRoutes';

export const CareTeamRoutes = [
  {
    name: 'CareTeam',
    path: 'careTeamMembers',
    component: CareTeamMembers,
    children: [
      {
        name: 'Add Care Team Member',
        path: 'add-care-team-member',
        component: AddCareTeamMember,
        screenOptions: {
          modalSize: 'large',
        },
      },
      {
        name: 'Create Chat',
        path: 'create-patient-chat',
        component: CreatePatientChatGroup,
        screenOptions: {
          modalSize: 'medium',
        },
      },
      ...TaskRoutes,
    ],
  },
];
