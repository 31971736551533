import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {View} from '@unthinkable/react-core-components';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {invokeEvents, useInvoke} from '../../../hooks/useInvoke';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {TextRenderer} from '../../../components/formEditors/Editors';
import {RowHeading} from '../../../components/header/RowHeading';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import {Button} from '../../../components/button/Button.js';
import {Confirm} from '../../../components/confirm';
import {Icon} from '../../../components/icon';
import buttonType from '../../../constant/buttonType.js';
import {routeLink} from '../../../constant';
import modalMessages from '../../../constant/ModalMessage';
import {action, entity} from '../../../constant/authorization';
import {STATUS} from '../../user/constants/UserConstants';
import {USER_TYPE} from '../../../constant/userType';
import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService';
import {isAdmin} from '../../../utils/checkUserType';
import images from '../../../assets/images';
import {getPatientById} from '../service/patient.service';
import {HttpAuthService} from '../../../services';
import {generateUserTypeWiseCareTeamMembers} from '../../../services/user.service';
import {patientDetailsWidgetStyle} from './style/PatientDetail.style';
import {ColumnTextRender, GroupRowText} from '../styles/CareTeamMembers.style';

const RenderUser = ({row}) => {
  return (
    <View>
      <ColumnTextRender title={row?.user?.name}>
        {row?.user?.name}
      </ColumnTextRender>
      <GroupRowText title={row?.user?.email}> {row?.user?.email} </GroupRowText>
    </View>
  );
};

export const CareTeamMembers = props => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const {patientId} = useParams();
  const {state} = useLocation();
  const {patient: statePatient} = state || {};
  const [patient, setPatient] = useState(statePatient);
  const navigate = useNavigate();
  const {user} = useAuth();
  const RenderDelete = ({row}) => {
    const {user} = useAuth();
    const deleteDocument = useInvoke({
      method: 'put',
      eventSourceId: ['careTeamMembers'],
      throwError: true,
    });

    if (
      (isAdmin() ||
        (user?.practiceId &&
          user?.practiceId?._id === row?.user?.practiceId?._id) ||
        (user?.facilityId &&
          user?.facilityId?._id === row?.user?.facilityId?._id)) &&
      checkAuthorization(row?.user, entity.patient, action.viewOwn)
    ) {
      return (
        <Confirm
          title={modalMessages.careTeamMember.title}
          secondaryTitle={row.name}
          message={modalMessages.careTeamMember.message}
          confirmText={modalMessages.careTeamMember.confirmText}
          variant="error"
          onConfirm={async () => {
            await deleteDocument({
              uri:
                routeLink.removeCareTeamMember +
                `/${patient?._id}/${row?.user?._id}`,
            });
          }}>
          <Icon src={images.trash} title="Delete" />
        </Confirm>
      );
    } else {
      return null;
    }
  };

  const RenderChatIcon = ({row}) => {
    return (
      <Icon
        src={images.chatSmileGreen}
        title="Chat"
        onClick={() => {
          navigate('create-patient-chat', {
            state: {
              users: row?.user ? [row.user] : [],
              patient: patient,
            },
          });
        }}
      />
    );
  };

  const fetchPatient = async () => {
    try {
      const response = await getPatientById(patientId);
      if (response?.data?.length === 0) {
        navigate('/patient');
      }
      setPatient(response?.data);
    } catch (err) {
      console.log('consoling Error in fetchPatient', err);
      navigate('/patient');
    }
  };
  useEffect(() => {
    if (!patient) {
      fetchPatient();
    }
  }, []);
  const fetchCareTeamMembers = async () => {
    try {
      setLoading(true);
      const response = await HttpAuthService.get(routeLink.getCareTeamMember, {
        params: {
          filter: JSON.stringify({
            status: STATUS.ACTIVE,
            patient: patient?._id,
            primaryPractice: patient?.primaryPractice?._id,
            practices: patient?.practices,
            residence: patient?.residence?._id,
            currentLocation: patient?.currentLocation?._id,
            'user.userType.name': [
              USER_TYPE.OFFICE_USER,
              USER_TYPE.MEDICAL_PROVIDER,
              USER_TYPE.FACILITY_USER,
            ],
          }),
          fields: {
            patient: 1,
            user: {
              name: 1,
              email: 1,
              userType: {
                name: 1,
              },
              practiceId: {
                name: 1,
              },
              facilityId: {
                name: 1,
              },
              employerType: {
                name: 1,
              },
              phoneNumber: 1,
              organizationType: 1,
            },
          },
        },
      });
      setData(generateUserTypeWiseCareTeamMembers(response.data));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const renderTable = props => {
    const {row} = props;
    const {notify} = useEventHandler();

    if (!row) return;
    if (row?.name === 'Header') {
      return (
        <View style={{flex: 1, overflow: 'hidden', maxHeight: '335px'}}>
          <BasicTable
            columns={[
              {
                header: 'Name',
              },
              {
                header: 'User Type',
              },
              {
                header: 'Phone Number',
              },
              {
                header: 'Employer',
              },
              {
                header: '',
              },
            ]}></BasicTable>
        </View>
      );
    } else
      return (
        <View style={{flex: 1}}>
          <RowHeading
            title={row.name}
            actions={() => {
              return (
                <Icon
                  src={row.expanded ? images.showLess : images.showMore}
                  onClick={() => {
                    row.expanded = !row.expanded;
                    invokeEvents({
                      eventSourceId: ['collapse'],
                      notify,
                    });
                  }}
                />
              );
            }}
          />
          {row.expanded && (
            <BasicTable
              style={{
                rowText: {
                  fontSize: '0.875rem',
                  fontWeight: '500',
                },
              }}
              eventSourceId={'careProviders'}
              variant={'outline'}
              data={row.data}
              columns={[
                {
                  render: RenderUser,
                },
                {
                  type: 'text',
                  field: 'user.userType.name',
                },
                {
                  type: 'text',
                  field: 'user.phoneNumber',
                },
                {
                  render: ({row, ...rest}) => {
                    return (
                      <TextRenderer
                        {...rest}
                        title={
                          row?.user?.employerType?.name === entity.facilities
                            ? row?.user?.facilityId?.name
                            : row?.user?.practiceId?.name
                        }
                        value={
                          row?.user?.employerType?.name === entity.facilities
                            ? row?.user?.facilityId?.name
                            : row?.user?.practiceId?.name
                        }
                      />
                    );
                  },
                },
                {
                  render: RenderDelete,
                },
                {
                  render: RenderChatIcon,
                },
              ]}
              {...props}
            />
          )}
        </View>
      );
  };

  useEffect(() => {
    fetchCareTeamMembers();
  }, [patient]);

  useDataFetchEvents({eventSourceId: ['careTeamMembers']}, () => {
    fetchCareTeamMembers();
  });

  return (
    <View style={{...patientDetailsWidgetStyle}}>
      <TableHeader
        title={''}
        actions={
          checkAuthorization(user, entity.careTeamMembers, action.create) &&
          checkPrimaryPractice(user, patient)
            ? [
                <Button
                  key="addCareTeamMember"
                  text="Add Care Team Member"
                  onPress={() => {
                    navigate(`add-care-team-member`, {
                      state: {
                        patient: patient,
                      },
                    });
                  }}
                  icon={images.addIcon}
                  iconPosition="right"
                  buttonType={buttonType.linkButtonWithBorder}
                />,
              ]
            : []
        }
      />
      <BasicTable
        variant={'outline'}
        data={data}
        loading={loading}
        eventSourceId={['careTeamMembers', 'collapse']}
        renderRow={renderTable}
        {...props}
      />
    </View>
  );
};
