import React from 'react';
import {Row, View} from '@unthinkable/react-core-components';
import {patientDetailStyles} from '../screens/style';
import {Button} from '../../../components/button/Button.js';
import buttonType from '../../../constant/buttonType.js';
import {useNavigate} from 'react-router-dom';
import images from '../../../assets/images';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';
import {FilePreviewer} from 'src/components/filePreviewer';
// import {FilePreviewer} from '../../../components/filePreviewer';

export const PatientHeader = ({
  patient,
  encounter,
  visitTaskId,
  notesTaskId,
  medicationTaskId,
  contactTaskId,
  isContactTaskButtonVisible = false,
  isTcmVisitTaskButtonVisible = false,
  isTcmMedicationButtonVisible,
  isGenerateNotesButtonVisible = false,
  isGenerateNotesButtonDisabled = true,
  // isCCDVisible = false,
}) => {
  const navigate = useNavigate();

  const {isMobile} = useMediaQuery();
  return (
    <View
      style={
        isMobile
          ? patientDetailStyles.headerContainerColumn
          : patientDetailStyles.headerContainer
      }>
      <View
        style={
          isMobile
            ? patientDetailStyles.headerButtonContainerColumn
            : patientDetailStyles.headerButtonContainer
        }>
        {isContactTaskButtonVisible && (
          <Button
            key="ContactAttempts"
            text="Contact Attempts"
            onPress={() => {
              navigate(
                `${encounter._id}/${contactTaskId}/add-contact-attempts`,
                {
                  state: {
                    encounter,
                  },
                },
              );
            }}
            icon={images.addUser}
            iconPosition="right"
            buttonType={buttonType.linkButtonWithBorder}
          />
        )}
        {isTcmMedicationButtonVisible && (
          <Row gap={4}>
            <Button
              key="completeMedicationTask"
              text="Complete Med Rec"
              icon={images.medRecComplete}
              iconPosition="right"
              buttonType={buttonType.linkButtonWithBorder}
              onPress={() => {
                navigate(`complete-medication/${medicationTaskId}`, {
                  state: {
                    patient: patient,
                    encounter: encounter,
                  },
                });
              }}
            />
          </Row>
        )}
        {isTcmVisitTaskButtonVisible && (
          <Row gap={4}>
            <Button
              key="completeVisitTask"
              text="Complete Visit"
              buttonType={buttonType.linkButtonWithBorder}
              onPress={() => {
                navigate(`complete-visit/${visitTaskId}`, {
                  state: {
                    titleName: patient.FullName,
                    patient: patient,
                    encounter: encounter,
                    taskId: visitTaskId,
                  },
                });
              }}
            />
          </Row>
        )}
        {isGenerateNotesButtonVisible && (
          <Row gap={4}>
            <Button
              key="Generate_TCM_Note"
              text="Generate TCM Note"
              buttonType={buttonType.linkButtonWithBorder}
              onPress={() => {
                navigate(`tcm-notes`, {
                  state: {
                    titleName: patient.FullName,
                    patient: patient,
                    encounter: encounter,
                    taskId: notesTaskId,
                  },
                });
              }}
              disabled={isGenerateNotesButtonDisabled}
            />
          </Row>
        )}
        {encounter?.CCD && (
          <Row gap={4}>
            {FilePreviewer({
              value: {
                contentType: 'application/pdf',
                filename: encounter?.CCD?.filename,
                key: encounter?.CCD?.key,
              },
              renderComponent: (
                <Button
                  key="CCD"
                  text="CCD"
                  buttonType={buttonType.linkButtonWithBorder}
                />
              ),
            })}
          </Row>
        )}
      </View>
    </View>
  );
};
