import {useEventHandler} from '@unthinkable/react-event-handler';
import {useToast} from '@unthinkable/react-toast';
import React, {useState} from 'react';
import {Button} from 'src/components/button';
import {Confirm} from 'src/components/confirm';
import {routeLink} from 'src/constant';
import buttonType from 'src/constant/buttonType';
import modalMessages from 'src/constant/ModalMessage';
import {TASK_TYPE} from 'src/constant/taskType';
import {HttpAuthService} from 'src/services';

const createForm1823 = () => {
  const [loading, setLoading] = useState(-1);
  const {notify} = useEventHandler();
  const toast = useToast();

  const requestForm1823Handler = async (patient, index) => {
    setLoading(index);
    try {
      const response = await HttpAuthService.post(
        `${routeLink.CreateForm1823Task}`,
        {
          patientId: patient?._id,
          encounterId: patient?.latestEncounter?._id,
        },
      );
      if (response?.success) {
        notify &&
          notify('dataUpdated', {
            id: 'form1823Task',
          });
      }
      toast({
        message: response.message,
        type: response.success ? 'Success' : 'Error',
      });
    } catch (err) {
      toast({
        message: err.message,
        type: 'Error',
      });
    } finally {
      setLoading(-1);
    }
  };
  const renderForm1823Button = (patient, index) => {
    const form1823 = patient?.latestEncounter?.taskInfo?.filter(
      task => task.type === TASK_TYPE.Form1823,
    );
    return (
      !form1823?.length && (
        <Confirm
          title={modalMessages.requestForm1823.title}
          message={modalMessages.requestForm1823.message}
          confirmText={modalMessages.requestForm1823.confirmText}
          onConfirm={() => {
            requestForm1823Handler(patient, index);
          }}>
          <Button
            key="Request Form 1823"
            text="Request Form 1823"
            loading={loading === index}
            buttonType={buttonType.linkButton}
          />
        </Confirm>
      )
    );
  };
  return {
    renderForm1823Button,
    loading,
  };
};

export default createForm1823;
