import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from '../../auth/hooks/useAuth';
import {useMediaQuery} from '../../auth/hooks/useMediaquery';
import {Table} from '../../../components/table';
import {renderPatientCard} from '../../../components/card/patientCard';
import {patientFields} from '../../../constant/patient.fields';
import {action, entity} from '../../../constant/authorization';
import checkAuthorization from '../../../services/accessService';
import {getPatientsFullName} from '../../../utils/commonMethod';
import {FACILITY_TYPE} from '../../../constant/facilityType';
import {locationStatus} from 'src/modules/patient/service/patient.service';
import {
  renderArrivalDate,
  renderCurrentLocation,
  renderDischargeDate,
  renderDOB,
  renderPatientType,
  renderTcmDueDate,
} from '../facility.utils';
import {TextRender} from '@unthinkable/react-table';
import createForm1823 from '../hooks/Form1823.hook';
import useChangePatientType from '../hooks/useChangePatientType';
import {Icon} from './FacilityTable.style';
import images from 'src/assets/images';
import {TableHeader} from 'src/components/header/TableHeader';
import {Button} from 'src/components/button';
import buttonType from 'src/constant/buttonType';
import {
  isFacilityEmployee,
  isPracticeEmployee,
} from 'src/utils/checkEmployerType';
import {useFacilityFilter} from '../hooks/useFacilityFilter.hook';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {LoaderAbsoluteContainer} from './FacilityStatsOverview.style';

export const FacilityCareTeamPatientTable = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state, pathname} = useLocation();
  const reduxKey = pathname.split('/').pop();

  const {facility: stateFacility} = state || {};
  const facility = isFacilityEmployee() ? user.facilityId : stateFacility;

  const {renderForm1823Button} = createForm1823();
  const {RenderPatientTypeChangeAction} = useChangePatientType();
  const {generateFacilityPatientFilter} = useFacilityFilter();
  const {
    filter: stateFilter = {},
    field,
    sort,
    api,
    showPatientType,
    residenceTab = false,
    admissionPlanningTab = false,
    transitionalCareTab = false,
  } = generateFacilityPatientFilter(facility);
  useEffect(() => {
    if (!api) {
      navigate('/dashboard');
    }
  }, []);
  const {isMobile} = useMediaQuery();
  const filter = {...stateFilter};
  if (isPracticeEmployee()) {
    filter['$and'] = [
      ...(filter['$or'] ? [{$or: [...filter['$or']]}] : []),
      {
        $or: [
          {primaryPractice: user?.practiceId?._id},
          {practices: user?.practiceId?._id},
        ],
      },
    ];
    if (filter['$or']) delete filter['$or'];
  }

  let columns = [];
  if (isMobile) {
    columns = [
      {
        render: ({row}) => {
          return renderPatientCard({row: row.patient});
        },
      },
    ];
  } else {
    columns = [
      {
        header: 'Name',
        type: 'text',
        render: ({row}) => {
          const {FirstName, LastName, MiddleName} = row.patient;
          return getPatientsFullName({FirstName, LastName, MiddleName});
        },
        width: 200,
      },
      {
        header: 'DOB',
        type: 'text',
        render: ({row}) => renderDOB(row.patient),
        visible: residenceTab,
      },
      {
        header: 'Location Status',
        render: ({row}) => locationStatus(row.patient),
        visible: residenceTab,
      },
      {
        header: 'Current Location',
        render: ({row}) => renderCurrentLocation(row.patient),
        visible: admissionPlanningTab,
        width: 300,
      },
      {
        header: 'Admission Date',
        render: ({row}) => renderArrivalDate(row.patient),
        visible: admissionPlanningTab,
        width: 150,
      },
      {
        header: 'Hospitalization Reason',
        render: ({row}) => {
          return (
            <TextRender value={row?.patient?.latestEncounter?.diagnoseReason} />
          );
        },
        visible: admissionPlanningTab || transitionalCareTab,
        width: 200,
      },
      {
        header: 'Discharge Date',
        render: ({row}) => renderDischargeDate(row?.patient),
        visible: transitionalCareTab,
        width: 200,
      },
      {
        header: 'New Medications',
        render: () => {
          return '';
        },
        width: 200,
        visible: transitionalCareTab,
      },
      {
        header: 'Readmission Risk',
        render: () => {
          return '';
        },
        visible: transitionalCareTab,
        width: 200,
      },
      {
        header: 'TCM Due Date',
        render: ({row}) => renderTcmDueDate(row.patient),
        width: 150,
        visible: () => transitionalCareTab && isPracticeEmployee(),
      },
      {
        header: 'Type',
        render: ({row}) => renderPatientType(row.patient),
        visible: () => showPatientType,
        width: 150,
      },

      // {
      //   render: ({row}) => renderTaskWidget(row.patient, user),
      //   width: 600,
      //   visible: () => {
      //     return user.practiceId && !admissionPlanningTab;
      //   },
      // },
      {
        render: ({row, index}) => renderForm1823Button(row.patient, index),
        visible: () =>
          transitionalCareTab &&
          checkAuthorization(user, entity.Form1823, action.request),
      },
      {
        render: ({row}) => {
          return (
            row?.patient?.residence?.type === FACILITY_TYPE.SkilledNursing &&
            RenderPatientTypeChangeAction(row?.patient)
          );
        },
        visible: () =>
          showPatientType &&
          checkAuthorization(user, entity.patientType, action.edit),
        width: 50,
      },
      {
        render: () => {
          return <Icon src={images.chatSmileGreen} />;
        },
        width: 50,
        visible: residenceTab,
      },
    ];
  }
  if (!facility) {
    return (
      <LoaderAbsoluteContainer>
        <ActivityIndicator />
      </LoaderAbsoluteContainer>
    );
  }

  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            actions={
              (admissionPlanningTab || residenceTab) &&
              checkAuthorization(user, entity.patient, action.create)
                ? [
                    <Button
                      key="Add New Patient"
                      text="Add New Patient"
                      onPress={() => {
                        navigate('/facility-stats/overview/add-patient', {
                          state,
                        });
                      }}
                      icon={images.addIcon}
                      iconPosition="right"
                      buttonType={buttonType.linkButtonWithBorder}
                    />,
                  ]
                : []
            }
          />
        );
      }}
      flex={false}
      reduxKey={reduxKey}
      api={api}
      filter={filter}
      eventSourceId={['form1823Task', 'patientClassification']}
      onRowPress={({row}) => {
        navigate(`/patient/details/${row?.patient?._id}/tcmEncounter`, {
          state: {
            patient: row.patient,
          },
        });
      }}
      style={{
        border: isMobile,
        rowBorder: isMobile,
        columnPadding: isMobile,
      }}
      fields={
        field || {
          patient: patientFields,
        }
      }
      sort={sort || {}}
      columns={columns}
    />
  );
};
