import {action, entity} from '../../constant/authorization';
import {status} from '../../constant/status';
import {TASK_TYPE} from '../../constant/taskType';
import checkAuthorization from '../../services/accessService';
import {isPracticeEmployee} from '../../utils/checkEmployerType';
import {isFacilityAdmin, isFacilityUser} from '../../utils/checkUserType';

export const TaskFilter = ({user}) => {
  const filter = {
    status: {$nin: [status.complete, status.inactive]},
    // 'patientId.status': status.active,
  };
  if (isPracticeEmployee()) {
    filter['patientId.primaryPractice'] = user?.practiceId?._id;
  }
  if (
    isPracticeEmployee() &&
    checkAuthorization(user, entity.patient, action.viewOwn)
  ) {
    filter['residence'] = {$in: user?.facilityAssigned};
  }

  if (isFacilityAdmin()) {
    filter['$or'] = [
      {'patientId.currentLocation': user?.facilityId},
      {'patientId.residence': user?.facilityId},
    ];
  } else if (isFacilityUser()) {
    filter['owners'] = user?._id;
  }

  if (!isFacilityAdmin()) {
    filter['status'] = {
      $nin: [status.complete, status.approved, status.inactive],
    };
  }

  const taskTypes = TaskUserHaveAccess({user});

  return {filter, taskTypes};
};

export const TaskUserHaveAccess = ({user}) => {
  const taskTypes = [];
  if (checkAuthorization(user, entity[TASK_TYPE.ContactTask], action.view)) {
    taskTypes.push(TASK_TYPE.ContactTask);
  }
  if (checkAuthorization(user, entity[TASK_TYPE.MedicationTask], action.view)) {
    taskTypes.push(TASK_TYPE.MedicationTask);
  }
  if (checkAuthorization(user, entity[TASK_TYPE.VisitTask], action.view)) {
    taskTypes.push(TASK_TYPE.VisitTask);
  }
  if (checkAuthorization(user, entity[TASK_TYPE.NotesTask], action.view)) {
    taskTypes.push(TASK_TYPE.NotesTask);
  }
  if (
    checkAuthorization(
      user,
      entity[TASK_TYPE.INITIAL_TCM_ELIGIBILITY],
      action.view,
    )
  ) {
    taskTypes.push(TASK_TYPE.INITIAL_TCM_ELIGIBILITY);
  }
  if (
    checkAuthorization(user, entity[TASK_TYPE.FACILITY_ASSIGNMENT], action.view)
  ) {
    taskTypes.push(TASK_TYPE.FACILITY_ASSIGNMENT);
  }
  if (checkAuthorization(user, entity[TASK_TYPE.Form1823], action.view)) {
    taskTypes.push(TASK_TYPE.Form1823);
  }
  if (
    checkAuthorization(user, entity[TASK_TYPE.FACILITY_ONBOARDING], action.view)
  ) {
    taskTypes.push(TASK_TYPE.FACILITY_ONBOARDING);
  }
  if (
    checkAuthorization(
      user,
      entity[TASK_TYPE.PATIENT_CLASSIFICATION],
      action.complete,
    )
  ) {
    taskTypes.push(TASK_TYPE.PATIENT_CLASSIFICATION);
  }
  if (
    checkAuthorization(user, entity[TASK_TYPE.DISCHARGE_STATUS], action.view)
  ) {
    taskTypes.push(TASK_TYPE.DISCHARGE_STATUS);
  }

  return taskTypes;
};
