import {Confirm} from '@unthinkable/react-confirm';
import React from 'react';
import images from 'src/assets/images';
import {Icon} from 'src/components/icon';
import {routeLink} from 'src/constant';
import modalMessages from 'src/constant/ModalMessage';
import {useInvoke} from 'src/hooks';

const useChangePatientType = () => {
  const invoke = useInvoke({method: 'put', throwError: true});
  const RenderPatientTypeChangeAction = patient => {
    return (
      <Confirm
        title={modalMessages.changeToSkilledNursing.title}
        secondaryTitle={patient.name}
        message={
          patient?.type === 'skilledNursing'
            ? modalMessages.changeToLongTermCare.message
            : modalMessages.changeToSkilledNursing.message
        }
        confirmText="Confirm"
        onConfirm={async () => {
          await invoke({
            uri: routeLink.getPatient + '/' + patient?._id,
            data: {
              type:
                patient?.type === 'skilledNursing'
                  ? 'longTermCare'
                  : 'skilledNursing',
            },
            eventSourceId: ['patientClassification'],
          });
        }}>
        <Icon src={images.change} />
      </Confirm>
    );
  };
  return {
    RenderPatientTypeChangeAction,
  };
};

export default useChangePatientType;
