import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FacilityTable} from './FacilityTable';
import {routeLink} from '../../../constant';

export const FacilityStatsTable = props => {
  const navigate = useNavigate();
  return (
    <FacilityTable
      {...props}
      api={routeLink.getFacilityWithMyPatients}
      fromFacilityStats
      onRowPress={({row}) => {
        navigate(`/facility-stats/overview/${row._id}/patient/residents`, {
          state: {
            facility: row,
          },
        });
      }}
    />
  );
};
