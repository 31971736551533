import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {View} from '@unthinkable/react-core-components';
import {useFilter} from '@unthinkable/react-filters';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import routeLink from '../../../constant/routeLink.js';
import {patientDetailsWidgetStyle} from '../screens/style/PatientDetail.style';
import {status} from '../../../constant/status';
import {AutoCompleteFilter} from '../../../components/filter/Filters';
import {getPatientById} from '../service/patient.service';
import {combineText} from 'src/utils/commonMethod';
import {TextRenderer} from '@unthinkable/react-text-input';
import Config from 'src/Config';
export const OutPatientMedication = props => {
  const {state} = useLocation();
  const {patientId} = useParams();
  const {patient: statePatient} = state || {};
  const [patient, setPatient] = useState(statePatient || {});
  const navigate = useNavigate();
  const filterProps = useFilter({
    params: {
      status: status.active,
    },
  });
  const {onChangeFilter, filterValues} = filterProps;
  const {params} = filterValues;
  const filter = {
    patientId: patientId,
  };
  const fetchPatient = async () => {
    try {
      if (!patient?._id) {
        const response = await getPatientById(patientId);
        if (response.data) {
          setPatient(response?.data);
        } else {
          navigate('/patient');
        }
      }
    } catch (err) {
      console.log(err);
      navigate('/patient');
    }
  };
  if (params.status) {
    filterValues.filter = {
      ...filterValues.filter,
      ...filter,
      status: params.status,
    };
  } else {
    delete filterValues?.filter?.status;
  }

  useEffect(() => {
    fetchPatient();
  }, []);

  return (
    <View style={patientDetailsWidgetStyle}>
      <TableHeader
        title={''}
        actions={[
          <AutoCompleteFilter
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
            field={'status'}
            options={[status.active, status.inactive]}
            key={'statusFilter'}
            placeholder={'Status'}
            asParam={true}
          />,
        ]}
      />
      <BasicTable
        params={{...params}}
        variant={'outline'}
        eventSourceId="outpatient-medications"
        api={routeLink.getOutPatientMedication}
        fields={{
          drugName: 1,
          dose: 1,
          frequency: 1,
          direction: 1,
          prescriberId: {
            firstName: 1,
            lastName: 1,
          },
          status: 1,
          fillDate: 1,
        }}
        filter={filterValues.filter}
        columns={[
          {
            header: 'Name',
            field: 'drugName',
            render: props => (
              <TextRenderer
                {...props}
                styles={{
                  ...props.styles,
                  text: {
                    ...props.styles.text,
                    textDecoration:
                      props?.row?.status == 'Inactive'
                        ? 'line-through'
                        : 'none',
                  },
                }}
              />
            ),
          },
          {
            header: 'Dose',
            filed: 'dose',
            type: 'text',
          },
          {
            header: 'Frequency',
            field: 'frequency',
            type: 'text',
          },
          {
            header: 'Directions',
            field: 'direction',
            type: 'text',
          },
          {
            header: 'Prescriber',
            render: ({row}) => {
              return (
                <TextRenderer
                  value={combineText(
                    row?.prescriberId?.firstName,
                    row?.prescriberId?.lastName,
                  )}
                />
              );
            },
          },
          {
            header: 'Last Fill Date',
            field: 'fillDate',
            type: 'date',
            formatOptions: {
              format: Config.dateFormat,
            },
          },
          // {
          //   header:"Estimated Days Remaining",

          // }
        ]}
        {...props}
      />
    </View>
  );
};
