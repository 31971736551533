import React, {useEffect} from 'react';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {useDispatch} from 'react-redux';
import {updateLastMessage} from '../chatRedux/actions';
import {getLastMessage} from '../chatRedux/reducer';

const GetLastMessage = ({row}) => {
  const {user} = useAuth();
  const dispatch = useDispatch();
  const lastMessage = getLastMessage(row);

  useEffect(() => {
    if (user?.chatInstance) {
      dispatch(
        updateLastMessage(row?.groupId?.lastMessage, row?.groupId?.PNGroupId),
      );
    }
  }, [row.lastReadTimeToken, row._id]);

  return <div>{lastMessage && lastMessage}</div>;
};

export default GetLastMessage;
