import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useToast} from '@unthinkable/react-toast';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {useInvoke} from '../../../hooks';
import ChatMessageArea from '../components/ChatMessageArea';
import JumpToLatest from '../components/JumpToLatest';
import MessageInputBox from '../components/MessageInputBox';
import {ChatMainContainer} from '../styles/Chat.style';
import {ChatMessageAreaContainer} from '../styles/ChatMessageArea.style';
import {routeLink, toasterMessage} from '../../../constant';
import {DEFAULT_MESSAGE_COUNT} from '../constants/chat.constants';
import {getMembersData} from '../chatRedux/reducer';
import {HttpAuthService} from '../../../services';
import ChatHeader from '../components/ChatHeader';

const ChatContent = () => {
  const [oldMessages, setOldMessages] = useState([]);
  const [newMessages, setNewMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [canJump, setCanJump] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const chatElement = useRef(null);
  const {channelId, groupId, patientId} = useParams();

  const {user} = useAuth();
  const toast = useToast();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const membersData = getMembersData(groupId);

  const invoke = useInvoke({
    method: 'put',
    close: false,
    eventSourceId: ['Groups'],
  });

  const scrollToBottom = useCallback(
    (start, end) => {
      chatElement?.current?.scrollTo(start, end);
    },
    [chatElement],
  );
  const handleJump = () => {
    scrollToBottom(0, chatElement?.current?.scrollHeight);
    setCanJump(false);
  };

  const getMessageHistory = useCallback(
    async ({count = DEFAULT_MESSAGE_COUNT, timetoken = ''}) => {
      if (!channelId) return;
      let options = {
        channel: channelId,
        count,
      };
      if (timetoken) {
        options['start'] = timetoken;
      }
      try {
        setIsLoading(true);
        const history = await user?.chatInstance?.history(options);
        const messages = history?.messages?.map(message => ({
          text: message?.entry[0]?.text,
          timetoken: message?.timetoken,
          uuid: message?.entry[0]?.uuid,
        }));

        return messages;
      } catch (error) {
        toast({
          message: `${toasterMessage.getMessage.failure}: ${error}`,
          type: 'Error',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [channelId],
  );
  const loadMore = async () => {
    try {
      if (hasMore) {
        const response = await getMessageHistory({
          timetoken: oldMessages.length
            ? oldMessages?.[0].timetoken
            : newMessages?.[0].timetoken,
        });
        if (response) {
          if (response.length < DEFAULT_MESSAGE_COUNT) {
            setHasMore(false);
          }
          setOldMessages(messages => [...response, ...messages]);
          scrollToBottom(
            chatElement.current.scrollTop,
            chatElement.current.scrollTop + response.length,
          );
        }
      }
    } catch (error) {
      toast({
        message: `${toasterMessage.loadMore.failure}:${error}`,
        type: 'Error',
      });
    }
  };

  const fetchData = useCallback(async () => {
    if (!groupId) return;
    try {
      setIsLoading(true);
      if (!group) {
        await fetchGroup();
      }
      const message = await getMessageHistory({});
      setNewMessages(message || []);
      setIsLoading(false);
      // TODO: handle the scroll behavior on loading first time
      setTimeout(() => {
        scrollToBottom(0, chatElement?.current?.scrollHeight);
      }, 500);
    } catch (error) {
      setIsLoading(false);
      toast({
        message: `${toasterMessage.getMessage.failure}: ${error}`,
        type: 'Error',
      });
    }
    [groupId];
  });
  const fetchGroup = async () => {
    try {
      if (groupId) {
        const response = await HttpAuthService.get(
          `${routeLink.getChatGroup}/${groupId}`,
          {
            params: {
              fields: {
                _id: 1,
                name: 1,
                PNGroupId: 1,
                status: 1,
                practiceId: {
                  name: 1,
                },
                facilityId: {
                  name: 1,
                },
                patientId: 1,
                lastMessageTimeToken: 1,
                lastReadTimeToken: 1,
              },
            },
          },
        );

        if (response?.data) {
          setGroup(response.data);
        } else {
          navigate('/dashboard');
        }
      }
    } catch (error) {
      console.log('🚀 ~ fetchGroup ~ error:', error);
    }
  };

  const sendMessage = useCallback(
    text => {
      try {
        setIsSending(true);
        user?.chatInstance?.publish({
          channel: channelId,
          message: [{text: text, uuid: user.PNChatMemberId}],
        });
        setIsSending(false);
        // TODO: handle the scroll behavior on sending
        setTimeout(() => {
          scrollToBottom(0, chatElement?.current?.scrollHeight);
        }, 50);
      } catch (error) {
        setIsSending(false);
        toast({
          message: `${toasterMessage.sendMessage.failure}: ${error}`,
          type: 'Error',
        });
      }
    },
    [channelId, user],
  );

  useEffect(() => {
    fetchData();
  }, [channelId, groupId]);

  // subscribing selected Channel
  useEffect(() => {
    if (user?.chatInstance) {
      user?.chatInstance?.subscribe({channels: [channelId]});
      const chatListener = {
        message: message => {
          const sentMessage = {
            text: message.message[0].text,
            sender: user.name,
            uuid: message.message[0].uuid,
            timetoken: message.timetoken,
          };
          setNewMessages(prevMessages => [...prevMessages, sentMessage]);
        },
      };
      user.chatInstance?.addListener(chatListener);
      return () => {
        user.chatInstance?.removeListener(chatListener);
        user.chatInstance?.unsubscribe({channels: [channelId]});
      };
    }
  }, [channelId]);

  useEffect(() => {
    const lastMessage = newMessages[newMessages.length - 1];
    if (lastMessage) {
      // update in chatMembers
      invoke({
        uri: `${routeLink.updateLastMessageTimetoken}`,
        data: {
          lastReadTimeToken: lastMessage.timetoken,
          groupId: groupId,
        },
      });
    }
    // update chat group
    lastMessage &&
      invoke({
        uri: `/v1/chatgroups/${groupId}`,
        data: {
          lastMessageTimeToken: lastMessage.timetoken,
          lastMessage: lastMessage.text,
        },
      });
    // lastMessage && setLastReadTimeTokenOnPubNub(lastMessage);
    if (!canJump) {
      scrollToBottom(0, chatElement?.current?.scrollHeight);
    }
  }, [newMessages]);

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '1',
        }}>
        Loading...
      </div>
    );
  }
  if (group) {
    return (
      <ChatMainContainer>
        <ChatHeader group={group} />
        <ChatMessageAreaContainer patientId={patientId}>
          <ChatMessageArea
            membersData={membersData}
            isLoading={isLoading}
            chatElement={chatElement}
            loadMore={loadMore}
            messages={[...oldMessages, ...newMessages]}
            setCanJump={setCanJump}
          />
        </ChatMessageAreaContainer>
        {canJump && <JumpToLatest handleJump={handleJump} />}
        <MessageInputBox onSendMessage={sendMessage} isSending={isSending} />
      </ChatMainContainer>
    );
  }
};

export default ChatContent;
