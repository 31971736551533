import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Form} from '../../../components/form';
import {useFormSubmit} from '../../../hooks/useFormSubmit';
import {routeLink} from '../../../constant';
import {useAuth} from '../../auth/hooks/useAuth';
import {TCMContactStatus} from '../../../constant/status';
import {TableHeader} from '../../../components/header/TableHeader';
import {useLocation} from 'react-router-dom';
import {validateDate} from '../../../utils/validations/commonValidations';
import {
  defaultContactMode,
  defaultContactPerson,
} from '../constants/PatientConstants';
import { DateTypeCaste } from 'src/utils/commonMethod';

export const AddContactAttemptsModal = props => {
  const {EncounterId, TaskId} = useParams();
  const {user} = useAuth();
  const {state} = useLocation();
  const {encounter, titleName} = state || {};
  const navigate = useNavigate();
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.getContactAttempt}`,
    method: 'post',
    eventSourceId: ['contactAttempts', 'tcmtasks', 'ContactTask'],
  });
  useEffect(() => {
    if (!encounter?.dischargeDate) {
      navigate('/patient');
    }
  }, []);
  return (
    <>
      <TableHeader title="Contact Attempts" secondaryTitle={titleName || ''} />
      <Form
        api={`${routeLink.getContactAttempt}`}
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          taskId: TaskId,
          updatedBy: user._id,
          encounterId: EncounterId,
          date: DateTypeCaste(new Date()),
          mode: defaultContactMode,
          person: defaultContactPerson,
          contactStatus: TCMContactStatus.successful,
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'autoComplete',
                field: 'mode',
                label: 'Contact Mode',
                api: routeLink.getContactMode,
                sort: {name: 1},
                suggestionField: 'name',
                size: 'medium',
                required: true,
              },
              {
                type: 'autoComplete',
                field: 'person',
                label: 'Contact Person',
                api: routeLink.getContactPerson,
                sort: {name: 1},
                suggestionField: 'name',
                size: 'medium',
                required: true,
              },
              {
                type: 'autoComplete',
                field: 'contactStatus',
                label: 'Contact Status',
                size: 'medium',
                required: true,
                options: Object.values(TCMContactStatus),
              },
              {
                type: 'date',
                field: 'date',
                label: 'Date of Contact',
                size: 'medium',
                required: true,
                validate: date => validateDate(date, encounter?.dischargeDate),
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
