import React from 'react';
import {ChatHeaderMainDiv, MemberIconStyle} from '../styles/ChatHeader.style';
import images from '../../../assets/images';
import {useNavigate} from 'react-router-dom';
const ChatHeader = ({group}) => {
  const navigate = useNavigate();

  return (
    <ChatHeaderMainDiv>
      {group?.name}
      <MemberIconStyle
        src={images.groupMembers}
        alt="members"
        onClick={() => {
          navigate('GroupMembersList', {
            state: {
              patient: group?.patientId,
            },
          });
        }}
      />
    </ChatHeaderMainDiv>
  );
};

export default ChatHeader;
