import {View} from '@unthinkable/react-core-components';
import React from 'react';
import {Table} from '../../../components/table';
import {TableHeader} from '../../../components/header/TableHeader';
import {DateFilter, SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import routeLink from '../../../constant/routeLink.js';
import {useAuth} from '../../auth/hooks/useAuth';
import {AutoCompleteFilter} from '../../../components/filter/Filters';
import {entity} from '../../../constant/authorization.js';
import Config from '../../../Config.js';
import {isAdmin} from '../../../utils/checkUserType.js';
import {ColumnTextRender, GroupRowText} from '../styles/AuditLogTable.style';
const RenderUser = ({row}) => {
  return (
    <View>
      <ColumnTextRender title={row?.user?.name}>
        {row?.user?.name}
      </ColumnTextRender>
      <GroupRowText title={row?.user?.email}> {row?.user?.email} </GroupRowText>
    </View>
  );
};

export const AuditLogTable = props => {
  const {user} = useAuth();
  const {searchValue, onChangeFilter, filterValues} = useFilter();

  filterValues.filter = {
    facilityId: user?.facilityId?._id,
    practiceId: user?.practiceId?._id,
    ...filterValues.filter,
  };
  if (isAdmin()) {
    if (!filterValues?.filter?.employerType) {
      delete filterValues?.filter?.facilityId;
      delete filterValues?.filter?.practiceId;
    } else if (filterValues?.filter?.employerType?.name === entity.practices) {
      delete filterValues?.filter?.facilityId;
    } else if (filterValues?.filter?.employerType?.name === entity.facilities) {
      delete filterValues?.filter?.practiceId;
    }
  }

  return (
    <Table
      renderHeader={() => {
        return (
          <TableHeader
            actions={[
              <SearchFilter
                key="searchFilter"
                placeholder="Search user name"
                value={searchValue}
                onChangeFilter={onChangeFilter}
              />,
              isAdmin() ? (
                <AutoCompleteFilter
                  onChangeFilter={onChangeFilter}
                  filterValues={filterValues}
                  field={'employerType'}
                  api={routeLink.getEmployerTypes}
                  key={'employerTypeFilter'}
                  suggestionField={'name'}
                  placeholder={'Employer'}
                  dependentFilterFields={['practiceId', 'facilityId']}
                  sort={{name: 1}}
                />
              ) : null,
              filterValues?.filter?.employerType?.name === entity.practices ? (
                <AutoCompleteFilter
                  onChangeFilter={onChangeFilter}
                  filterValues={filterValues}
                  field={'practiceId'}
                  api={routeLink.addPractice}
                  key={'practiceFilter'}
                  suggestionField={'name'}
                  placeholder={'Practice'}
                  sort={{name: 1}}
                />
              ) : null,
              filterValues?.filter?.employerType?.name === entity.facilities ? (
                <AutoCompleteFilter
                  onChangeFilter={onChangeFilter}
                  filterValues={filterValues}
                  field={'facilityId'}
                  api={routeLink.getFacilities}
                  key={'facilityFilter'}
                  suggestionField={'name'}
                  placeholder={'Facility'}
                  sort={{name: 1}}
                />
              ) : null,
              <DateFilter
                key="spanFilter"
                options={[
                  {label: 'All', value: 'All'},
                  {label: 'Day', value: 'Day'},
                  {label: 'Week', value: 'Week'},
                  {label: 'Month', value: 'Month'},
                  {label: 'Year', value: 'Year'},
                  {label: 'Custom', value: 'Custom'},
                ]}
                filterValues={filterValues}
                onChangeFilter={onChangeFilter}
                field={'dateTime'}
              />,
            ]}
          />
        );
      }}
      api={routeLink.getAuditLogs}
      search={searchValue}
      searchFields={['user.name']}
      filter={JSON.stringify(filterValues?.filter)}
      sort={{
        dateTime: -1,
      }}
      fields={{
        user: {
          name: 1,
          email: 1,
        },
        dateTime: 1,
        eventType: 1,
        entity: 1,
        notes: 1,
        employerType: {
          name: 1,
        },
        facilityId: {
          name: 1,
        },
        practiceId: {
          name: 1,
        },
      }}
      columns={[
        {
          header: 'User',
          render: RenderUser,
          width: 160,
        },
        {
          header: 'Date',
          field: 'dateTime',
          type: 'date',
          formatOptions: {
            format: Config.dateFormat,
          },
          width: 100,
        },
        {
          header: 'Time',
          field: 'dateTime',
          type: 'date',
          formatOptions: {
            format: Config.timeFormat,
          },
          width: 100,
        },
        {
          header: 'Employer',
          field: row =>
            row.employerType?.name === entity.facilities
              ? row?.facilityId?.name
              : row?.practiceId?.name,
          type: 'text',
          width: 100,
          visible: isAdmin(),
        },
        {
          header: 'Action',
          field: 'eventType',
          type: 'text',
          width: 250,
        },
        {
          header: 'Notes',
          field: 'notes',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};
