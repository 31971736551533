import styled from 'styled-components';

const ChatContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  overflow-y: hidden;
  ${({style}) => style && {...style}}
`;

const GroupListContainer = styled.div`
  width: 40%;
  max-width: 320px;
  border-right: 1px solid #e4e4e7;
`;
const ChatMainScreenContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex: 1;
  position: relative;
`;

const ChatMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const ChatActivityIndicatorStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'Translate(-50%, -50%)',
};

const Image = styled.img`
  height: 400px;
  width: 400px;
  // @media (max-width: 480px) {
  //   height: 16px;
  //   width: 16px;
  // }
`;

export {
  ChatContainer,
  ChatMainScreenContainer,
  GroupListContainer,
  ChatActivityIndicatorStyle,
  ChatMainContainer,
  Image,
};
