import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {View} from '@unthinkable/react-core-components';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {FACILITY_TYPE} from '../../../constant/facilityType';
import {Button} from '../../../components/button';
import images from '../../../assets/images';
import buttonType from '../../../constant/buttonType';
import {Patient_Location_Type} from '../../../modules/patient/constants/PatientConstants';
import {addMedicationStyle} from '../../../modules/patient/screens/style/AddMedication.style';
import locationType from '../../../constant/locationType';

export const FacilityAssignmentModal = props => {
  const {
    state: {tcmTaskId, patient},
  } = useLocation();
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.completeFacilityAssignmentTask}`,
    method: 'put',
    eventSourceId: ['tcmtasks', 'facilityAssignment', 'careTeamMembers'],
  });
  const RenderPlusButton = () => {
    const navigate = useNavigate();
    return (
      <View style={addMedicationStyle.buttonContainer}>
        <Button
          key="Add New Facility Form Transitional Event"
          icon={images.addIcon}
          buttonType={buttonType.linkButtonWithBorder}
          onPress={() => {
            navigate('add-new-facility', {
              state: {
                isRedoxFacility: false,
                tcmTaskId,
                patient,
              },
            });
          }}
        />
      </View>
    );
  };
  return (
    <Form
      saveButtonText={'Save'}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        let _residence;
        if (!data?.residence && !data?.residenceType) {
          _residence = data.location;
        } else if (!data?.residence && data?.residenceType) {
          _residence = null;
        } else {
          _residence = data.residence;
        }
        return {
          data: {
            patientId: patient?._id,
            taskId: tcmTaskId,
            residence: _residence,
            residenceType:
              data?.residenceType || locationType.locationTypeId.Facility,
            currentLocation: data?.location,
            currentLocationType: locationType.locationTypeId.Facility,
            status: 'Complete',
            successStatus: true,
            type: data?.type || 'skilledNursing',
          },
        };
      }}
      layoutFields={[
        {
          label: `Facility Assignment-${patient?.FullName}`,
          fields: [
            {
              type: 'autoComplete',
              label: 'Current Location',
              api: routeLink.getFacilities,
              field: 'location',
              sort: {name: 1},
              fields: {
                name: 1,
                type: 1,
              },
              suggestionField: 'name',
              required: true,
              size: 'large',
              filter: {
                isRedoxFacility: false,
              },
              onChangeValue: (value, e, options) => {
                const {setFieldValue} = options;
                setFieldValue('type', null);
              },
            },
            {
              type: 'autoComplete',
              field: 'type',
              label: 'Patient Type',
              options: [
                {label: 'Skilled Nursing', value: 'skilledNursing'},
                {label: 'Long-Term Care', value: 'longTermCare'},
              ],
              keyField: 'value',
              suggestionField: 'label',
              size: 'medium',
              required: true,
              visible: ({values}) => {
                return values?.location?.type === FACILITY_TYPE.SkilledNursing;
              },
              onChangeValue: (value, e, options) => {
                const {setFieldValue} = options;
                setFieldValue('residenceType', null);
                setFieldValue('residence', null);
              },
            },
            {
              type: 'autoComplete',
              field: 'residenceType',
              label: 'Residence Type',
              api: routeLink.getLocationTypes,
              sort: {name: 1},
              suggestionField: 'value',
              valueField: 'value',
              size: 'medium',
              required: true,
              onChangeValue: (value, e, {setFieldValue}) => {
                if (
                  value?.residenceType?.name === locationType.locationType.Home
                )
                  setFieldValue('residence', null);
              },
              visible: ({values}) => {
                return values?.type === 'skilledNursing';
              },
            },
            {
              type: 'autoComplete',
              label: 'Residence',
              api: routeLink.getFacilities,
              field: 'residence',
              sort: {name: 1},
              suggestionField: 'name',
              required: true,
              size: 'medium',
              filter: {
                isRedoxFacility: false,
              },
              visible: ({values}) => {
                return (
                  values?.residenceType?.value ===
                  Patient_Location_Type.Facility
                );
              },
            },
            {
              render: RenderPlusButton,
              size: 0.5,
              visible: ({values}) =>
                values?.residenceType?.value === Patient_Location_Type.Facility,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
