import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useToast} from '@unthinkable/react-toast';
import {action, entity} from '../constant/authorization';
import {TASK_STATUS, TASK_TYPE} from '../constant/taskType';
import checkAuthorization from './accessService';
import {Icon} from '../components/icon';
import images from '../assets/images';
import modalMessages from '../constant/ModalMessage';
import {isFacilityAdmin} from '../utils/checkUserType';
import {useAuth} from '../modules/auth/hooks/useAuth';
import {RenderGenerateNote} from '../modules/tasks/screens/RenderNotesTask';

export const TaskAction = task => {
  const navigate = useNavigate();
  const toast = useToast();
  const {user} = useAuth();
  const IsFacilityAdmin = isFacilityAdmin();

  if (!task) return null;

  if (task?.type === TASK_TYPE.NotesTask) {
    return RenderGenerateNote({
      row: task,
      route: 'tcm-notes',
      navigate,
      toast,
      user,
    });
  }

  if (!checkAuthorization(user, entity[task?.type], action.complete))
    return null;

  const navigateToTask = (path, state = {}) => {
    navigate(path, {state});
  };

  const taskRoutes = {
    [TASK_TYPE.ContactTask]: () =>
      navigateToTask(
        `${task?.encounterId?._id}/${task?.taskId}/add-contact-attempts`,
        {
          encounter: task?.encounterId,
          patient: task?.patientId,
        },
      ),

    [TASK_TYPE.VisitTask]: () =>
      navigateToTask(`complete-visit/${task?.taskId}`, {
        encounter: task?.encounterId,
        patient: task?.patientId,
      }),

    [TASK_TYPE.MedicationTask]: () =>
      navigateToTask(`complete-medication/${task?.taskId}`, {
        encounter: task?.encounterId,
        patient: task?.patientId,
        medicationId: task?.taskId,
      }),

    [TASK_TYPE.INITIAL_TCM_ELIGIBILITY]: () =>
      navigateToTask('initial-eligibility', {
        status: task?.status,
        type: task?.type,
        encounter: task?.encounterId,
        patient: task?.patientId,
        tcmTaskId: task?._id,
      }),

    [TASK_TYPE.FACILITY_ASSIGNMENT]: () =>
      navigateToTask('facility-assignment', {
        status: task?.status,
        type: task?.type,
        encounter: task?.encounterId,
        tcmTaskId: task?._id,
        patient: task?.patientId,
      }),

    [TASK_TYPE.FACILITY_ONBOARDING]: () =>
      navigateToTask(`facility-onboarding/${task?.facilityId?._id}`, {
        facility: task?.facilityId,
        status: task?.status,
        type: task?.type,
        tcmTaskId: task?._id,
      }),

    [TASK_TYPE.PATIENT_CLASSIFICATION]: () =>
      navigateToTask(`patient-classification/${task?._id}`, {
        status: task?.status,
        type: task?.type,
        encounter: task?.encounterId,
        tcmTaskId: task?._id,
        patient: task?.patientId,
      }),

    [TASK_TYPE.DISCHARGE_STATUS]: () =>
      navigateToTask(`complete-discharge-status/${task?._id}`, {
        encounter: task?.encounterId,
        patient: task?.patientId,
      }),
  };

  const handleClick = e => {
    e.stopPropagation();

    if (task?.type === TASK_TYPE.Form1823) {
      if (IsFacilityAdmin && task?.status !== TASK_STATUS.Approved) {
        toast({
          type: 'Error',
          message:
            task?.status === TASK_STATUS.Active
              ? modalMessages.form1823.active
              : modalMessages.form1823.rejected,
        });
        return;
      }
      navigateToTask(`form1823/${task?.taskId}`, {
        status: task?.status,
        type: task?.type,
        encounter: task?.encounterId,
      });
      return;
    }

    taskRoutes[task?.type]?.();
  };

  return <Icon src={images.activation} onClick={handleClick} />;
};
