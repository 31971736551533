import React from 'react';
import {routeLink} from '../../../constant';
import {patientFields} from '../../../constant/patient.fields';
import {HttpAuthService} from '../../../services';
import {Patient_Location_Status} from '../constants/PatientConstants';
import {patientTableStyles} from '../screens/style';

export const getPatientById = async patientId => {
  try {
    const response = await HttpAuthService.get(
      `${routeLink.getPatient}/${patientId}`,
      {
        params: {
          fields: patientFields,
        },
      },
    );
    return response;
  } catch (err) {
    console.log('Error caught in getPatientById', err);
    throw err;
  }
};

export const locationStatus = patient => {
  if (patient?.locationStatus !== Patient_Location_Status.NONE) {
    const statusText = patient?.locationStatus;
    return (
      <div style={patientTableStyles.StatusContainer}>
        <div
          style={
            statusText == Patient_Location_Status.HOME
              ? patientTableStyles.GreenDot
              : patientTableStyles.RedDot
          }></div>
        <div style={patientTableStyles.textContainer}>{statusText}</div>
      </div>
    );
  }
  return '-';
};
