import React, {useEffect, useState} from 'react';
import {TextRenderer} from '@unthinkable/react-text-input';
import {Col, Row, View} from '@unthinkable/react-core-components';
import {ActivityIndicator} from 'react-native-web';
import images from '../../../assets/images';
import {HttpAuthService} from '../../../services';
import {
  completionCard,
  Heading,
  Icon,
  PercentageContainer,
} from '../styles/Completion.style';

export const CompletionCountCard = ({
  heading,
  api,
  filter,
  percentage = false,
  field,
}) => {
  const [value, setValue] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await HttpAuthService.get(api, {
        params: {
          filter: JSON.stringify(filter),
        },
      });
      if (response?.data) {
        setValue(response.data);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  let percentageContainerType = 'positive';
  if (
    (field === 'reAdmissionRate' || field === 'currentHospitalization') &&
    value?.percentageChange
  ) {
    percentageContainerType =
      value?.percentageChange > 0 ? 'negative' : 'positive';
  } else if (value?.percentageChange) {
    percentageContainerType =
      value?.percentageChange >= 0 ? 'positive' : 'negative';
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <View style={completionCard.container} gap={20}>
      <Heading>{heading}</Heading>
      <Col
        style={{
          flex: 1,
        }}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View style={completionCard.cardContent}>
            <TextRenderer
              value={
                value?.dataPercentage
                  ? `${value?.data}(${value?.dataPercentage}%)`
                  : value?.data
                  ? `${value?.data}${percentage ? '%' : ''}`
                  : `0${percentage ? '%' : ''}`
              }
              styles={{text: completionCard.count}}
            />
            <Row style={completionCard.footer}>
              <PercentageContainer type={percentageContainerType}>
                <Icon
                  src={
                    value?.percentageChange >= 0
                      ? images.arrowUp
                      : images.ArrowDown
                  }
                />
                {Math.abs(value?.percentageChange) || 0}%
              </PercentageContainer>
              <TextRenderer
                value={'vs Last Month'}
                styles={{text: completionCard.text}}
              />
            </Row>
          </View>
        )}
      </Col>
    </View>
  );
};
