import React, {useEffect, useState} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {Checkbox} from '@unthinkable/react-checkbox';
import {Button} from '../../../components/button';
import {isFacilityAdmin, isMedicalProvider} from '../../../utils/checkUserType';
import {HttpAuthService} from '../../../services';
import {phoneAndFaxValidation} from '../../../utils/validations/commonValidations';
import {TASK_STATUS} from '../../../constant/taskType';
import {DateTypeCaste} from 'src/utils/commonMethod';

export const Form1823 = props => {
  const {taskId} = useParams();
  const isfacilityadmin = isFacilityAdmin();
  const ismedicalprovider = isMedicalProvider();
  const {type, encounter} = useLocation().state;
  const [status, setStatus] = useState('');
  const [rejectReason, setRejectReason] = useState(null);
  const navigate = useNavigate();

  const {onSubmit} = useFormSubmit({
    uri: isFacilityAdmin() ? routeLink.completeForm1823 : routeLink.form1823,
    eventSourceId: 'tcmtasks',
  });

  const fetchForm = async () => {
    try {
      const response = await HttpAuthService.get(
        `${routeLink.form1823}/${taskId}`,
      );
      if (response.data) {
        setStatus(response?.data?.status);
        if (response.data.status === TASK_STATUS.Rejected) {
          setRejectReason(response.data?.rejectedReason);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchForm();
  }, []);
  if (status === TASK_STATUS.Rejected) {
    alert(`Form is rejected due to following reason : ${rejectReason}`);
  }
  return (
    <Form
      saveButtonText={
        isFacilityAdmin() || isMedicalProvider() ? 'Approve' : 'Save'
      }
      onSubmit={onSubmit}
      defaultValues={{
        dateOfExamination: DateTypeCaste(new Date()),
      }}
      beforeSubmit={data => {
        if (isfacilityadmin) {
          return {
            data: {
              ...data.data,
              status: TASK_STATUS.Complete,
              formId: taskId,
              encounterId: encounter?._id,
              type: type,
            },
          };
        } else if (ismedicalprovider) {
          return {
            data: {
              ...data.data,
              formId: taskId,
              status: TASK_STATUS.Approved,
              encounterId: encounter?._id,
              type: type,
            },
          };
        } else {
          return {
            data: {
              ...data.data,
              formId: taskId,
              encounterId: encounter?._id,
              type: type,
            },
          };
        }
      }}
      mode="edit"
      footer={{
        actions: [
          ...(isFacilityAdmin() && status === TASK_STATUS.Approved
            ? [
                <Button
                  key={TASK_STATUS.Reject}
                  text={TASK_STATUS.Reject}
                  onPress={() =>
                    navigate(`reject-reason`, {
                      state: {status},
                    })
                  }
                />,
              ]
            : []),
        ],
      }}
      api={routeLink.form1823 + `/${taskId}`}
      fields={{
        status: 1,
        patientId: {
          FullName: 1,
          DOB: 1,
          residence: {
            name: 1,
            streetAddress: 1,
            city: 1,
            zipCode: 1,
            faxNumber: 1,
            country: 1,
            phoneNumber: 1,
          },
        },
        knownAllergies: 1,
        height: 1,
        weight: 1,
        medicalHistoryAndDiagnosis: 1,
        physicalOrSensoryLimitations: 1,
        cognitiveOrBehavioralStatus: 1,
        nursingTreatmentTherapyServiceRequirement: 1,
        specialPrecautions: 1,
        elopementRisk: 1,
        supervision: {
          ambulating: 1,
          bathing: 1,
          dressing: 1,
          eating: 1,
          selfCare: 1,
          toileting: 1,
          transferring: 1,
        },
        specialDietInstructions: {
          regular: 1,
          calorieControlled: 1,
          noAddedSalt: 1,
          lowFatLowCholesterol: 1,
          other: 1,
        },
        specialConditionRequirements: {
          communicableDisease: 1,
          bedridden: 1,
          pressureSores: 1,
          dangerToSelfOrOthers: 1,
          requireNursingPsychiatricCare: 1,
        },
        professionalOpinion: 1,
        medicalAssistance: {
          required: 1,
          needsAssistanceWithSelfAdministration: 1,
          needsMedicalAdministration: 1,
          ableToSelfAdministerMedications: 1,
        },
        medicineDocument: 1,
        commentsOrObservations: 1,
        medications: 1,
        examiner: 1,
        licenseNumber: 1,
        titleOfExaminer: 1,
        telephoneNumber: 1,
        addressOfExaminer: 1,
        dateOfExamination: 1,
        rejectReason: 1,
      }}
      layoutFields={[
        ...[
          // Resident Information
          {
            label: 'Resident Information',
            fields: [
              {
                type: 'text',
                label: 'Resident Name',
                field: 'patientId.FullName',
                size: 'medium',
                required: true,
                readOnly: true,
              },
              {
                type: 'date',
                label: 'DOB',
                field: 'patientId.DOB',
                size: 'medium',
                required: true,
                readOnly: true,
              },
            ],
          },
          // Facility Information
          {
            label: 'Facility Information',
            fields: [
              {
                type: 'text',
                label: 'Facility Name',
                field: 'patientId.residence.name',
                readOnly: true,
              },
              {
                type: 'text',
                label: 'Telephone Number',
                field: 'patientId.residence.phoneNumber',
                size: 'medium',
                readOnly: true,
              },
              {
                type: 'text',
                label: 'Street Address',
                field: 'patientId.residence.streetAddress',
                size: 'medium',
                readOnly: true,
              },
              {
                type: 'text',
                label: 'Fax Number',
                field: 'patientId.residence.faxNumber',
                size: 'small',
                readOnly: true,
              },
              {
                type: 'text',
                label: 'City',
                field: 'patientId.residence.city',
                size: 'small',
                readOnly: true,
              },
              {
                type: 'text',
                label: 'ZipCode',
                field: 'patientId.residence.zipCode',
                size: 'small',
                readOnly: true,
              },
            ],
          },
          // Section 1. Health Assessment
          {
            label: 'Section 1. Health Assessment',
            fields: [
              {
                type: 'inputTextChip',
                label: 'Known Allergies',
                field: 'knownAllergies',
                size: 'large',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'text',
                label: 'Height (in)',
                field: 'height',
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'text',
                label: 'Weight (lbs)',
                field: 'weight',
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'inputTextChip',
                label: 'Medical History and Diagnoses',
                field: 'medicalHistoryAndDiagnosis',
                size: 'large',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'text',
                label: 'Physical or Sensory Limitations',
                field: 'physicalOrSensoryLimitations',
                size: 'large',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'text',
                label: 'Cognitive or Behavioral Status',
                field: 'cognitiveOrBehavioralStatus',
                size: 'large',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'text',
                label: 'Nursing/Treatment/Therapy Service Requirements',
                field: 'nursingTreatmentTherapyServiceRequirement',
                size: 'large',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'text',
                label: 'Special Precautions',
                field: 'specialPrecautions',
                size: 'medium',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field: 'elopementRisk',
                label: 'Elopement Risk',
                options: [
                  {label: 'Yes', value: true},
                  {label: 'No', value: false},
                ],
                keyField: 'value',
                suggestionField: 'label',
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
            ],
          },
          {
            label:
              'A To what extent does the individual need supervision or assistance with the following?',
            fields: [
              {
                type: 'autoComplete',
                field: 'supervision.ambulating',
                label: 'Ambulation',
                options: [
                  'Independent',
                  'Need Assistance',
                  'Need Supervision',
                  'Total Care',
                ],
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field: 'supervision.dressing',
                label: 'Dressing',
                options: [
                  'Independent',
                  'Need Assistance',
                  'Need Supervision',
                  'Total Care',
                ],
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field: 'supervision.bathing',
                label: 'Bathing',
                options: [
                  'Independent',
                  'Need Assistance',
                  'Need Supervision',
                  'Total Care',
                ],
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field: 'supervision.eating',
                label: 'Eating',
                options: [
                  'Independent',
                  'Need Assistance',
                  'Need Supervision',
                  'Total Care',
                ],
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field: 'supervision.selfCare',
                label: 'Self-care',
                options: [
                  'Independent',
                  'Need Assistance',
                  'Need Supervision',
                  'Total Care',
                ],
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field: 'supervision.toileting',
                label: 'Toileting',
                options: [
                  'Independent',
                  'Need Assistance',
                  'Need Supervision',
                  'Total Care',
                ],
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field: 'supervision.transferring',
                label: 'Transferring',
                options: [
                  'Independent',
                  'Need Assistance',
                  'Need Supervision',
                  'Total Care',
                ],
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
            ],
          },
          {
            label: 'B Special Diet Instructions:',
            fields: [
              {
                render: Checkbox,
                label: 'Regular',
                field: 'specialDietInstructions.regular',
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                render: Checkbox,
                label: 'Calorie Controlled',
                field: 'specialDietInstructions.calorieControlled',
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                render: Checkbox,
                label: 'No Added Salt',
                field: 'specialDietInstructions.noAddedSalt',
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                render: Checkbox,
                label: 'Low Fat/Low Cholesterol Salt',
                field: 'specialDietInstructions.lowFatLowCholesterol',
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'text',
                label: 'Other',
                helperText:
                  '(specify, including consistency changes such as puree):',
                field: 'specialDietInstructions.other',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
            ],
          },
          {
            label:
              'C. Does the individual have any of the following conditions/requirements?',
            fields: [
              {
                type: 'autoComplete',
                field: 'specialConditionRequirements.communicableDisease',
                label:
                  'A communicable disease, which could be transmitted to other residents or staff?',
                options: [
                  {label: 'Yes', value: true},
                  {label: 'No', value: false},
                ],
                keyField: 'value',
                suggestionField: 'label',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field: 'specialConditionRequirements.bedridden',
                label: 'Bedridden?',
                options: [
                  {label: 'Yes', value: true},
                  {label: 'No', value: false},
                ],
                keyField: 'value',
                suggestionField: 'label',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field: 'specialConditionRequirements.pressureSores',
                label: 'Any stage 2, 3, or 4 pressure sores?',
                options: [
                  {label: 'Yes', value: true},
                  {label: 'No', value: false},
                ],
                keyField: 'value',
                suggestionField: 'label',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field: 'specialConditionRequirements.dangerToSelfOrOthers',
                label: 'Pose a danger to self or others?',
                helperText:
                  '(Consider any significant history of physically or sexually aggressive behavior.)',
                options: [
                  {label: 'Yes', value: true},
                  {label: 'No', value: false},
                ],
                keyField: 'value',
                suggestionField: 'label',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
              {
                type: 'autoComplete',
                field:
                  'specialConditionRequirements.requireNursingPsychiatricCare',
                label: 'Require 24-hour nursing or psychiatric care?',
                options: [
                  {label: 'Yes', value: true},
                  {label: 'No', value: false},
                ],
                keyField: 'value',
                suggestionField: 'label',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
            ],
          },
          {
            label:
              'D. In your professional opinion, can this individual’s needs be met in an assisted living facility, which is not a medical, nursing, or psychiatric facility?',
            fields: [
              {
                type: 'autoComplete',
                options: [
                  {label: 'Yes', value: true},
                  {label: 'No', value: false},
                ],
                keyField: 'value',
                suggestionField: 'label',
                field: 'professionalOpinion',
                size: 'small',
                readOnly:
                  status === TASK_STATUS.Approved ||
                  status === TASK_STATUS.Complete,
              },
            ],
          },
          // section 2. Self-Care and General Oversight Assessment - Medications
          {
            label:
              'Section 2. Self-Care and General Oversight Assessment - Medications',
            fields: [
              {
                label: 'A. Medications',
                fields: [
                  {
                    type: 'inputTextChip',
                    field: 'medications',
                    size: 'large',
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      status === TASK_STATUS.Complete,
                  },
                ],
              },
              {
                label:
                  'B. Does the individual need help with taking his or her medications (meds)?',
                fields: [
                  {
                    type: 'autoComplete',
                    field: 'medicalAssistance.required',
                    size: 'small',
                    options: [
                      {label: 'Yes', value: true},
                      {label: 'No', value: false},
                    ],
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      status === TASK_STATUS.Complete,
                    keyField: 'value',
                    suggestionField: 'label',
                  },
                ],
              },
              {
                label:
                  'If YES, place a checkmark in front of the appropriate box below:',
                fields: [
                  {
                    label: 'Needs Assistance With Self-Administration',
                    helperText:
                      'This allows unlicensed staff to assist with nasal,ophthalmic, oral, otic, and topical medications.',
                    render: Checkbox,
                    field:
                      'medicalAssistance.needsAssistanceWithSelfAdministration',
                    size: 'medium',
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      status === TASK_STATUS.Complete,
                  },
                  {
                    label: 'Needs Medication Administration',
                    helperText:
                      'Not all assisted living facilities have licensed staff to perform this service.',
                    render: Checkbox,
                    field: 'medicalAssistance.needsMedicalAdministration',
                    size: 'small',
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      status === TASK_STATUS.Complete,
                  },
                  {
                    label: 'Able To Self-Administer Medications',
                    helperText: 'Resident does not need staff assistance',
                    render: Checkbox,
                    field: 'medicalAssistance.ableToSelfAdministerMedications',
                    size: 'small',
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      status === TASK_STATUS.Complete,
                  },
                ],
              },
              {
                label: 'C. Additional Comments/Observations',
                fields: [
                  {
                    type: 'text',
                    field: 'commentsOrObservations',
                    size: 'large',
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      status === TASK_STATUS.Complete,
                  },
                ],
              },
            ],
          },
        ],
        ...(type === 'Form1823'
          ? [
              {
                label:
                  'NOTE: MEDICAL CERTIFICATION IS INCOMPLETE WITHOUT THE FOLLOWING INFORMATION (To be filled out by PCP only)',
                fields: [
                  {
                    type: 'text',
                    label: 'Name of Examiner',
                    field: 'examiner',
                    size: 'large',
                    required: isMedicalProvider() ? true : false,
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      !isMedicalProvider() ||
                      status === TASK_STATUS.Complete,
                  },
                  {
                    type: 'text',
                    label: 'Medical License Number',
                    field: 'licenseNumber',
                    size: 'medium',
                    required: isMedicalProvider() ? true : false,
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      !isMedicalProvider() ||
                      status === TASK_STATUS.Complete,
                  },
                  {
                    type: 'autoComplete',
                    label: 'Title of Examiner',
                    field: 'titleOfExaminer',
                    options: ['MD', 'DO', 'APRN', 'PA'],
                    size: 'medium',
                    required: isMedicalProvider() ? true : false,
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      !isMedicalProvider() ||
                      status === TASK_STATUS.Complete,
                  },
                  {
                    type: 'text',
                    label: 'Telephone Number',
                    field: 'telephoneNumber',
                    validate: value => phoneAndFaxValidation(value, 'phone'),
                    size: 'medium',
                    required: isMedicalProvider() ? true : false,
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      !isMedicalProvider() ||
                      status === TASK_STATUS.Complete,
                  },
                  {
                    type: 'text',
                    label: 'Address of Examiner:',
                    field: 'addressOfExaminer',
                    size: 'medium',
                    required: isMedicalProvider() ? true : false,
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      !isMedicalProvider() ||
                      status === TASK_STATUS.Complete,
                  },
                  {
                    type: 'date',
                    label: 'Date of Examination',
                    field: 'dateOfExamination',
                    size: 'medium',
                    required: isMedicalProvider() ? true : false,
                    readOnly:
                      status === TASK_STATUS.Approved ||
                      !isMedicalProvider() ||
                      status === TASK_STATUS.Complete,
                  },
                ],
              },
            ]
          : []),
      ]}
      {...props}
    />
  );
};
