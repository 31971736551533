import {TableHeader} from 'src/components/header/TableHeader';
import React from 'react';
import {Form} from 'src/components/form';
import {useFormSubmit} from 'src/hooks';
import {routeLink} from 'src/constant';
import {DateTypeCaste} from 'src/utils/commonMethod';

const DownloadReportModal = props => {
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.downloadReport}`,
    method: 'download',
  });
  return (
    <>
      <TableHeader title="Download Report" />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          startDate: DateTypeCaste(new Date()),
          endDate: DateTypeCaste(new Date()),
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'autoComplete',
                field: 'eventType',
                label: 'Event Type',
                required: true,
                keyField: 'value',
                suggestionField: 'label',
                options: [
                  {label: 'Arrival', value: 'Arrival'},
                  {label: 'Discharge', value: 'Discharge'},
                ],
              },
              {
                type: 'date',
                field: 'startDate',
                label: 'Start Date',
                required: true,
              },
              {
                type: 'date',
                field: 'endDate',
                label: 'End Date',
                required: true,
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};

export default DownloadReportModal;
