import styled from 'styled-components';
export const MainContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: auto;
  @media (max-width: 350px) {
    padding: 0px;
  }
`;

export const AppContentStyle = styled.div`
  padding: ${props => (props.setPaddingToZero ? '0px' : '16px')};
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 350px) {
    padding: 8px;
  }
`;
