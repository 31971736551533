import React, {useEffect, useReducer} from 'react';
import {UserService} from '../../services';
import {
  AuthContext,
  CLEAR_AUTH,
  SESSION_TIME_OUT,
  SET_AUTH,
  SET_LOADING,
  authReducer,
  initialState,
} from './AuthContext';
import Config from '../../Config';
import Pubnub from 'pubnub';

export const AuthProvider = props => {
  const {children} = props || {};
  const [state, dispatch] = useReducer(authReducer, initialState);

  const setAuth = payload => {
    dispatch({type: SET_AUTH, payload});
  };

  const clearAuth = () => {
    dispatch({type: CLEAR_AUTH});
  };

  const setLoading = isLoading => {
    dispatch({type: SET_LOADING, payload: isLoading});
  };

  const sessionTimeOut = () => {
    dispatch({type: SESSION_TIME_OUT});
  };

  // Effect to run once on component mount
  useEffect(() => {
    const loadAuthState = async () => {
      try {
        const user = await UserService.verifyToken();
        if (user) {
          const pubNub = new Pubnub({
            publishKey: Config.PUB_NUB_PUBLISH_KEY,
            subscribeKey: Config.PUB_NUB_SUBSCRIBE_KEY,
            userId: user.PNChatMemberId,
          });
          pubNub.setToken(user.chatAuthKey);
          user.chatInstance = pubNub;
          setAuth({user});
        } else {
          clearAuth();
        }
      } catch (error) {
        // Handle error (e.g., token might be invalid or expired)
        clearAuth();
      }
    };
    loadAuthState();
  }, []);

  // The value provided to the context consumers
  const authContextValue = {
    state,
    dispatch,
    setAuth,
    clearAuth,
    setLoading,
    sessionTimeOut,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
